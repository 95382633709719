import React from 'react'
import HeaderLarge from '../typography/HeaderLarge'
import TextbookHomePage from './TextbookHomePage'
import { Separator } from '../shadcn/ui/separator'
import HeaderMedium from '../typography/HeaderMedium'
import { ExternalLinkIcon } from 'lucide-react'
import SpinLoader from '../loader/SpinLoader'
import FourOhFourPage from '../../pages/errors/FourOhFourPage'

const TextbookDisplay = ({textbookItem, param, loading}) => {

  if(loading) return <SpinLoader />

    return (
      
    <div className='w-full md:w-4/5 mx-auto py-2 px-6'>
      {param === 'home' ? 
        <div className="my-4">
          <TextbookHomePage />
        </div>
      :
      <>
        {textbookItem.title ? 
          <>
            <div className="my-4">
              <HeaderLarge blackText={true} text={textbookItem.title} underline={true} />
            </div>
            <div id="ckeditor" className="ckeditor" dangerouslySetInnerHTML={{ __html: textbookItem.content}} />
            <Separator className='my-4' />
            <div className='my-2'>
                <HeaderMedium blackText={false} text="Recommended Reading:" underline={true} /> 
                <ul className='list-disc pl-5'>
                  {textbookItem.page_resources && textbookItem.page_resources.map((res) => (
                    <li className='py-1'>
                      <a
                        href={res.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline flex items-center space-x-1"
                      >
                        <span>{res.name}</span>
                        <ExternalLinkIcon size={14}/>
                      </a>
                    </li>
                  ))}
                </ul>
            </div>
          </>      
        :
          <FourOhFourPage />
        }
      </>
      }
      
    </div>
  )
}

export default TextbookDisplay