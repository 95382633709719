import React from 'react'
import { Button } from '../shadcn/ui/button'
import { useNavigate } from 'react-router-dom'
import examKeys from '../id_helpers/exam_ids.json'
import { LibraryBig, LineChart } from 'lucide-react'
import { Separator } from '../shadcn/ui/separator'
import { Dialog, DialogContent, DialogHeader, DialogDescription, DialogTrigger, DialogTitle } from '../shadcn/ui/dialog'
import CheckMarkAnimated from '../other_ui/CheckMarkAnimated'

function UserSubscriptions({ activeSubscriptions, expiredSubscriptions, grantTrialSubscription, success, loading, subscriptionCount, grantFreeSubscription, freeSubLoading, freeSubSuccess, freeSubError }) {
  const navigate = useNavigate()

  // Create a set of active subscription exam IDs
  const activeExamIds = new Set(activeSubscriptions.map(sub => sub.exam_id));

  // Filter expired subscriptions to exclude those with an active subscription of the same exam ID
  const filteredExpiredSubscriptions = expiredSubscriptions.filter(sub => !activeExamIds.has(sub.exam_id));
  const handleNavigation = (examId) => {
    if (examId === 1){
        navigate('/MSRA/home')
    } else if (examId === 5){
      navigate('/trial-MSRA/home')
    }
  }

  const MSRAExists = activeSubscriptions.some(subscription => subscription.exam_id === 1);

  return (
    <div>
      {activeSubscriptions.length === 0 && expiredSubscriptions.length === 0 && (
        <>
          <section>
            <h2 className="text-xl font-semibold mb-4">Active Subscriptions</h2>
            <div className="space-y-4">
                <p>You have no active subscriptions, but you are an early bird which gives you <strong>free</strong> full access if you claim below. We recommend starting off with the free trial first. 
                <br /><br />
                {/* <span className="italic">Tell your friends, as there are only {subscriptionCount.remaining_subscriptions} free full-access subscriptions remaining</span> */}
                </p>
              {/* <p>You have not purchased a subscription yet, why not start with a Free Trial? 
                <br />
                <span className='text-xs italic'>
                  If you have purchased a subscription but cannot see it listed below, please contact us <a href="mailto:contact@exam-vitals.com" className='hover:underline text-blue-800 dark:text-blue-300'>here</a>.
                </span></p> */}
              <div className="flex flex-col md:flex-row justify-center mt-4 gap-4"> 
              <Dialog>
                  <DialogTrigger asChild>
                    <Button size="sm" variant={'outline'}>
                      Activate Free Trial
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    {success && !loading ?
                    <CheckMarkAnimated page={"Trial Subscription Grant"}/>  
                    : 
                    <>
                      <DialogHeader>
                        <DialogTitle>
                          MSRA Free Trial
                        </DialogTitle>
                        <DialogDescription>
                          Activate your MSRA Free Trial now. No payment required. 
                        </DialogDescription>
                      </DialogHeader>
                      <Button onClick={() => grantTrialSubscription(5)}>
                        Activate Free Trial Now
                      </Button>
                    </>
                    }
                  </DialogContent>
                </Dialog>

                {/* {subscriptionCount.remaining_subscriptions > 0 ? ( */}
                <Dialog>
                  <DialogTrigger asChild>
                    <Button size="sm">
                      Activate Free Full Access Subscription
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    {freeSubSuccess && !freeSubLoading ?
                    <CheckMarkAnimated page={"Free Full Access Grant"}/>  
                    : 
                    <>
                      <DialogHeader>
                        <DialogTitle>
                          MSRA Early Bird Free Access
                        </DialogTitle>
                        <DialogDescription>
                          As a thank you for being an early user, we are giving away free subscriptions. Click below to activate. No payment required. 
                        </DialogDescription>
                      </DialogHeader>
                      <Button onClick={() => grantFreeSubscription()}>
                        Activate Free Full Access Subscription Now
                      </Button>
                    </>
                    }
                  </DialogContent>
                </Dialog>
                {/* ) : ( */}
                {/* <Button onClick={() => navigate('/pricing')} size="sm">
                  Buy Full Access
                </Button> */}
                {/* )
                } */}
                
              </div>
            </div>
          </section>
        </>
      )}
      {activeSubscriptions.length > 0 && MSRAExists && (
        <section>
          <h2 className="text-xl font-semibold mb-4">Active Subscriptions</h2>
          {activeSubscriptions.map(sub => {
            if (sub.exam_id === 5) {
              return null; // Skip rendering for sub.id === 5
            }

            return (
            <div key={sub.id} className="space-y-4">
              <div className="bg-background rounded-lg p-4 shadow-sm">
                <div className="flex items-center justify-between mb-2">
                  <div className="font-medium">
                    {examKeys.find(exam => exam.id === sub.exam_id)?.full_name}
                  </div>
                  <div className="text-muted-foreground text-sm">
                    Expires on {sub.end_date}
                  </div>
                </div>
                <div className="text-muted-foreground text-sm">
                  Started on {sub.start_date}
                </div>
                <div className="mt-4 flex flex-wrap justify-between gap-2 sm:flex-row sm:gap-3">
                {/* WILL NEED TO CHANGE THE NUMBER PASSED THROUGH TO HANDLENAVIGATION WHEN MORE EXAMS ARE MADE */}
                  <Button 
                    variant='outline'
                    onClick={() => handleNavigation(sub.exam_id)} 
                    size="sm"
                    >
                    Question Bank
                  </Button>
                  {sub.exam_name === "MSRA" ? (
                  <Button 
                    className='hover:cursor-pointer'
                    onClick={() => navigate('/MSRA/textbook/home')}
                    variant="link" 
                    size="sm"
                    asChild
                    >
                    <span className='gap-1 items-center'>
                      <LibraryBig
                        size={14}
                        />
                        <span>Textbook</span>
                    </span>
                  </Button>
                  ) : sub.exam_name === "MSRA Free Trial" ? (
                  <Button 
                    className='hover:cursor-pointer'
                    onClick={() => navigate('/trial-MSRA/textbook/home')}
                    variant="link" 
                    size="sm"
                    asChild
                    >
                    <span className='gap-1 items-center'>
                      <LibraryBig
                        size={14}
                        />
                        <span>Textbook</span>
                    </span>
                  </Button>
                  ) : null}
                  {sub.exam_name === "MSRA" ?(
                  <Button 
                    className='hover:cursor-pointer'
                    onClick={() => navigate('/MSRA/dashboard')}
                    variant="link" 
                    size="sm"
                    asChild
                    >
                    <span className='gap-1 items-center'>
                      <LineChart size={14}/>
                      <span>My Performance</span>
                    </span>
                  </Button>
                  ) : sub.exam_name === "MSRA Free Trial" ? (
                  <Button 
                    className='hover:cursor-pointer'
                    onClick={() => navigate('/trial-MSRA/dashboard')}
                    variant="link" 
                    size="sm"
                    asChild
                    >
                    <span className='gap-1 items-center'>
                      <LineChart size={14}/>
                      <span>My Performance</span>
                    </span>
                  </Button>
                  ) : null
                  }
                </div>
              </div>
            </div>
          )}
          )}
        </section>
      )}
      {activeSubscriptions.length > 0 && !MSRAExists  && (
        <section>
          <h2 className="text-xl font-semibold mb-4">Free Trial Subscriptions</h2>
          {activeSubscriptions.map(sub => (
            <div key={sub.id} className="space-y-4">
              <div className="bg-background rounded-lg p-4 shadow-sm">
                <div className="flex items-center justify-between mb-2">
                  <div className="font-medium">
                    {examKeys.find(exam => exam.id === sub.exam_id)?.full_name}
                  </div>
                  <div className="text-muted-foreground text-sm">
                    Expires on {sub.end_date}
                  </div>
                </div>
                <div className="text-muted-foreground text-sm">
                  Started on {sub.start_date}
                </div>
                <div className="mt-4 flex flex-wrap justify-between gap-2 sm:flex-row sm:gap-3">
                {/* WILL NEED TO CHANGE THE NUMBER PASSED THROUGH TO HANDLENAVIGATION WHEN MORE EXAMS ARE MADE */}
                  <Button 
                    variant='outline'
                    onClick={() => handleNavigation(sub.exam_id)} 
                    size="sm"
                    >
                    Question Bank
                  </Button>
                  {sub.exam_name === "MSRA" ? (
                  <Button 
                    className='hover:cursor-pointer'
                    onClick={() => navigate('/MSRA/textbook/home')}
                    variant="link" 
                    size="sm"
                    asChild
                    >
                    <span className='gap-1 items-center'>
                      <LibraryBig
                        size={14}
                        />
                        <span>Textbook</span>
                    </span>
                  </Button>
                  ) : sub.exam_name === "MSRA Free Trial" ? (
                  <Button 
                    className='hover:cursor-pointer'
                    onClick={() => navigate('/trial-MSRA/textbook/home')}
                    variant="link" 
                    size="sm"
                    asChild
                    >
                    <span className='gap-1 items-center'>
                      <LibraryBig
                        size={14}
                        />
                        <span>Textbook</span>
                    </span>
                  </Button>
                  ) : null}
                  {sub.exam_name === "MSRA" ?(
                  <Button 
                    className='hover:cursor-pointer'
                    onClick={() => navigate('/MSRA/dashboard')}
                    variant="link" 
                    size="sm"
                    asChild
                    >
                    <span className='gap-1 items-center'>
                      <LineChart size={14}/>
                      <span>My Performance</span>
                    </span>
                  </Button>
                  ) : sub.exam_name === "MSRA Free Trial" ? (
                  <Button 
                    className='hover:cursor-pointer'
                    onClick={() => navigate('/trial-MSRA/dashboard')}
                    variant="link" 
                    size="sm"
                    asChild
                    >
                    <span className='gap-1 items-center'>
                      <LineChart size={14}/>
                      <span>My Performance</span>
                    </span>
                  </Button>
                  ) : null
                  }
                </div>
              </div>
            </div>
          ))}
          <div className="space-y-4 mt-4">
            <h2 className="text-xl font-semibold mb-4">Full Access Subscriptions</h2>
              {/* {subscriptionCount.remaining_subscriptions > 0 ?
              ( */}
                <>
                  <h2 className="text-lg font-semibold mb-4">You are an early bird! You can claim a full subscription for <strong>free</strong> by clicking below.</h2>
                  {/* <p>There are {subscriptionCount.remaining_subscriptions} left to claim, so tell your friends!</p> */}
                  <div className="flex justify-center mt-4"> 
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button size="sm">
                          Activate Free Full Access Subscription
                        </Button>
                      </DialogTrigger>
                      <DialogContent>
                        {freeSubSuccess && !freeSubLoading ?
                        <CheckMarkAnimated page={"Free Full Access Grant"}/>  
                        : 
                        <>
                          <DialogHeader>
                            <DialogTitle>
                              MSRA Early Bird Free Access
                            </DialogTitle>
                            <DialogDescription>
                              As a thank you for being an early user, we are giving away free subscriptions. Click below to activate. No payment required. 
                            </DialogDescription>
                          </DialogHeader>
                          <Button onClick={() => grantFreeSubscription()}>
                            Activate Free Full Access Subscription Now
                          </Button>
                        </>
                        }
                      </DialogContent>
                    </Dialog>
                  </div>
                </>
              {/* )
              : ( */}
              {/* <>
              <p>You have not purchased full access to the MSRA yet. If you have purchased a subscription but cannot see it listed below, please contact us <a href="mailto:contact@exam-vitals.com" className='hover:underline text-blue-800 dark:text-blue-300'>here</a>.</p>
                <div className="flex justify-center mt-4"> 
                  <Button onClick={() => navigate('/pricing')} size="sm">
                    Upgrade to full access
                  </Button>
                </div>
              </> */}
              {/* )} */}
          </div>
        </section>
      )}
      {filteredExpiredSubscriptions.length > 0 && (
        <section>
          <h2 className="text-xl font-semibold mb-4">Expired Subscriptions</h2>
          {filteredExpiredSubscriptions.map(sub => (
            <div key={sub.id} className="space-y-4">
              <div className="bg-background rounded-lg p-4 shadow-sm">
                <div className="flex items-center justify-between mb-2">
                  <div className="font-medium">
                    {examKeys.find(exam => exam.id === sub.exam_id)?.full_name}
                  </div>
                  <div className="text-muted-foreground text-sm">
                    Expired on {sub.end_date}
                  </div>
                </div>
                <div className="text-muted-foreground text-sm">
                  Started on {sub.start_date}
                </div>
                <div className="mt-4">
                  <Button variant="outline" size="sm" onClick={()  => navigate('/pricing')}>
                    Renew Subscription
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </section>
      )}
    </div>
  )
}

export default UserSubscriptions
