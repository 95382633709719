import { useState, useEffect } from 'react';
import { getCsrfToken } from '../../csrfTokenHelper';

export const useFetchSubscriptions = (token) => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!token) {
            return; // Exit the useEffect if the token is not yet available
        }
        const fetchSubscriptions = async () => {
            try {
                const csrfToken = getCsrfToken();
                let response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/subscriptions/my_subscriptions/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "X-CSRFToken": csrfToken,  
                    }
                });

                if (!response.ok) throw new Error('Failed to fetch subscriptions');

                const data = await response.json();
                setSubscriptions(data.subscriptions);
            } catch (error) {
                console.error('Error fetching subscriptions:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchSubscriptions();
    }, [token]);

    return { subscriptions, loading, error };
};
