import React, { useEffect, useState, useCallback } from 'react'
import { RLDisplay } from '../../../../../components/hooks/MSRA/question_displays/RLDisplay'
import { MCQDisplay } from '../../../../../components/hooks/MSRA/question_displays/MCQDisplay'
import { Card } from "../../../../../components/shadcn/ui/card"
import { Button } from '../../../../../components/shadcn/ui/button'
import { CircleArrowLeft, CircleArrowRight } from 'lucide-react'
import { Separator } from '../../../../../components/shadcn/ui/separator'
import { useNavigate, useParams } from 'react-router-dom'
import { 
    fetchUserQuestionStates,
    fetchSessionQuestions,
    enrichQuestionsWithDetails,
    fetchQuestionAttempts,
    fetchQuestionAttemptStats,
  } from '../../../../../components/hooks/MSRA/helper_functions/cpsFetches'
import { useFetchSpecificMSRASessionData } from '../../../../../components/hooks/MSRA/fetches/FetchSpecificMSRASessionData'
import ReviewSessionFormattedQuestionList from '../../../../../components/session_navigation/MSRA/sessionNavigatorForReview'
import { useAuth0 } from '@auth0/auth0-react'
import SpinLoader from '../../../../../components/loader/SpinLoader'
import GoBackButton from '../../../../../components/other_ui/GoBackButton'
import FourOhFourPage from '../../../../errors/FourOhFourPage'


function TrialPDSingleSessionReview() {
  const { id } = useParams();  
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [questionsLoading, setQuestionsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [questionStates, setQuestionStates] = useState([]);
  const [questionsError, setQuestionsError] = useState(null);
  const [questionAttempts, setQuestionAttempts] = useState({});
  const [overallScore, setOverallScore] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [scorePercentage, setScorePercentage] = useState(0.0);
  const [userAnswer, setUserAnswer] = useState({}); // Now an object to track answers by question ID
  const [showDialog, setShowDialog] = useState(false)
  const [optionOrder, setOptionOrder] = useState();
  const [orderLoading, setOrderLoading] = useState(true)
  const [questionAttemptsLoading, setQuestionAttemptsLoading] = useState(true)
  const [questionStats, setQuestionStats] = useState()
  const [statsLoading, setStatsLoading] = useState(true)
  const [manualScore, setManualScore] = useState({})
  const [answerSubmitted, setAnswerSubmitted] = useState(false)
  const navigate = useNavigate()
  const [wrongSession, setWrongSession] = useState(false)
  
  const [token, setToken] = useState(null)
  const [tokenLoading, setTokenLoading] = useState(true)
  const { getAccessTokenSilently, isLoading: authLoading } = useAuth0()

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setToken(token);
      } catch (error) {
        console.error('Error fetching token:', error);
      } finally {
        setTokenLoading(false);
      }
    };
    
    fetchToken();
  }, [getAccessTokenSilently]);

  const { data, loading, error } = useFetchSpecificMSRASessionData(id, token, "MSRA Free Trial")

  useEffect(() => {
    if (data && data.paper){
      if (data.paper !== 5 || data.is_active){
        setWrongSession(true)
      }
    }
  }, [data])


  const currentQuestion = questions.length > 0 ? questions.find(q => q.id === data.questions[currentQuestionIndex]) : null;

  useEffect(() => {
    let newOptionsOrder = {};
    if (!wrongSession && questions && !questionAttemptsLoading){
    questions.forEach(question => {
        if (question && question.options) {
            if (questionAttempts[question.id] && questionAttempts[question.id].user_answer) {
                const orderedOptions = questionAttempts[question.id].user_answer.map(index => question.options[index]);
                newOptionsOrder[question.id] = orderedOptions.map(option => {
                    const originalIndex = question.options.findIndex(o => o.id === option.id);
                    const label = String.fromCharCode(65 + originalIndex);
                    return { ...option, label };
                });
                setOrderLoading(false)
            } else {
                newOptionsOrder[question.id] = question.options.map(option => {
                    const originalIndex = question.options.findIndex(o => o.id === option.id);
                    const label = String.fromCharCode(65 + originalIndex);
                    return { ...option, label }                        
                })
                setOrderLoading(false)
            }
            setOptionOrder(newOptionsOrder);
        }
    });
    } else return

}, [wrongSession, questions, questionAttempts]);      

useEffect(() => {
  if (wrongSession || !data || !data.id) return;

  const fetchData = async () => {
      setQuestionsLoading(true);

      try {
          const questions = await fetchSessionQuestions(data.id, token);
          const questionsWithDetails = await enrichQuestionsWithDetails(questions, token);
          setQuestions(questionsWithDetails);

          const attempts = await fetchQuestionAttempts(data.id, token);
          const attemptsByQuestionId = {};
          const newUserAnswers = {};

          attempts.forEach(attempt => {
              attemptsByQuestionId[attempt.question] = attempt;
          });

          questionsWithDetails.forEach(question => {
              if (question.question_type === 4 && !attemptsByQuestionId[question.id]) {
                  newUserAnswers[question.id] = question.options.map((_, index) => index);
              }
          });

          setUserAnswer(newUserAnswers);
          setQuestionAttempts(attemptsByQuestionId);
      } catch (error) {
          console.error('Failed to fetch session data:', error);
      } finally {
          setQuestionsLoading(false);
          setQuestionAttemptsLoading(false);
      }
  };

  fetchData();
}, [wrongSession, data, token]); // Only depends on 'data'

useEffect(() => {
  if (wrongSession || !data || !data.id) return;

  fetchQuestionAttemptStats(data.id, token)
      .then(stats => {
          setQuestionStats(stats); // Store stats in state
      })
      .catch(error => {
          console.error("Failed to fetch question stats", error);
      });
}, [wrongSession, data, token]); // Depends on 'data'

useEffect(() => {
  if (wrongSession || !data || !data.id) return;

  fetchUserQuestionStates(data.id, token)
      .then(questionStatesData => {
          setQuestionStates(questionStatesData);
      })
      .catch(error => {
          console.error("Failed to fetch question states", error);
      });
}, [wrongSession, data, token]);

useEffect(() => {
  if(wrongSession) return
  let totalScore = 0
  let totalPossiblePoints = 0

  if(Object.keys(questionAttempts).length > 0){
      Object.values(questionAttempts).forEach(attempt => {
          totalScore += attempt.points_scored;
          totalPossiblePoints += attempt.total_points;
      });

      setOverallScore(totalScore)
      setTotalPoints(totalPossiblePoints)
  }
}, [wrongSession, questionAttempts])


const goToPreviousQuestion = useCallback(() => {
  if (currentQuestionIndex > 0) {
      let newIndex = currentQuestionIndex - 1;
      setCurrentQuestionIndex(newIndex);
  }
}, [currentQuestionIndex]);

const goToNextQuestion = useCallback(() => {
  if (currentQuestionIndex < data.questions.length - 1) {
      let newIndex = currentQuestionIndex + 1;
      setCurrentQuestionIndex(newIndex);
  }
}, [currentQuestionIndex, data.questions]);

const navigateToQuestion = (questionNumber) => {
  setCurrentQuestionIndex(questionNumber)
};

const handleKeyDown = useCallback((event) => {
  if (event.key === 'ArrowRight') {
      goToNextQuestion();
  } else if (event.key === 'ArrowLeft') {
      goToPreviousQuestion();
  }
}, [goToNextQuestion, goToPreviousQuestion]);

// Add event listener on component mount and cleanup on unmount
useEffect(() => {
  window.addEventListener('keydown', handleKeyDown);

  return () => {
      window.removeEventListener('keydown', handleKeyDown);
  };
}, [handleKeyDown]);

if (wrongSession || error) return <FourOhFourPage />
if (loading || orderLoading || questionAttemptsLoading || tokenLoading || !currentQuestion) return <SpinLoader />;
const currentQuestionId = data.questions[currentQuestionIndex];
const currentQuestionState = questionStates.find(qs => qs.question_details.id === currentQuestionId);
const OptionsComponent = currentQuestion && currentQuestion.question_type === 4 ? RLDisplay : MCQDisplay;
    

  
  return (
    <div>
    <GoBackButton 
      navigateTo={'/trial-MSRA/all-session-summary/PD'}
      altText={'All Trial PD Sessions'}
    />
    <h5 className="lg:text-3xl text-2xl lg:mt-6 mt-4 font-bold tracking-tight text-center text-gray-900 dark:text-white">Review session</h5>
    <h5 className="lg:text-lg text-md text-center">You are reviewing a previous session you completed. Answers can't be changed.</h5>
      <div className="flex flex-col justify-center lg:flex-row mx-auto p-1 md:p-4 lg:p-10 gap-8">
          <Card className="flex flex-col w-full  lg:w-[50vw] md:p-8 p-2">
              <div className="flex items-center justify-between mb-4">
                  <Button 
                      variant='outline'
                      className={`hidden md:block ${currentQuestionIndex === 0 ? 'invisible' : ''}`} 
                      onClick={goToPreviousQuestion}
                      >
                      Previous
                  </Button>
                  <CircleArrowLeft
                      className={`block md:hidden ${currentQuestionIndex === 0 ? 'invisible' : ''}`}
                      onClick={goToPreviousQuestion}
                  />
                  <h5 className='text-xl font-bold'>
                      Question {currentQuestionIndex + 1} of {questions.length}
                  </h5>
                  <CircleArrowRight
                      className={`block md:hidden ${(currentQuestionIndex === data.questions.length - 1) ? 'invisible' : ''}`}
                      onClick={goToNextQuestion}
                  />
                  <Button
                      variant='outline'
                      className={`hidden md:block ${(currentQuestionIndex === data.questions.length - 1) ? 'invisible' : ''}`} 
                      onClick={goToNextQuestion}
                      >
                      Next
                  </Button>
              </div>
              <Separator orientation='horizontal' />
              <div className="mb-4">
                  <OptionsComponent
                      questions={currentQuestion}
                      isAnswered={currentQuestionState?.answered}
                      questionType={currentQuestion && currentQuestion.question_type}
                      userAnswer={userAnswer[currentQuestionId]}
                      fetchedUserAnswer={questionAttempts[currentQuestionId]}
                      optionOrder={optionOrder[currentQuestionId]}
                      manualScore={manualScore}
                      questionStats={questionStats.questionId[currentQuestionId]}
                      reviewState={true}
                  />
              </div>
          </Card>
          <div>
              <Card className="w-full lg:min-w-[17vw] p-4">
                <ReviewSessionFormattedQuestionList
                      expandedQuestionOrder={data.questions} 
                      questionStates={questionStates} 
                      questionAttempts={questionAttempts}
                      navigateToQuestion={navigateToQuestion}
                      localUserAnswer={userAnswer}
                      currentQuestionIndex={currentQuestionIndex}
                      allQuestions={questions}
                      totalPoints={totalPoints} 
                      overallScore={overallScore}
                      manualScore={manualScore}
                  />
              </Card>
          </div>
      </div>
  </div>
  )
}

export default TrialPDSingleSessionReview