// authActions.js
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_IDLE = 'SET_IDLE';
export const SET_ACTIVE = 'SET_ACTIVE';

export const setAuthenticated = () => ({
  type: SET_AUTHENTICATED,
});

export const setUnauthenticated = () => ({
  type: SET_UNAUTHENTICATED,
});

export const setIdle = () => ({
  type: SET_IDLE,
});

export const setActive = () => ({
  type: SET_ACTIVE,
});
