import React from 'react';
import { HoverCard, HoverCardContent, HoverCardTrigger, } from '../../../shadcn/ui/hover-card';

export const SBADisplay = ({ questions, isAnswered, onUserAnswerChange, data, allQuestionAttempts, localUserAnswer, questionAttemptsStats, reviewState }) => {
    const sessionId = data.id;
    const currentQuestionAttempt = allQuestionAttempts[questions.id];
    const formattedLocalUserAnswer = localUserAnswer && localUserAnswer[questions.id];
    const fetchedUserAnswer = currentQuestionAttempt && currentQuestionAttempt.user_answer;
    const correctAnswer = questions.sba_answer;

    const userAnswerIndex = isAnswered ? (formattedLocalUserAnswer !== undefined ? formattedLocalUserAnswer : fetchedUserAnswer) : null;

    return (
        <div className="flex flex-col">
            {reviewState && !isAnswered && (
            <div className="font-semibold italic text-gray-600 text-center underline my-4 dark:text-gray-300">
                You did not answer this question.
            </div>
            )}
            <div className="p-4 mb-4 border-b border-gray-200" dangerouslySetInnerHTML={{ __html: questions.content }} />
            <div className="text-sm italic text-gray-600 mb-4 dark:text-gray-300">
                Please select the most appropriate answer.
            </div>
            {questions.options.map((option, index) => {
                const statsPercentage = questionAttemptsStats.questionId[questions.id].option[index].percentage
                const statsCount = questionAttemptsStats.questionId[questions.id].option[index].count
                const isUserAnswer = index === userAnswerIndex;
                const isCorrectAnswer = index === correctAnswer;
                const bgColor = isAnswered ? (
                    isCorrectAnswer ? 'bg-green-200 dark:bg-green-800' :
                    isUserAnswer ? 'bg-red-200 dark:bg-red-800' : ''
                ) : '';

            if (!isAnswered) {
                return (
                    <label key={option.id} className={`flex items-center dark:text-white mb-4 p-4 border rounded shadow-sm  dark:text-black ${!reviewState ? 'cursor-pointer dark:hover:bg-black/20 hover:bg-gray-100' : 'cursor-not-allowed'}`}>
                        <input 

                            type="radio"
                            name={`question-${questions.id}`}
                            value={index}
                            checked={formattedLocalUserAnswer === index}
                            onChange={(e) => onUserAnswerChange(Number(e.target.value))}
                            className="mr-2"
                            disabled={isAnswered || reviewState}
                        />
                        <div dangerouslySetInnerHTML={{ __html: option.content }} />
                    </label>
                );
            }
                return (
                    <label key={option.id} className={`flex items-center dark:text-white mb-4 p-4 border rounded shadow-sm ${bgColor} ${!isAnswered && !reviewState ? 'hover:bg-gray-100 cursor-pointer' : ''} dark:text-black`}>
                        <input 
                            type="radio"
                            name={`question-${questions.id}`}
                            value={index}
                            checked={isUserAnswer}
                            onChange={(e) => onUserAnswerChange(Number(e.target.value))}
                            className="mr-2"
                            disabled={isAnswered || reviewState}
                        />
                        <div className="items-center "style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>  {/* Adjusted for vertical alignment */}
                            <div dangerouslySetInnerHTML={{ __html: option.content }} />

                            <HoverCard>
                                <HoverCardTrigger asChild>
                                    <div className='text-sm text-muted-foreground' style={{ whiteSpace: 'nowrap' }}>{statsPercentage}%</div>
                                </HoverCardTrigger>
                                <HoverCardContent className="w-80">
                                    <div className="flex justify-between space-x-4">                        
                                        <div className="space-y-1">
                                            <h4 className="text-sm font-semibold">Of all users who attempted this question, {statsPercentage}% selected this option as their answer.</h4>
                                        </div>
                                    </div>
                                </HoverCardContent>
                            </HoverCard>

                        </div>
                    </label>
                );
            })}
            {isAnswered && (
                <>
                    <div className="mt-4 font-semibold">
                        {userAnswerIndex === correctAnswer ? (
                            <span className="text-green-500">Correct!</span>
                        ) : (
                            <span className="text-red-500">Incorrect. Correct Answer: {questions.options[correctAnswer].content}</span>
                        )}
                    </div>
                    {questions.explanation && (
                        <>
                            <div className="underline italic text-gray-600 dark:text-gray-300">
                                Explanation:
                            </div>
                            <div className="mb-4" dangerouslySetInnerHTML={{ __html: questions.explanation }} />
                        </>
                    )}
                </>
            )}
        </div>
    );
};
