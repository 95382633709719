import React, { useEffect, useState } from 'react'
import { useFetchSessionSummary } from '../../../../../components/hooks/MSRA/fetches/FetchSessionSummary'
import { useParams } from 'react-router-dom'
import SessionSummaryItem from '../../../../../components/common/SessionSummaryItem'
import SpinLoader from '../../../../../components/loader/SpinLoader';
import { useAuth0 } from '@auth0/auth0-react';
import FourOhFourPage from '../../../../errors/FourOhFourPage';
import PieChart from '../../../../../components/visualisation/PieChart';

function TrialCPSSessionSummary() {
  const { sessionId } = useParams();
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const [token, setToken] = useState(null);
  const [tokenLoading, setTokenLoading] = useState(true);
  const [wrongSession, setWrongSession] = useState(false)
  const [wrongSessionLoading, setWrongSessionLoading] = useState(true)
  
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setToken(token);
      } catch (error) {
        console.error('Error fetching token:', error);
      } finally {
        setTokenLoading(false);
      }
    };

    fetchToken();
  }, [getAccessTokenSilently]);

  const { data, loading, error } = useFetchSessionSummary(sessionId, token);

  useEffect(() => {
    if(data && data.session){
      setWrongSessionLoading(false)
      if (data.session.paper_id !== 6){
        setWrongSession(true)
      }
    }
  }, [data, token])

  if(error || wrongSession) return <FourOhFourPage />
  if (loading || tokenLoading || wrongSessionLoading) return <SpinLoader />

  return (
    <>
    
      <div className="flex flex-col items-center mt-10">
          <h5 className={`text-3xl font-bold tracking-tight mb-4 text-gray-900 dark:text-white`}>Session summary:</h5>
          <ul className="w-full flex flex-col items-center">
              <SessionSummaryItem session={data.session} paperType={'CPS Trial'} />
          </ul>
      </div>
    </>
  )
}

export default TrialCPSSessionSummary