import PricingText from '../../components/pricing/PricingText'
import PricingOptions from '../../components/pricing/PricingOptions'
import React from 'react'

function Pricing() {
  return (
    <div className='mb-6 sm:mb-8'>
      <PricingText />
      <PricingOptions />
    </div>
  )
}

export default Pricing