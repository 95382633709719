import { Link, useNavigate } from 'react-router-dom'
import QuestionFilter from '../../../../../components/cps_filter/QuestionFilter.jsx'
import StartSessionCard from '../../../../../components/cps_filter/StartSessionCard'
import GoBackButton from '../../../../../components/other_ui/GoBackButton'
import React, { useEffect, useState } from 'react'
import { useFetchMSRASessionData } from '../../../../../components/hooks/MSRA/fetches/FetchActiveMSRASessionData'
import { useFetchQuestionCountByPaper } from '../../../../../components/hooks/MSRA/fetches/FetchQuestionCountByPaper'
import SpinLoader from '../../../../../components/loader/SpinLoader'
import { useAuth0 } from '@auth0/auth0-react'
import { useToast } from '../../../../../components/shadcn/ui/use-toast'
import { Card, CardContent, CardHeader, CardFooter, CardDescription, CardTitle } from '../../../../../components/shadcn/ui/card'
import { Progress } from '../../../../../components/shadcn/ui/progress'
import { Separator } from '../../../../../components/shadcn/ui/separator'
import { useFetchActiveSubPerformance } from '../../../../../components/hooks/MSRA/user_performance/FetchActiveSubPerformance.jsx'
import { getCsrfToken } from '../../../../../components/hooks/csrfTokenHelper.js'

function TrialPDHomePage() {
    const toast = useToast()
    const navigate = useNavigate()
    const [token, setToken] = useState(null)
    const [tokenLoading, setTokenLoading] = useState(true)
    const { getAccessTokenSilently, isLoading: authLoading } = useAuth0()
    const [newSessionLoading, setNewSessionLoading] = useState(false)
    
    const [showDialog, setShowDialog] = useState(false)
    const [questionFilter, setQuestionFilter] = useState('unanswered')
    const [selectedQuestions, setSelectedQuestions] = useState(null)

    
    useEffect(() => {
        const fetchToken = async () => {
          try {
            const token = await getAccessTokenSilently();
            setToken(token);
          } catch (error) {
            console.error('Error fetching token:', error);
          } finally {
            setTokenLoading(false);
          }
        };
        
        fetchToken();
    }, [getAccessTokenSilently]);
    
    const { data: existingSession, loading: existingLoading, error: existingError } = useFetchMSRASessionData(5, token, "MSRA Free Trial")
    const { questionCount, loading: countLoading, error: countError } = useFetchQuestionCountByPaper(5, token, "MSRA Free Trial")
    const { loading, pdPerformance, pdCoverage } = useFetchActiveSubPerformance(token, "MSRA Free Trial")

    const allLoading = loading || countLoading || existingLoading || tokenLoading || newSessionLoading

    useEffect(() => {
        if(questionFilter === "unanswered"){
            setSelectedQuestions(questionCount.unanswered)
        } else if(questionFilter === "all"){
            setSelectedQuestions(questionCount.total)
        } else if(questionFilter === "answered"){
            setSelectedQuestions(questionCount.answered)
        } else {
            setSelectedQuestions(undefined)
        }
    }, [questionCount, questionFilter])

    const handleResumeSession = () => {
        navigate('/trial-MSRA/PD/session')
    }

    const handleOpenDialog = () => {
        setShowDialog(true)
    }


    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the form from submitting in the traditional way
      
        if(selectedQuestions === 0){
            toast({
                variant: "destructive",
                title: "Uh oh! You haven't selected any questions.",
                description: "Please double check your preferences to make sure there are questions in your new revision session.",
                duration: 4000,
            });
            return
        }

        // Proceed with creating a new session if no existing session or existing session ended successfully
        const selectedCategoryIds = [14];
        const payload = {
            exam: 5,  // Explicitly setting 'exam' to MSRA id
            paper: 5,  // Explicitly setting 'paper' to CPS id
            selectedCategoryIds,
            questionFilter: questionFilter.toUpperCase()
        };
      
        try {
            setNewSessionLoading(true)
            const csrfToken = getCsrfToken();
            const response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/MSRA/user-session/create-user-session/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "X-CSRFToken": csrfToken,  
                },
                body: JSON.stringify(payload)  // Ensure payload is structured correctly and stringified
            });
      
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
      
            const data = await response.json();
            navigate('/trial-MSRA/PD/session');
        } catch (error) {
            console.error('Error creating user session:', error);
        }
      };

    if(allLoading) return <SpinLoader />

  return (
    <>
        <GoBackButton 
            altText='All Free Trial MSRA Papers'
            navigateTo='/trial-MSRA/home'
            />
        <div className="container mx-auto px-4 pb-8 md:px-6 md:pb-12">
            <div className="space-y-8">
            <div className="text-center sm:text-left sm:max-w-[80%] mx-auto">
                <h1 
                className="text-3xl font-bold"
                >FREE TRIAL - Professional Dilemmas Paper</h1>
                <h1 className="text-xl font-semibold">Question Bank</h1>
                <div>
                    <span
                        className="mt-1 text-black/60 dark:text-white/60 text-sm hover:underline cursor-pointer"
                        onClick={() => navigate("/trial-MSRA/all-session-summary/PD")}
                    >
                        Review Previous Sessions
                    </span>
                </div>
            </div>
            <Card className='sm:max-w-[50%] mx-auto'>
                <CardHeader>
                    <CardTitle>Your Summary</CardTitle>
                    <CardDescription>{questionCount.total} Total Questions</CardDescription>
                </CardHeader>
                <CardContent className='space-y-4'>
                    <div className="flex items-center justify-between">
                        <div className="font-medium text-foreground">Questions Answered:</div>
                        <div className="text-right">
                            <div className="text-muted-foreground">{!pdCoverage || pdCoverage.coverage_percentage === 0 ? 0 : pdCoverage.coverage_percentage.toFixed(1)}% Complete</div>
                            {pdCoverage && pdCoverage.total_questions_answered > 0 && 
                                <div className="text-xs text-muted-foreground">({pdCoverage.total_questions_answered} / {pdCoverage.total_questions})</div>
                            }
                        </div>
                    </div>
                    <Progress value={pdCoverage ? pdCoverage.coverage_percentage : 0} />
                    <div className="flex items-center justify-between">
                        <div className="font-medium text-foreground">Correct Answers:</div>
                        <div className="text-muted-foreground">
                        {pdCoverage && pdCoverage.total_questions_answered > 0 ? `${pdPerformance.performance_percentage.toFixed(1)}%` : "No questions answered yet"}
                        </div>
                    </div>
                    <Progress value={pdPerformance ? pdPerformance.performance_percentage : 0} />
                </CardContent>
                <div className="inline sm:hidden">
                    <Separator orientation="horizontal" className='mt-2 mb-4'/>
                </div>
            </Card>
            <div className="grid sm:max-w-[70%] mx-auto gap-8">
                <div className="mx-auto">
                <QuestionFilter
                    selectedFilter={questionFilter}
                    setSelectedFilter={setQuestionFilter}
                    />
                </div>
                <StartSessionCard 
                    selectedQuestions={selectedQuestions}
                    existingSession={existingSession}
                    handleOpenDialog={handleOpenDialog}
                    handleResumeSession={handleResumeSession}
                    handleSubmit={handleSubmit}
                    />
            </div>
            </div>
        </div>
    </>
  )
}

export default TrialPDHomePage