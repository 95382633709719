import React from 'react'
import PieChart from '../visualisation/PieChart'
import BarChart from '../visualisation/BarChart'
import HeaderMedium from '../typography/HeaderLarge';
import LineChart from '../visualisation/LineChart';

function HomePageAnalyticsDisplay() {
  const histogramData = {
        "0%": 0.0,
        "1%": 0.0,
        "2%": 0.0,
        "3%": 0.0,
        "4%": 0.0,
        "5%": 0.0,
        "6%": 0.0,
        "7%": 0.1,
        "8%": 0.1,
        "9%": 0.1,
        "10%": 0.1,
        "11%": 0.1,
        "12%": 0.1,
        "13%": 0.1,
        "14%": 0.1,
        "15%": 0.2,
        "16%": 0.2,
        "17%": 0.2,
        "18%": 0.3,
        "19%": 0.3,
        "20%": 0.4,
        "21%": 0.4,
        "22%": 0.5,
        "23%": 0.5,
        "24%": 0.6,
        "25%": 0.7,
        "26%": 0.7,
        "27%": 0.8,
        "28%": 0.9,
        "29%": 1.0,
        "30%": 1.1,
        "31%": 1.2,
        "32%": 1.3,
        "33%": 1.4,
        "34%": 1.5,
        "35%": 1.6,
        "36%": 1.7,
        "37%": 1.8,
        "38%": 1.9,
        "39%": 2.0,
        "40%": 2.1,
        "41%": 2.2,
        "42%": 2.3,
        "43%": 2.4,
        "44%": 2.5,
        "45%": 2.5,
        "46%": 2.6,
        "47%": 2.6,
        "48%": 2.7,
        "49%": 2.7,
        "50%": 2.8,
        "51%": 2.7,
        "52%": 2.7,
        "53%": 2.6,
        "54%": 2.6,
        "55%": 2.5,
        "56%": 2.5,
        "57%": 2.4,
        "58%": 2.3,
        "59%": 2.2,
        "60%": 2.1,
        "61%": 2.0,
        "62%": 1.9,
        "63%": 1.8,
        "64%": 1.7,
        "65%": 1.6,
        "66%": 1.5,
        "67%": 1.4,
        "68%": 1.3,
        "69%": 1.2,
        "70%": 1.1,
        "71%": 1.0,
        "72%": 0.9,
        "73%": 0.8,
        "74%": 0.7,
        "75%": 0.7,
        "76%": 0.6,
        "77%": 0.5,
        "78%": 0.5,
        "79%": 0.4,
        "80%": 0.4,
        "81%": 0.3,
        "82%": 0.3,
        "83%": 0.2,
        "84%": 0.2,
        "85%": 0.2,
        "86%": 0.1,
        "87%": 0.1,
        "88%": 0.1,
        "89%": 0.1,
        "90%": 0.1,
        "91%": 0.1,
        "92%": 0.1,
        "93%": 0.1,
        "94%": 0.0,
        "95%": 0.0,
        "96%": 0.0,
        "97%": 0.0,
        "98%": 0.0,
        "99%": 0.0,
        "100%": 0.0,
  }

  let cumulativeSum = 0;
  const reformattedData = {};

  const keys = Object.keys(histogramData).reverse(); // Reverse to calculate cumulative
  keys.forEach(key => {
    const proportion = histogramData[key];
    cumulativeSum += proportion;
    reformattedData[key] = {
      proportion_of_users: proportion,
      cumulative_proportion: cumulativeSum
    };
  });
  const finalData = {};
  Object.keys(histogramData).forEach(key => {
    finalData[key] = reformattedData[key];
  });
  

  return (
    <>
      <div className='lg:w-2/3 lg:mx-auto'>
        <div className="text-center text-black dark:text-white text-4xl font-semibold underline my-4 mx-2">
          Cardiology
        </div>
        <div className="lg:flex lg:justify-center grid grid-cols-1 lg:grid-cols-2 my-6">
          <div className="flex justify-center align-center text-center">
            <div className="mt-4 hidden lg:inline" >
              <PieChart
                title="Coverage"
                dashboard={true}
                data={[
                  { color: 3, label: 'Not viewed', value: 12 },
                  { color: 2, label: 'Skipped', value: 3 },
                  { color: 1, label: 'Attempted', value: 50 },
                ]}
                scoreChart={false}
                mobile={false}
              />
            </div>
            <div className="mt-4 hidden md:inline lg:hidden" >
              <PieChart
                title="Coverage"
                dashboard={true}
                data={[
                  { color: 3, label: 'Not viewed', value: 12 },
                  { color: 2, label: 'Skipped', value: 3 },
                  { color: 1, label: 'Attempted', value: 50 },
                ]}
                scoreChart={false}
                mobile={false}
              />
            </div>
            <div className="p-0 sm:hidden inline" >
              <PieChart
                title="Coverage"
                dashboard={true}
                data={[
                  { color: 3, label: 'Not viewed', value: 12 },
                  { color: 2, label: 'Skipped', value: 3 },
                  { color: 1, label: 'Attempted', value: 50 },
                ]}
                scoreChart={false}
                mobile={true}
              />
            </div>
          </div>
          <div className="flex justify-center align-center text-center">
            <div className="mt-4 hidden lg:inline" >
              <PieChart
                title="Score"
                dashboard={true}
                data={[
                  { color: 2, label: 'Incorrect', value: 21 },
                  { color: 1, label: 'Correct', value: 29 },
                ]}
                scoreChart={true}
                mobile={false}
              />
            </div>
            <div className="mt-4 hidden md:inline lg:hidden">
              <PieChart
                title="Score"
                dashboard={true}
                data={[
                  { color: 2, label: 'Incorrect', value: 21 },
                  { color: 1, label: 'Correct', value: 29 },
                ]}
                scoreChart={true}
                mobile={false}
              />
            </div>
            <div className="p-0 sm:hidden inline">
              <PieChart
                title="Score"
                dashboard={true}
                data={[
                  { color: 2, label: 'Incorrect', value: 21 },
                  { color: 1, label: 'Correct', value: 29 },
                ]}
                scoreChart={true}
                mobile={true}
              />  
            </div>
          </div>
        </div>
        <div className="my-4">
          <div className="lg:w-full md:w-2/3 w-full mx-auto mb-4">
            <div className="text-center py-4">
              <div className='font-semibold text-2xl sm:text-3xl'>
                Percentile by Score
              </div>
            </div>
            <div className="mt-4 hidden lg:inline" >
              <BarChart homePage={true} data={finalData} performancePercentage={60} viewPort='large' />
            </div>
            <div className="mt-4 hidden md:inline lg:hidden">
              <BarChart homePage={true} data={finalData} performancePercentage={60} viewPort='medium' />
            </div>
            <div className="p-0 sm:hidden inline">
              <BarChart homePage={true} data={finalData} performancePercentage={60} viewPort='small' />
            </div>

          </div>
        </div>
      </div>     
    </>
  )
}

export default HomePageAnalyticsDisplay