import React, { useEffect, useState } from 'react'
import LogoutButton from './LogoutButton';
import { useSelector, useDispatch } from 'react-redux';
import { Separator } from '../shadcn/ui/separator';
import { Button } from '../shadcn/ui/button';
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
  } from "../shadcn/ui/sheet"

  import { Link, useNavigate } from 'react-router-dom';  // If you're using react-router for navigation
import { setUnauthenticated } from '../../redux/authActions';
import { useTheme } from './ThemeContext';
import { Moon, Sun, ChevronDown, ChevronUp, Home, BookOpen, LineChart, AtSign, Info, BookOpenCheck, SquareCheckBig } from 'lucide-react';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
    DropdownMenuSeparator,
    DropdownMenuLabel
  } from "../shadcn/ui/dropdown-menu"

import { useAuth0 } from "@auth0/auth0-react";
import LogoIcon from './LogoIcon';
import { animated } from 'react-spring';
import { useDropdownAnimation } from '../hooks/animation/useDropdownAnimation';
import useFetchUserSubscriptions from '../../components/hooks/subscriptions/useFetchUserSubscriptions';


function Navbar() {    
    const { theme, toggleTheme } = useTheme();
    const [msraOpen, setMsraOpen] = useState(false);
    const [msraTrialOpen, setMsraTrialOpen] = useState(false);
    const msraAnimation = useDropdownAnimation(msraOpen);
    const msraTrialAnimation = useDropdownAnimation(msraTrialOpen);
    const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0();
    const [ activeSubscriptions, setActiveSubscriptions ] = useState([])
    const [sheetIsOpen, setSheetIsOpen] = useState(false)

  
    const [mobileStudyOpen, setMobileStudyOpen] = useState(false);
    const [mobileStudyTrialOpen, setMobileStudyTrialOpen] = useState(false);
    const mobileStudyAnimation = useDropdownAnimation(mobileStudyOpen);
    const mobileStudyTrialAnimation = useDropdownAnimation(mobileStudyTrialOpen);

    const [mobileMsraOpen, setMobileMsraOpen] = useState(false);
    const [mobileMsraTrialOpen, setMobileMsraTrialOpen] = useState(false);
    const mobileMsraAnimation = useDropdownAnimation(mobileMsraOpen)
    const mobileMsraTrialAnimation = useDropdownAnimation(mobileMsraTrialOpen)
    const { fetchUserSubscriptions, data, loading: subscriptionLoading, error } = useFetchUserSubscriptions();
    const [fetchSubLoading, setFetchSubLoading] = useState(true)
  
    const textColor = theme === 'dark' ? '#eeeeee' : '#183477';


    const navigate = useNavigate();

    const toggleItems = (exam) => {
        if (exam === 'MSRA') {
            setMsraOpen(!msraOpen)
        } else if (exam === 'mobile-study'){
            setMobileStudyOpen(!mobileStudyOpen)
        } else if (exam === 'mobile-msra'){
            setMobileMsraOpen(!mobileMsraOpen)
        } else if (exam === 'MSRA Trial') {
            setMsraTrialOpen(!msraTrialOpen)
        } else if (exam === 'mobile-study-trial') {
            setMobileStudyTrialOpen(!mobileStudyTrialOpen)
        } else if (exam === 'mobile-msra-trial') {
            setMobileMsraTrialOpen(!msraTrialOpen)
        } 
      };

      const mobileStudyIcon = {
        transition: 'transform 0.3s ease',
        transform: mobileStudyOpen ? 'rotate(180deg)' : 'rotate(0deg)'
    };

      const mobileMSRAIcon = {
        transition: 'transform 0.3s ease',
        transform: mobileMsraOpen ? 'rotate(180deg)' : 'rotate(0deg)'
    };

      const MSRAIcon = {
        transition: 'transform 0.3s ease',
        transform: msraOpen ? 'rotate(180deg)' : 'rotate(0deg)'
    };

        const mobileStudyTrialIcon = {
        transition: 'transform 0.3s ease',
        transform: mobileStudyTrialOpen ? 'rotate(180deg)' : 'rotate(0deg)'
    };

      const mobileMSRATrialIcon = {
        transition: 'transform 0.3s ease',
        transform: mobileMsraTrialOpen ? 'rotate(180deg)' : 'rotate(0deg)'
    };

      const MSRATrialIcon = {
        transition: 'transform 0.3s ease',
        transform: msraTrialOpen ? 'rotate(180deg)' : 'rotate(0deg)'
    };

    useEffect(() => {
          setFetchSubLoading(false)
          fetchUserSubscriptions();
      }, []);

    useEffect(() => {
        if (data && data.subscriptions) {
          const activeSubs = data.subscriptions.filter(sub => sub.is_active);
          setActiveSubscriptions(activeSubs);
        }
      }, [data]);

    const MSRAExists = activeSubscriptions.some(subscription => subscription.exam_id === 1);
    const MSRATrialExists = activeSubscriptions.some(subscription => subscription.exam_id === 5)

    const sheetNavigate = (destination) => {
        navigate(destination);
        setSheetIsOpen(false);
    }

    return (
        <div className='navbar'>
        <nav className="w-full h-60px z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-800">
            <div className="max-w-screen-lg flex flex-wrap justify-between mx-auto p-2">
                <Link to={isAuthenticated ? "/profile" : "/"} className="flex items-center space-x-3 ml-0 rtl:space-x-reverse">
                    <div className="hidden md:block">
                        <LogoIcon
                            width={50}
                            height={55}
                        />
                    </div>
                    <div className="block md:hidden ml-0">
                        <LogoIcon
                            width={40}
                            height={45}
                        />
                    </div>
                    <span className="scroll-m-20 hidden md:block text-2xl pt-2 font-semibold tracking-tight" id="navbar-title" style={{ color: textColor }}>
                        ExamVitals
                    </span>
                </Link>
                {!isLoading && !subscriptionLoading && !fetchSubLoading && (
                    <>
                    {/* mobile menu: */}
                        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse items-center">
                            <div className="hidden lg:flex space-x-3 items-center">
                                {isAuthenticated  ?
                                    (<LogoutButton />)
                                    :
                                    (<>
                                        <Button variant='link' onClick={() => loginWithRedirect()}>
                                            Login
                                        </Button>
                                        <Button size="sm" onClick={() => loginWithRedirect({authorizationParams: { screen_hint: "signup", }})}>
                                            Get started
                                        </Button>
                                    </>
                                )}
                                <Button onClick={() => toggleTheme()} variant="outline" size="icon">
                                <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:rotate-90 dark:scale-0" />
                                <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
                                <span className="sr-only">Toggle theme</span>
                                </Button>
                            </div>
                                <Sheet open={sheetIsOpen} onOpenChange={setSheetIsOpen}>
                                    <SheetTrigger>
                                        <button 
                                            data-collapse-toggle="navbar-sticky" 
                                            type="button" 
                                            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" 
                                            aria-controls="navbar-sticky" 
                                            aria-expanded="false"
                                        >
                                            <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                <path d="M4 6h16M4 12h16m-7 6h7" />
                                            </svg>
                                        </button>
                                    </SheetTrigger>
                                    <SheetContent>
                                        <SheetHeader>
                                            <SheetTitle className="text-center">Menu</SheetTitle>
                                        </SheetHeader>

                                        {/* Other menu items */}
                                        <ul className='grid gap-1'>
                                            <li className='hover:underline' onClick={() => sheetNavigate(isAuthenticated ? '/profile' : '/')}><Link to={isAuthenticated ? '/profile' : '/'}>Home</Link></li>
                                            <Separator orientation='horizontal'/>
                                            {isAuthenticated && activeSubscriptions.length > 0 && (
                                                <>
                                                    <li onClick={() => toggleItems('mobile-study')} className='label-inline'>
                                                        <div className="flex items-center cursor-pointer hover:underline gap-1">
                                                            Questions
                                                            <div style={mobileStudyIcon}>
                                                                <ChevronDown size={16} />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <Separator orientation='horizontal'/> 
                                                    <animated.div style={mobileStudyAnimation}>
                                                        {MSRAExists ? (
                                                            <li  onClick={() => toggleItems('mobile-msra')} className='label-inline ml-2'>
                                                                <div className="flex items-center cursor-pointer hover:underline gap-1">
                                                                    MSRA
                                                                    <div style={mobileMSRAIcon}>
                                                                        <ChevronDown size={16} />
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ) : (
                                                            <li  onClick={() => toggleItems('mobile-msra-trial')} className='label-inline ml-2'>
                                                                <div className="flex items-center cursor-pointer hover:underline gap-1">
                                                                    MSRA Free Trial
                                                                    <div style={mobileMSRATrialIcon}>
                                                                        <ChevronDown size={16} />
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                        }
                                                        <Separator orientation='horizontal'/> 
                                                        <animated.div style={mobileMsraAnimation}>
                                                            <div className="my-2 flex flex-col gap-2">
                                                                <li className='ml-4 cursor-pointer hover:underline' onClick={() => sheetNavigate('/MSRA/CPS/start-session')}>Clinical Problem Solving</li>
                                                                <li className='ml-4 cursor-pointer hover:underline' onClick={() => sheetNavigate('/MSRA/PD/start-session')}>Professional Dilemmas</li>
                                                            </div>
                                                            <Separator orientation='horizontal'/> 
                                                        </animated.div>
                                                        <animated.div style={mobileMsraTrialAnimation}>
                                                            <div className="my-2 flex flex-col gap-2">
                                                                <li className='ml-4 cursor-pointer hover:underline' onClick={() => sheetNavigate('/trial-MSRA/CPS/start-session')}>Clinical Problem Solving Free Trial</li>
                                                                <li className='ml-4 cursor-pointer hover:underline' onClick={() => sheetNavigate('/trial-MSRA/PD/start-session')}>Professional Dilemmas Free Trial</li>
                                                            </div>
                                                            <Separator orientation='horizontal'/> 
                                                        </animated.div>
                                                    </animated.div>     
                                                    <li 
                                                        onClick={() => sheetNavigate(MSRAExists ? '/MSRA/textbook/home' : '/trial-MSRA/textbook/home')} 
                                                        className='label-inline cursor-pointer hover:underline'>
                                                        Textbook
                                                    </li>
                                                    <Separator orientation='horizontal'/> 
                                                    <li 
                                                        onClick={() => sheetNavigate(MSRAExists ? '/MSRA/dashboard' : '/trial-MSRA/dashboard')} 
                                                        className='label-inline cursor-pointer hover:underline'>
                                                        Analytics
                                                    </li>    
                                                    <Separator orientation='horizontal'/> 
                                                </>
                                            )}
                                            
                                            <span>
                                                {/* Authentication buttons and links */}
                                                {!isAuthenticated && (
                                                    <>
                                                        <li className='label-inline cursor-pointer hover:underline mb-1' onClick={() => loginWithRedirect()}>
                                                            Login
                                                        </li>
                                                        <Separator orientation='horizontal'/>
                                                        <li className='label-inline cursor-pointer hover:underline my-1' onClick={() => loginWithRedirect({authorizationParams: { screen_hint: "signup", }})}>Get started</li>
                                                        <Separator orientation='horizontal'/>
                                                    </>
                                                )}
                                                <li><button className='label-inline cursor-pointer hover:underline' onClick={() => toggleTheme()}>Toggle light/dark mode</button></li>
                                                {/* Logout button when authenticated */}
                                                {isAuthenticated && (
                                                        <>
                                                        <Separator orientation='horizontal'/>
                                                        <li><button className='label-inline cursor-pointer hover:underline' onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Logout</button></li>
                                                        </>
                                                        )}
                                            </span>
                                        </ul>
                                    </SheetContent>
                                </Sheet>
                        </div>
                        <div className="items-center justify-between hidden w-full lg:flex md:w-auto md:order-1" id="navbar-sticky">
                                {isAuthenticated && activeSubscriptions.length > 0 && (
                                    <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 dark:border-gray-700">    
                                        <li>
                                            <DropdownMenu>
                                                <DropdownMenuTrigger>
                                                    <Button className="dark:text-gray-300 cursor-pointer" variant='link' asChild>
                                                        <span className="gap-2 items-center">
                                                            <SquareCheckBig size={16} />
                                                            <span>Questions</span>
                                                        </span>
                                                    </Button>
                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent className='w-[150px]'>
                                                    {MSRAExists ? (
                                                        <>
                                                            <DropdownMenuLabel onClick={() => toggleItems('MSRA')} className='w-[150px] flex items-center justify-between cursor-pointer'>
                                                                <span>MSRA</span>
                                                                <div style={MSRAIcon} className='mr-2'>
                                                                    <ChevronDown size={16} />
                                                                </div>
                                                            </DropdownMenuLabel>
                                                            <animated.div style={msraAnimation}>
                                                                <DropdownMenuItem 
                                                                    onClick={() => navigate('/MSRA/CPS/start-session')}
                                                                    className='hover:underline cursor-pointer'>
                                                                    Clinical Problem Solving
                                                                </DropdownMenuItem>
                                                                <DropdownMenuSeparator />
                                                                <DropdownMenuItem 
                                                                    onClick={() => navigate('/MSRA/PD/start-session')}
                                                                    className='hover:underline cursor-pointer'
                                                                    >
                                                                    Professional Dilemmas
                                                                </DropdownMenuItem>
                                                            </animated.div>
                                                        </>
                                                    ) : 
                                                    (
                                                        <>
                                                            <DropdownMenuLabel onClick={() => toggleItems('MSRA Trial')} className='w-[150px] flex items-center justify-between cursor-pointer'>
                                                                <span>MSRA Free Trial</span>
                                                                <div style={MSRATrialIcon} className='mr-2'>
                                                                    <ChevronDown size={16} />
                                                                </div>
                                                            </DropdownMenuLabel>
                                                            <animated.div style={msraTrialAnimation}>
                                                                <DropdownMenuItem 
                                                                    onClick={() => navigate('/trial-MSRA/CPS/start-session')}
                                                                    className='hover:underline cursor-pointer'>
                                                                    Clinical Problem Solving Free Trial
                                                                </DropdownMenuItem>
                                                                <DropdownMenuSeparator />
                                                                <DropdownMenuItem 
                                                                    onClick={() => navigate('/trial-MSRA/PD/start-session')}
                                                                    className='hover:underline cursor-pointer'
                                                                    >
                                                                    Professional Dilemmas Free Trial
                                                                </DropdownMenuItem>
                                                            </animated.div>
                                                        </>
                                                    )}
                                                </DropdownMenuContent>
                                            </DropdownMenu>
                                        </li>
                                        <li><Separator orientation='vertical' /></li>
                                        <li>
                                            <Button className="dark:text-gray-300 cursor-pointer" variant='link' onClick={() => navigate(MSRAExists ? `/MSRA/textbook/home` : `/trial-MSRA/textbook/home`)} asChild>
                                                <span className="gap-2 items-center">
                                                    <BookOpen size={16} />
                                                    <span>Textbook</span>
                                                </span>
                                            </Button>
                                        </li>
                                        <li><Separator orientation='vertical' /></li>
                                        <li>
                                            <Button className="dark:text-gray-300 cursor-pointer" variant='link' onClick={() => navigate(MSRAExists ? '/MSRA/dashboard' : '/trial-MSRA/dashboard')} asChild>
                                                <span className="gap-2 items-center">
                                                    <LineChart size={16} />
                                                    <span>Analytics</span>
                                                </span>
                                            </Button>
                                        </li>
                                    </ul>
                                )}
                        </div>
                    </>
                )}
            </div>
        </nav>
    </div>

  )
}

export default Navbar