import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { getCsrfToken } from '../hooks/csrfTokenHelper';
import SpinLoader from '../loader/SpinLoader';

const csrfToken = getCsrfToken();

const fetchUserSubscriptions = async (token) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}custom_api/subscriptions/my_subscriptions/`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-CSRFToken": csrfToken,  // Add CSRF token to headers
    }
  });
  return response.data;
};

const MSRAProtectedRoute = ({ children }) => {
  const { isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [checkingSubscriptions, setCheckingSubscriptions] = useState(true);
  const [hasMSRASubscription, setHasMSRASubscription] = useState(false);

  const { data, error, isLoading: subscriptionsLoading } = useQuery('userSubscriptions', async () => {
    const token = await getAccessTokenSilently();
    return fetchUserSubscriptions(token);
  });

  useEffect(() => {
    if (!subscriptionsLoading && data && data.subscriptions) {
      const msraSubscription = data.subscriptions.find(sub => sub.exam_id === 1 && sub.is_active);
      setHasMSRASubscription(!!msraSubscription);
      setCheckingSubscriptions(false);
    }
  }, [data, subscriptionsLoading]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  
  if (isLoading || subscriptionsLoading || checkingSubscriptions) {
    return <SpinLoader />; // or a loading indicator
  }

  if (!isAuthenticated) {
    return <>You need to log in</>; // or a loading indicator
  }

  if (!hasMSRASubscription) {
    return <Navigate to="/" />; // Redirect to a no-access page or any other route
  }
  return children;
};

export default MSRAProtectedRoute;
