import { useState, useEffect } from 'react';
import { getCsrfToken } from '../../csrfTokenHelper';

export const useFetchQuestionCountByPaper = (paperId, token, exam_name) => {
    const [questionCount, setQuestionCount] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!token || !paperId) {
            return;
        }
    
        const fetchSession = async () => {
            try {
                const csrfToken = getCsrfToken();
                let response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/MSRA/user-session/user-paper-stats/${paperId}/?exam_name=${encodeURIComponent(exam_name)}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "X-CSRFToken": csrfToken,  // Add CSRF token to headers
                    }
                });
    
                if (!response.ok) throw new Error('Failed to fetch question counts');
    
                const questionCount = await response.json();
                setQuestionCount(questionCount);
            } catch (error) {
                console.error('Error fetching question counts:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchSession();
    }, [token, paperId]);
    

    return { questionCount, loading, error };
};