import { useFetchTextbookByExam } from '../../../components/hooks/MSRA/fetches/textbook/FetchTextbookByExam'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CustomLoader from '../../../components/loader/CustomLoader'
import TextbookSideBar from '../../../components/textbook/TextbookSideBar'
import TextbookDisplay from '../../../components/textbook/TextbookDisplay'
import { useFetchSingleTextbookItem } from '../../../components/hooks/MSRA/fetches/textbook/FetchSingleTextbook'
import TextbookMobileSearch from '../../../components/textbook/TextbookMobileSearch'

function Textbook() {
    const {textbookItems: textbookList, loading } = useFetchTextbookByExam(1)
    const navigate = useNavigate()
    const { id } = useParams()
    const {textbookItem, loading: singleLoad } = useFetchSingleTextbookItem(id)
    const [searchTerm, setSearchTerm] = useState('');


    if (loading || singleLoad) return <CustomLoader loadingText={'Loading textbook...'}/> 
    return (
    <>
        <div className="flex md:flex-row flex-col">
            <TextbookSideBar
                textbookItems={textbookList}
                navigate={navigate}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />
            <TextbookMobileSearch
                textbookItems={textbookList}
                navigate={navigate}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />
            <TextbookDisplay
                textbookItem={textbookItem}
                param={id}
                loading={loading}
                singleLoading={singleLoad}
            />
        </div>
    </>
  )
}

export default Textbook