import React, {useState, useEffect} from "react"
import { Tabs, TabsList, TabsTrigger } from "../../../../components/shadcn/ui/tabs"
import { useFetchMSRAPapers } from "../../../../components/hooks/MSRA/fetches/FetchMSRAPapers"
import { useFetchCPSCategories } from "../../../../components/hooks/MSRA/fetches/FetchCPSCategories"
import { useFetchCPSSubcategories } from "../../../../components/hooks/MSRA/fetches/FetchCPSSubcategories"
import { useParams } from "react-router-dom"
import PaperPerformance from "../../../../components/dashboard/MSRA/PaperPerformance"
import CategoryPerformance from "../../../../components/dashboard/MSRA/CategoryPerformance"
import SubcategoryPerformance from "../../../../components/dashboard/MSRA/SubcategoryPerformance"
import { useFetchActiveSubPerformance } from "../../../../components/hooks/MSRA/user_performance/FetchActiveSubPerformance"
import { useFetchUserPercentile } from "../../../../components/hooks/MSRA/fetches/dashboard/FetchUserPercentile"
import { useFetchHistogramData } from "../../../../components/hooks/MSRA/fetches/dashboard/FetchHistogram"
import CustomLoader from "../../../../components/loader/CustomLoader"
import HeaderLarge from '../../../../components/typography/HeaderLarge'
import { useAuth0 } from "@auth0/auth0-react"
import SpinLoader from "../../../../components/loader/SpinLoader"

function MSRADashboard() {
    const [selectedTab, setSelectedTab] = useState('paper');
    const [selectedPaper, setSelectedPaper] = useState('Clinical Problem Solving')
    const [selectedCategory, setSelectedCategory] = useState('General Medicine')
    const [selectedSubcategory, setSelectedSubcategory] = useState('Haematology')
    // paper tab variables
    const [CPSdata, setCPSdata] = useState()
    const [PDdata, setPDdata] = useState()
    const [stateVariableLoading, setStateVariableLoading] = useState(true)
    // category tab variables
    const [categoryData, setCategoryData] = useState()
    //  subcategory tab variables
    const [subcategoryData, setSubcategoryData] = useState()

    const [token, setToken] = useState(null)
    const [tokenLoading, setTokenLoading] = useState(true)
    const { getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const token = await getAccessTokenSilently()
                setToken(token)
            } catch (error) {
                console.error('Error fetching token: ', error);
            } finally {
                setTokenLoading(false)
            }
        }
        fetchToken()
    }, [getAccessTokenSilently])
    
    

    // 
    const { papers, loading, error } = useFetchMSRAPapers(token, "MSRA");
    const { categoryNames, loading: catLoading, error: catError } = useFetchCPSCategories(token, "Clinical Problem Solving");
    const { subcategoryNames, loading: subcatLoading, error: subcatError } = useFetchCPSSubcategories(token, "Clinical Problem Solving");
    const { data, loading: paperLoading, error: paperError } = useFetchActiveSubPerformance(token, "MSRA")
    const {data: paperPercentileData, loading: paperPercentileLoading, error: paperPercentileError} = useFetchUserPercentile('?types=papers', token, "MSRA")
    const {data: histogramData, loading: histogramLoading, error: histogramError} = useFetchHistogramData(token, "MSRA")
    const {data: categoryPercentileData, loading: categoryPercentileLoading, error: categoryPercentileError} = useFetchUserPercentile('?types=categories', token, "MSRA")
    const {data: subcategoryPercentileData, loading: subcategoryPercentileLoading, error: subcategoryPercentileError} = useFetchUserPercentile('?types=subcategories', token, "MSRA")
    // 
    const allLoading = catLoading || subcatLoading || paperLoading || paperPercentileLoading || histogramLoading || categoryPercentileLoading || subcategoryPercentileLoading || tokenLoading || stateVariableLoading

    useEffect(() => {
        if(data[0] && data[0].papers){
          setCPSdata(data[0].papers.find(paper => paper.name === "Clinical Problem Solving"))
          setPDdata(data[0].papers.find(paper => paper.name === "Professional Dilemmas"))
          setCategoryData(data[0].categories)
          setSubcategoryData(data[0].subcategories)
          setStateVariableLoading(false)
        }
      }, [data])

    if (allLoading) return <SpinLoader />
  return (
    <div>
        <div className="mx-auto p-6 text-card-foreground text-center w-full sm:w-3/4 lg:w-2/3">
        <HeaderLarge text={'My Analytics'} blackText={true} />
        </div>
        {/* Navigation Tabs */}
        <div className="mx-auto px-4 w-full sm:w-3/4 lg:w-1/2">
        <Tabs defaultValue="paper" >
            <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger className="text-xs md:text-sm" value="paper" onClick={() => setSelectedTab('paper')}>Papers</TabsTrigger>
            <TabsTrigger className="text-xs md:text-sm" value="category" onClick={() => setSelectedTab('category')}>Categories</TabsTrigger>
            <TabsTrigger className="text-xs md:text-sm" value="subcategory" onClick={() => setSelectedTab('subcategory')}>Subcategories</TabsTrigger>
            </TabsList>
        </Tabs>
        </div>
        <div>
        {selectedTab === "paper" ? (
            <PaperPerformance 
            selectedPaper={selectedPaper}
            CPSdata={CPSdata}
            PDdata={PDdata}
            percentileData={paperPercentileData} 
            setSelectedPaper={setSelectedPaper}
            papers={papers}
            histogramData={histogramData.papers}
            />
        ) : selectedTab === "category" ? (
            <CategoryPerformance
            selectedCategory={selectedCategory}
            data={categoryData}
            percentileData={categoryPercentileData}
            setSelectedCategory={setSelectedCategory}
            categoryNames={categoryNames}
            histogramData={histogramData.categories}
            />
        ) : selectedTab === 'subcategory' ? (
            <SubcategoryPerformance
            selectedSubcategory={selectedSubcategory}
            data={subcategoryData}
            percentileData={subcategoryPercentileData}
            setSelectedSubcategory={setSelectedSubcategory}
            subcategoryNames={subcategoryNames}
            histogramData={histogramData.subcategories}
            />
        ) : ''}
        </div>
    </div>
  )
}

export default MSRADashboard