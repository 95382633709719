import React, { useState } from 'react'
import { Card, CardDescription, CardTitle, CardContent, CardHeader } from '../shadcn/ui/card'
import { BookOpen, LineChart, SquareCheckBig } from 'lucide-react'

function HomePageFeaturesSelect({selectedFeature, handleChangeSelectedFeature}) {

  return (
    <div className="flex flex-col md:flex-row lg:flex-row justify-center gap-6 w-full md:w-full lg:w-full xl:w-2/3 mx-auto px-5">
      <Card 
        className={`flex-1 ${selectedFeature === 0 ? 'dark:bg-black/80 bg-primary/5 border-black/20 dark:border-white/20' : 'dark:hover:bg-black/80 hover:bg-primary/5'} cursor-pointer`}
        onClick={() => handleChangeSelectedFeature(0)}
        >
          <CardHeader>
            <SquareCheckBig size={24} className='mx-auto mb-2' />
            <CardTitle className='text-center'>High Quality Questions</CardTitle>
          </CardHeader>
        <CardContent>
          <CardDescription>2000+ questions covering the Clinical Problem Solving and Professional Dilemmas papers. 
          </CardDescription>
        </CardContent>
      </Card>
      <Card 
        className={`flex-1 ${selectedFeature === 1 ? 'dark:bg-black/80 bg-primary/5 border-black/20 dark:border-white/20' : 'dark:hover:bg-black/80 hover:bg-primary/5'} cursor-pointer`}
        onClick={() => handleChangeSelectedFeature(1)}
      >
        <CardHeader>
          <LineChart size={24} className='mx-auto mb-2' />
          <CardTitle className='text-center'>Detailed Analytics</CardTitle>
        </CardHeader>
        <CardContent>
          <CardDescription>Performance summaries to help you track your progress and identify topics requiring improvement.</CardDescription>
        </CardContent>
      </Card>
      <Card 
        className={`flex-1 ${selectedFeature === 2 ? 'dark:bg-black/80 bg-primary/5 border-black/20 dark:border-white/20' : 'dark:hover:bg-black/80 hover:bg-primary/5'} cursor-pointer`}
        onClick={() => handleChangeSelectedFeature(2)}
        >
        <CardHeader>
          <BookOpen size={24} className='mx-auto mb-2' />
          <CardTitle className='text-center'>Evidence-Based Textbook</CardTitle>
        </CardHeader>
        <CardContent>
          <CardDescription>
            500+ Detailed textbook pages based on best practice guidelines (NICE, BTS, etc.). 
            </CardDescription>
        </CardContent>
      </Card>
    </div>
  )
}

export default HomePageFeaturesSelect