import React from 'react'
import HeaderLarge from '../typography/HeaderLarge'
import HeaderMedium from '../typography/HeaderLarge'
import { Button } from '../shadcn/ui/button'

function TextbookHomePage() {
  return (
    <div className='text-black dark:text-white'>
        <span className="text-center">
            <HeaderLarge text={'Welcome to our textbook resource'} blackText={true} />
        </span>
        <div className='my-6'>
            <HeaderMedium text={'Study by topic'}/>
            <p className="ml-2 mt-2">Use our navigation menu to see which textbook resources we have for each topic, or use our search bar to find articles with your key words</p>
        </div>
        <div className='my-6'>
            <HeaderMedium text={`Can't find what you're looking for?`}/>
            <p className="ml-2 mt-2">If there is material you feel we haven't covered, please <a href="mailto:contact@exam-vitals.com" className="font-semibold hover:underline">contact us</a> and we will do our best to add it to our resources! </p>
        </div>
    </div>
  )
}

export default TextbookHomePage