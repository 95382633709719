// SessionSummaryItem.js
import { Link } from 'react-router-dom';
import { Button } from '../shadcn/ui/button';
import useFormattedDate from '../hooks/useFormattedDate';
import { Separator } from '../shadcn/ui/separator';
import PieChart from '../visualisation/PieChart';

const SessionSummaryItem = ({ session, index, toggleItems, style, paperType}) => {
  const date = useFormattedDate(session.end_time);
  const id = session.session_id
  const totalQuestions = session.performance.questions_total;
  const viewed = session.performance.questions_viewed
  const attempted = session.performance.questions_attempted;
  const notViewed = session.performance.questions_total - attempted - viewed
  const percentAttempted = ((attempted / totalQuestions) * 100).toFixed(1);
  const scored = session.performance.points_scored;
  const totalPoints = session.performance.possible_points;
  const incorrect = totalPoints - scored
  const percentCorrect = ((scored / totalPoints) * 100).toFixed(1);
  const categories = session.categories;
  const subcategories = session.subcategories;
  const filter = session.filter === 'UNANSWERED' ? 'Unanswered Questions' : session.filter === 'ALL' ? 'All Questions' : session.filter === 'ANSWERED' ? 'Repeat Questions' : ''

  return (
    <div className={`w-11/12 lg:w-1/2 md:w-4/5 mb-4 p-6 bg-white border border-gray-200 shadow dark:bg-gray-800 dark:border-gray-700 rounded-lg overflow-visible`}>
      <div className="text-center">
        <h5 className="text-2xl mb-2 font-bold tracking-tight text-gray-900 dark:text-white">{date} Session</h5>
        {paperType === 'CPS' && (
          <>
            <p className='mb-2'>
              <span className='font-semibold text-gray-900 dark:text-white'>
                Categories 
              </span> 
              <br /> 
              <span className='text-muted-foreground'>
                {categories.map((cat, index) => {
                  return index === categories.length - 1 ? cat.category_name : cat.category_name + ', ';
                })}
              </span>
            </p>

            {subcategories.length > 0 && (
              <p className='mb-2'>
                <span className='font-semibold text-gray-900 dark:text-white'>
                  Subcategories 
                </span> 
                <br /> 
                <span className='text-muted-foreground'>
                  {subcategories.map((subcat, index) => {
                    return index === subcategories.length - 1 ? subcat.subcategory_name : subcat.subcategory_name + ', ';
                  })}
                </span>
              </p>
            )}
          </>
        )}
          <p className='mb-2'>
            <span className='font-semibold text-gray-900 dark:text-white'>
            Filter 
            </span> 
            <br /> 
            <span className='text-muted-foreground'>{filter}
            </span>
          </p>
      </div>
      <Separator className='my-4 dark:bg-gray-400' orientation='horizontal' />
      <div className='text-center'>
          {(paperType === 'PD' || paperType === 'PD Trial') &&  
            <p className="text-muted-foreground">
              Ranked List Questions are scored out of 20. <br />
              Multiple Choice Questions are scored out of 16. 
            </p>
          }
          <div className="flex flex-col lg:flex-row lg:items-baseline items-center justify-center">
            <div className="mt-4 hidden lg:inline" >
              <PieChart
                title="Coverage"
                scoreChart={false}
                dashboard={true}
                mobile={false}
                sessionSummary={true}
                data={[
                  {
                    color: 3,
                    label: `Not viewed`,
                    value: notViewed
                  },
                  {
                    color: 2,
                    label: `Skipped`,
                    value: viewed
                  },
                  {
                    color: 1,
                    label: `Attempted`,
                    value: attempted
                  },
                ]}
              />
            </div>
            <div className="mt-4 hidden md:inline lg:hidden">
              <PieChart
                title="Coverage"
                scoreChart={false}
                dashboard={true}
                mobile={false}
                sessionSummary={true}
                data={[
                  {
                    color: 3,
                    label: 'Not viewed',
                    value: notViewed
                  },
                  {
                    color: 2,
                    label: `Skipped`,
                    value: viewed
                  },
                  {
                    color: 1,
                    label: `Attempted`,
                    value: attempted
                  },
                ]}
              />
            </div>
            <div className="p-0 sm:hidden inline">
              <PieChart
                title="Coverage"
                scoreChart={false}
                dashboard={true}
                mobile={true}
                sessionSummary={true}
                data={[
                  {
                    color: 3,
                    label: `Not viewed`,
                    value: notViewed
                  },
                  {
                    color: 2,
                    label: `Skipped`,
                    value: viewed
                  },
                  {
                    color: 1,
                    label: `Attempted`,
                    value: attempted
                  },
                ]}
              />
            </div>
            <Separator className='lg:hidden inline dark:bg-gray-600 my-2' orientation='horizontal' />
              <div className="mt-4 hidden lg:inline" >
                <PieChart
                  title="Score"
                  scoreChart={true}
                  dashboard={true}
                  mobile={false}
                  sessionSummary={true}
                  data={[
                    {
                      color: 2,
                      label: `Incorrect`,
                      value: incorrect
                    },
                    {
                      color: 1,
                      label: `Correct`,
                      value: scored
                    },
                  ]}
                />
              </div>
              <div className="mt-4 hidden md:inline lg:hidden">
                <PieChart
                  title="Score"
                  scoreChart={true}
                  dashboard={true}
                  mobile={false}
                  sessionSummary={true}
                  data={[
                    {
                      color: 2,
                      label: `Incorrect`,
                      value: incorrect
                    },
                    {
                      color: 1,
                      label: `Correct`,
                      value: scored
                    },
                  ]}
                />
              </div>
              <div className="p-0 sm:hidden inline">
                <PieChart
                  title="Score"
                  scoreChart={true}
                  dashboard={true}
                  mobile={true}
                  sessionSummary={true}
                  data={[
                    {
                      color: 2,
                      label: `Incorrect`,
                      value: incorrect
                    },
                    {
                      color: 1,
                      label: `Correct`,
                      value: scored
                    },
                  ]}
                />
              </div>
          </div>
      </div>
      <Separator className='my-4 dark:bg-gray-400' orientation='horizontal' />
      <div className="flex flex-col items-center gap-4 lg:flex-row lg:justify-center lg:gap-20">
        <Button variant={'outline'} asChild>
            {paperType === 'CPS' ? (
            <Link to={`/MSRA/CPS/start-session`}>
              Start new session
            </Link>
            ) : paperType === 'CPS Trial' ? 
            (
              <Link to={`/trial-MSRA/CPS/start-session`}>
                Start new session
              </Link>
            ) : paperType === 'PD' ? 
            (
            <Link to={`/MSRA/PD/start-session`}>
              Start new session
            </Link>
            ) : paperType === 'PD Trial' ? 
            (
            <Link to={`/trial-MSRA/PD/start-session`}>
              Start new session
            </Link>
            ) : null}
        </Button>
        <Button asChild>
            {paperType === 'CPS' ? (
            <Link to={`/MSRA/session-review/CPS/${id}`}>
              Review answers
              <svg className="ml-2 w-3.5 h-3.5" aria-hidden="true" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </Link>
            ) : paperType === 'CPS Trial' ? 
            (
            <Link to={`/trial-MSRA/session-review/CPS/${id}`}>
              Review answers
              <svg className="ml-2 w-3.5 h-3.5" aria-hidden="true" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </Link>
            ) : paperType === 'PD' ?
            (
            <Link to={`/MSRA/session-review/PD/${id}`}>
              Review answers
              <svg className="ml-2 w-3.5 h-3.5" aria-hidden="true" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </Link>
            ) : paperType === 'PD Trial' ?
            (
              <Link to={`/trial-MSRA/session-review/PD/${id}`}>
                Review answers
                <svg className="ml-2 w-3.5 h-3.5" aria-hidden="true" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
              ) : null}
        </Button>
      </div>
    </div>
  );
};

export default SessionSummaryItem;
