import React, { useState } from 'react';
import {
    Dialog,
    DialogTrigger,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription
} from '../shadcn/ui/dialog';
import { Button } from '../shadcn/ui/button';
import { useIncrementSubscription } from '../hooks/MSRA/helper_functions/incrementSubscription';
import CheckMarkAnimated from '../other_ui/CheckMarkAnimated';

function MSRAResetDialog({ token, csrfToken }) {
    const [incrementSuccess, setIncrementSuccess] = useState(false);
    const [incrementError, setIncrementError] = useState(null);
    const [incrementLoading, setIncrementLoading] = useState(false);

    // Call the hook at the top level of the component
    useIncrementSubscription(
        setIncrementSuccess,
        incrementSuccess,
        setIncrementError,
        incrementError,
        setIncrementLoading,
        incrementLoading,
        token,
        csrfToken
    );

    const handleResetData = () => {
        // Trigger logic to reset (state updates will happen in the hook)
        setIncrementLoading(true);
    };

    return (
        <Dialog>
            <DialogTrigger className="flex justify-center items-center w-full sm:w-auto mx-auto my-8">
                <Button variant={'destructive'} className="w-full md:w-auto">
                    Reset Question Bank Data
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    {!incrementSuccess && (
                        <>
                            <DialogTitle>Reset Question Bank Data</DialogTitle>
                            <DialogDescription>
                                By resetting your question bank data, all your progress for both Clinical Problem Solving and Professional Dilemmas papers will be reset.
                            </DialogDescription>
                            <DialogDescription>
                                This can be helpful when you have completed the question bank and want to repeat questions and see how your performance has changed.
                            </DialogDescription>
                        </>
                    )}
                </DialogHeader>
                    {!incrementSuccess && 
                        <p>
                            Are you sure you want to proceed? You cannot reverse this.
                        </p>
                    }
                {incrementError && (
                    <p className="text-red-500 mt-2">
                        {incrementError.message || 'An error occurred while resetting data.'}
                    </p>
                )}
                {!incrementSuccess ? 
                    <Button
                        variant={'destructive'}
                        onClick={handleResetData}
                        disabled={incrementLoading}
                    >
                        {incrementLoading ? 'Resetting...' : 'Reset Data'}
                    </Button>
                : 
                <>
                    <CheckMarkAnimated 
                        page={"Reset Question Bank"}
                    />
                </>
                
                }
            </DialogContent>
        </Dialog>
    );
}

export default MSRAResetDialog;
