import React from 'react';
import { Button } from '../shadcn/ui/button';
import { useAuth0 } from "@auth0/auth0-react";


function LogoutButton() {
  const { logout } = useAuth0()
  return (
    <Button size="sm" type="button" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
      Logout
    </Button>
  );
}

export default LogoutButton;
