import { useState, useEffect } from 'react';
import { getCsrfToken } from '../../../csrfTokenHelper';

export const useFetchUserPercentile = (suffix, token, exam_name) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!token) return
        const fetchUserPercentile = async () => {
            try {
                const csrfToken = getCsrfToken();

                let response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/MSRA/overall-stats/simplified-user-percentile/${suffix}&exam_name=${encodeURIComponent(exam_name)}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "X-CSRFToken": csrfToken
                    }
                });



                if (!response.ok) throw new Error('Failed to fetch percentile score');

                const data = await response.json();
                setData(data);
            } catch (error) {
                console.error('Error fetching percentile score:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserPercentile();
    }, [token]);

    return { data, loading, error };
};