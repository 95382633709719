import { useFetchTextbookByExam } from '../../../../components/hooks/MSRA/fetches/textbook/FetchTextbookByExam'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CustomLoader from '../../../../components/loader/CustomLoader'
import TextbookSideBar from '../../../../components/textbook/TextbookSideBar'
import TextbookDisplay from '../../../../components/textbook/TextbookDisplay'
import { useFetchSingleTextbookItem } from '../../../../components/hooks/MSRA/fetches/textbook/FetchSingleTextbook'
import TextbookMobileSearch from '../../../../components/textbook/TextbookMobileSearch'
import { useAuth0 } from '@auth0/auth0-react'
import SpinLoader from '../../../../components/loader/SpinLoader'


function MSRATrialTextbook() {
    const navigate = useNavigate()
    const { id } = useParams()
    const [searchTerm, setSearchTerm] = useState('');
    const { getAccessTokenSilently, isLoading } = useAuth0();
    const [token, setToken] = useState(null);
    const [tokenLoading, setTokenLoading] = useState(true);
    const [sheetIsOpen, setSheetIsOpen] = useState(false)

    useEffect(() => {
        const fetchToken = async () => {
          try {
            const token = await getAccessTokenSilently();
            setToken(token);
          } catch (error) {
            console.error('Error fetching token:', error);
          } finally {
            setTokenLoading(false);
          }
        };
    
        fetchToken();
      }, [getAccessTokenSilently]);
    

    const {textbookItems: textbookList, loading } = useFetchTextbookByExam(5, token)
    const {textbookItem, loading: singleLoad } = useFetchSingleTextbookItem(id, token)

    const allLoading = singleLoad || loading || tokenLoading || isLoading

    const sheetNavigate = (destination) => {
      navigate(destination);
      setSheetIsOpen(false)
    }

    if(allLoading) return <SpinLoader />

    return (
    <>
        <div className="flex md:flex-row flex-col">
            <TextbookSideBar
                textbookItems={textbookList}
                navigate={navigate}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                exam={"MSRA Free Trial"}
                />
            <TextbookMobileSearch
                textbookItems={textbookList}
                navigate={navigate}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                exam={"MSRA Free Trial"}
                sheetIsOpen={sheetIsOpen}
                sheetNavigate={sheetNavigate}
            />
            <TextbookDisplay
                textbookItem={textbookItem}
                param={id}
                loading={allLoading}
            />
        </div>
    </>
  )
}

export default MSRATrialTextbook