import React from 'react';
import { useDeepCompareEffect } from './deep_compare/deepCompare';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../shadcn/ui/select';
import { Separator } from '../../../shadcn/ui/separator';
import { Check, X } from 'lucide-react';
import { HoverCard, HoverCardContent, HoverCardTrigger, } from '../../../shadcn/ui/hover-card';
import { 
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    } from '../../../shadcn/ui/dialog';
import { 
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
    } from '../../../shadcn/ui/table';
import { Progress } from '../../../shadcn/ui/progress';
import { Button } from '../../../shadcn/ui/button';


export const EMQDisplay = ({ questions, isAnswered, onUserAnswerChange, data, allQuestionAttempts, localUserAnswer, questionAttemptsStats, reviewState }) => {
    const sessionId = data.id;
    const currentQuestionAttempt = allQuestionAttempts[questions.id];

    // Build a mapping of scenario IDs to user answers using the order of scenarios
    const userAnswers = questions.scenarios.reduce((acc, scenario, index) => {
        // Check if an answer exists for this scenario
        if (currentQuestionAttempt && index < currentQuestionAttempt.user_answer.length) {
            acc[scenario.id] = currentQuestionAttempt.user_answer[index];
        }
        return acc;
    }, {});

    const handleSelectChange = (scenarioId, selectedOptionIndex) => {
        // Parse the selectedOptionIndex to ensure it's a valid number
        const index = parseInt(selectedOptionIndex, 10);
        if (!Number.isNaN(index)) {
            onUserAnswerChange({
                scenarioId,
                selectedOptionIndex: index  // Only send the index
            });
        } else {
            console.error('Invalid index:', selectedOptionIndex);
        }
    };
        

    const checkIfCorrect = (scenarioId, index) => {
        const correctIndex = questions.emq_answer[index];
        if(localUserAnswer[questions.id]) return localUserAnswer[questions.id][scenarioId] === correctIndex
        return userAnswers[scenarioId] === correctIndex;
    };

    return (
        <div className="flex flex-col">
            {reviewState && !isAnswered && (
            <div className="font-semibold italic text-gray-600 text-center underline my-4 dark:text-gray-300">
                You did not answer this question.
            </div>
            )}
            <div className="p-4 mb-4 border-b border-gray-200" dangerouslySetInnerHTML={{ __html: questions.content }} />
            {/* <div className="text-sm italic text-gray-600 mb-4 dark:text-gray-300">
                Please select the most appropriate option for each scenario. The same option may be selected for multiple scenarios.
            </div> */}
            {questions && questions.options && questions.scenarios.map((scenario, index) => {
                const userAnswerIndex = userAnswers[scenario.id];
                const isCorrect = isAnswered && checkIfCorrect(scenario.id, index);
                const answerColor = isAnswered 
                    ? (isCorrect ? 'bg-green-200 dark:bg-green-800 ' : 'bg-red-200 dark:bg-red-800') 
                    : '';
                const optionsStats
                 = questionAttemptsStats.questionId[questions.id].scenario[index].option

                return (
                    <div key={scenario.id} className="mb-4">
                        <div className="font-semibold mb-1">Scenario {index + 1}:</div>
                        <div dangerouslySetInnerHTML={{ __html: scenario.content }} className="mb-2" />
                        <div className="md:flex md:justify-between md:items-center">
                            <Select
                                key={userAnswerIndex || 'not-loaded'}
                                disabled={isAnswered || reviewState}
                                defaultValue={userAnswerIndex !== undefined ? String(userAnswerIndex) : undefined}
                                onValueChange={(value) => handleSelectChange(scenario.id, value)}
                            >
                                <SelectTrigger className={`w-[180px] ${answerColor}`}>
                                    <SelectValue placeholder="Select Answer..." />
                                </SelectTrigger>
                                <SelectContent>
                                    {questions.options.map((option, optionIndex) => (
                                        <SelectItem
                                            key={option.id}
                                            value={String(optionIndex)}
                                            className={`${isAnswered && optionIndex === userAnswerIndex ? answerColor : ''}`}
                                        >
                                            {option.content}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                            {isAnswered && <Dialog>
                                <DialogTrigger asChild>
                                    <Button className='text-muted-foreground' variant={'link'}>Click to see how others have answered</Button>
                                </DialogTrigger>
                                <DialogContent>
                                    <DialogTitle>Scenario {index+1}</DialogTitle>
                                    <Table>
                                        <TableHeader>
                                            <TableRow>
                                                <TableHead>Answer</TableHead>
                                                <TableHead className="text-right">Percent of users</TableHead>
                                            </TableRow>
                                        </TableHeader>
                                        {Object.entries(optionsStats).map(([optionIndex, stats]) => {
                                            const option = questions.options[optionIndex];
                                            return option ? (
                                                <TableRow key={optionIndex}>
                                                          <TableCell className={`font-medium ${optionIndex == questions.emq_answer[index] ? 'text-green-500 underline' : ''}`} style={{ width: '50%'}}>
                                                            {option.content} {optionIndex == userAnswerIndex ? '(you)' : null}
                                                          </TableCell>
                                                          <TableCell className='text-right'  style={{ width: '50%'}}>
                                                            <Progress style={{ transform: 'scaleX(-1)'}} value={stats.percentage}/>
                                                            <span>{stats.percentage}%</span>
                                                            </TableCell>
                                                </TableRow>
                                            ) : null;  // Return null or a placeholder if the option does not exist
                                        })}
                                    </Table>
                                </DialogContent>
                            </Dialog>}
                        </div>
                        {!isCorrect && isAnswered && (
                            <div>
                            <div className="italic text-gray-600 mt-2 md:mt-4">
                            <strong>Correct Answer:</strong>
                            </div> 
                        {questions.options[questions.emq_answer[index]].content}</div>
                        )}
                        <Separator className='mt-4' orientation='horizontal' />
                    </div>
                );
            })}
            {isAnswered ? (
                <>
                <div className="underline italic text-gray-600 dark:text-gray-300">
                        Explanation:
                    </div>
                <div className="mb-4" dangerouslySetInnerHTML={{ __html: questions.explanation }} />
                {/* {questions.textbook_page ? (
                    <>Handle textbook page relation</>
                ) : null
                } */}
                </>
            ) : null
            }
        </div>
    );
};
