import React from 'react';
import { Switch } from '../shadcn/ui/switch';

const AllTopicsToggle = ({ areAllChecked, handleAllQuestionsChange }) => {
    return (
        <div className="m-4">
            <label className="flex space-x-3 items-center cursor-pointer">
                <span className={`${areAllChecked && "text-black dark:text-white"} hidden sm:inline text-lg font-bold`}>Toggle all</span>
                <Switch
                    checked={areAllChecked}
                    onCheckedChange={(e) => handleAllQuestionsChange(e)}
                />
            </label>
        </div>
    );
}

export default AllTopicsToggle;
