import { Separator } from '../../shadcn/ui/separator';
import React, {useEffect, useState} from 'react';
import { Check } from 'lucide-react';
import { Pagination, PaginationContent, PaginationItem, PaginationLink, PaginationPrevious, PaginationNext, PaginationEllipsis } from '../../shadcn/ui/pagination';


function FormattedQuestionList({ expandedQuestionOrder, questionStates, questionAttempts, navigateToQuestion, localUserAnswer, allQuestions, currentQuestionIndex, totalPoints, overallScore, manualScore}) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;  
    
    const getFormattedList = () => {
        const formattedList = [];
        let lastIndex = 0;
        
        expandedQuestionOrder.forEach((questionId, index) => {
            if (expandedQuestionOrder.lastIndexOf(questionId) === index || index === expandedQuestionOrder.length - 1) {
                const questionState = questionStates.find(qs => qs.question_details.id === questionId);
                if (questionState && questionState.viewed) {
                    let answerIndicator = "-"; // Default indicator
                    
                    // Check if the question has been answered
                    if (questionState.answered) {
                        const formattedLocalUserAnswer = localUserAnswer[questionId];
                        const emqFormattedLocalUserAnswer = questionState.question_details.question_type === 2 && formattedLocalUserAnswer && typeof formattedLocalUserAnswer === 'object'
                        ? Object.values(formattedLocalUserAnswer)
                        : null;
                        
                        const fetchedAttempt = questionAttempts[questionId];
                        const localAttemptAnswer = allQuestions.find(question => question.id === questionId)

                        if(formattedLocalUserAnswer || formattedLocalUserAnswer === 0) {
                            switch(questionState.question_details.question_type){
                                // MCQ
                                case 1:
                                    totalPoints += 12
                                    overallScore += manualScore[questionId]
                                    answerIndicator = 
                                    <div className={`font-bold 
                                            ${manualScore[questionId] >= 9 ? "text-green-600 dark:text-green-500" :
                                            manualScore[questionId] >= 3 ? "text-orange-600 dark:text-orange-500" : "text-red-600 dark:text-red-600"}
                                    `}
                                    >{manualScore[questionId]} / 12</div>
                                    break
                                // EMQ
                                case 2:
                                    let pointCount = 0
                                    for (let i = 0; i < emqFormattedLocalUserAnswer.length; i++){
                                        if(emqFormattedLocalUserAnswer[i] === localAttemptAnswer.emq_answer[i]){
                                            pointCount++
                                            overallScore++
                                        }
                                        totalPoints++
                                    }
                                    answerIndicator = <div className={`font-bold 
                                            ${pointCount >= 3 ? "text-green-600 dark:text-green-500" :
                                            pointCount >= 1 ? "text-orange-600 dark:text-orange-500" : "text-red-600 dark:text-red-600"}
                                    `}
                                    >{pointCount} / {localAttemptAnswer.emq_answer.length}</div>
                                    break
                                // SBA
                                case 3: 
                                    if(formattedLocalUserAnswer === localAttemptAnswer.sba_answer) {
                                        overallScore++
                                    }
                                    totalPoints++
                                    answerIndicator = formattedLocalUserAnswer === localAttemptAnswer.sba_answer ? 
                                    <div className="text-green-600 dark:text-green-500 font-bold text-xl">✓</div> 
                                    : <div className="text-red-600 font-bold text-xl">x</div>;
                                    break
                                // RL
                                case 4:
                                    totalPoints += 20
                                    overallScore += manualScore[questionId]
                                    answerIndicator = 
                                    <div className={`font-bold 
                                            ${manualScore[questionId] >= 17 ? "text-green-600 dark:text-green-500" :
                                            manualScore[questionId] >= 11 ? "text-orange-600 dark:text-orange-500" : "text-red-600 dark:text-red-600"}
                                    `}
                                    >{manualScore[questionId]} / 20</div>
                                    break
                                default: 
                                    break
                            }
                        } else {
                            switch(questionState.question_details.question_type){
                                // MCQ
                                case 1: 
                                    if(fetchedAttempt){
                                        answerIndicator = (
                                            <div className={`font-bold
                                                ${fetchedAttempt.points_scored >=9 ? "text-green-600 dark:text-green-500" :
                                                fetchedAttempt.points_scored >=3 ? "text-orange-600 dark:text-orange-500" : "text-red-600 dark:text-red-600"}
                                            `}>
                                                {fetchedAttempt.points_scored} / {fetchedAttempt.total_points}
                                            </div>
                                        )
                                    }
                                    break
                                // EMQ
                                case 2:
                                    if(fetchedAttempt){
                                        answerIndicator = (
                                            <div className={`font-bold
                                              ${fetchedAttempt.points_scored >= 3 ? "text-green-600 dark:text-green-500" :
                                                fetchedAttempt.points_scored >= 1 ? "text-orange-600 dark:text-orange-500" : "text-red-600 dark:text-red-600"}
                                            `}>
                                              {fetchedAttempt.points_scored} / {fetchedAttempt.total_points}
                                            </div>
                                          )
                                    } else {
                                        answerIndicator = 'marking...'
                                    }
                                    break
                                // SBA
                                case 3:
                                    if(fetchedAttempt){
                                        answerIndicator = fetchedAttempt.points_scored === 1 ? 
                                        <div className="text-green-600 dark:text-green-500 font-bold text-xl">✓</div> 
                                        : <div className="text-red-600 font-bold text-xl">x</div>;
                                    } else{
                                        answerIndicator = 'marking...'
                                    }
                                    break
                                // RL
                                case 4:
                                    if(fetchedAttempt){
                                        answerIndicator = (
                                        <div className={`font-bold
                                              ${fetchedAttempt.points_scored >= 17 ? "text-green-600 dark:text-green-500" :
                                                fetchedAttempt.points_scored >= 11 ? "text-orange-600 dark:text-orange-500" : "text-red-600 dark:text-red-600"}
                                            `}>
                                              {fetchedAttempt.points_scored} / {fetchedAttempt.total_points}
                                        </div>
                                        )
                                    } else {
                                        answerIndicator = 'marking...'
                                    }
                                    break
                                default: 
                                    answerIndicator = "marking...";
                                    break
                            }
                        }
                    }

                    const text = (questionState.question_details.question_type === 3 || questionState.question_details.question_type === 1 || questionState.question_details.question_type === 4)
                    ? <>
                        <div className="grid grid-cols-2">
                            <div className="font-bold text-right text-lg mr-2 md:mr-4">{index + 1}:</div>
                            <div >{answerIndicator}</div>
                        </div>
                    </>
                    : <>
                        <div className="grid grid-cols-2">
                            <div className="font-bold text-right text-lg mr-2 md:mr-4">{lastIndex + 1}-{index + 1}:</div>
                            <div>{answerIndicator}</div>
                        </div>
                    </>;
                    
                    formattedList.push({ text, viewed: true });
                    lastIndex = index + 1;
                }
            }
        });

        return formattedList;
    };

    const formattedList = getFormattedList();
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = formattedList.slice(indexOfFirstItem, indexOfLastItem);


    // Handler functions for pagination
    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    // Determine the total number of pages
    const totalQuestions = formattedList.length;
    const totalPages = Math.ceil(totalQuestions / itemsPerPage);

    function getPageNumbers(currentPage, totalPages) {
        const pageWindow = 1;  // Shows one page before and after the current page
        let pages = [];
    
        // Determine the start and end indices of the pagination window
        const startPage = Math.max(1, currentPage - pageWindow);
        const endPage = Math.min(totalPages, currentPage + pageWindow);
    
        // Handle the addition of ellipsis for skipped pages at the beginning
        if (startPage > 1) {
            pages.push(1);
            if (startPage > 2) {
                pages.push('...');
            }
        }
    
        // Add the current window of pages
        for (let page = startPage; page <= endPage; page++) {
            pages.push(page);
        }
    
        // Handle the addition of ellipsis for skipped pages at the end
        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push('...');
            }
            pages.push(totalPages);
        }
    
        return pages;
    }
    
    useEffect(() => {
        const newPage = Math.ceil((currentQuestionIndex + 1) / itemsPerPage);
        setCurrentPage(newPage);
    }, [currentQuestionIndex, itemsPerPage]);

    useEffect(() => {
        setCurrentPage(totalPages)
    }, [totalPages])
    return (
        <>
            <h5 className='text-xl pb-2 text-center font-bold'>
                {totalPoints === 0 ? (
                    <>Score: -</>
                ) : (
                <>
                Score: {overallScore} / {totalPoints} ({((overallScore / totalPoints)*100).toFixed(1)}%)
                </>
                )}
            </h5>
            <Separator orientation='horizontal' />
            <ul className="list-none pl-0">
                {currentItems.filter(item => item.viewed).map((formattedQuestion, index) => (
                    <li key={index} className="cursor-pointer p-4 m-1 hover:bg-gray-100 dark:hover:bg-gray-800 rounded transition-colors" onClick={() => {
                        const questionNumber = indexOfFirstItem + index; // Adjust index to match the full list
                        navigateToQuestion(questionNumber);
                    }}>
                        {formattedQuestion.text}
                    </li>
                ))}
            </ul>
            <Separator className="mb-2 md:mb-4" orientation='horizontal' />
            {totalPages >1 && (<Pagination className='mb-2 md:mb-4'>
                <PaginationContent>
                    {currentPage > 1 && (
                        <PaginationItem>
                            <PaginationPrevious href="#" onClick={handlePreviousPage} />
                        </PaginationItem>
                    )}
                    {getPageNumbers(currentPage, totalPages).map((page, index) => (
                        <PaginationItem key={index}>
                            {page === '...' ? (
                                <span className="px-2">...</span>
                            ) : (
                                <PaginationLink href="#" className={currentPage === page ? 'text-blue-500' : ''} onClick={(e) => {
                                    e.preventDefault();
                                    setCurrentPage(page);
                                }}>
                                    {page}
                                </PaginationLink>
                            )}
                        </PaginationItem>
                    ))}
                    {currentPage < totalPages && (
                        <PaginationItem>
                            <PaginationNext href="#" onClick={handleNextPage} />
                        </PaginationItem>
                    )}
                </PaginationContent>
            </Pagination>)}


        </>
    );
}

export default FormattedQuestionList;
