import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from 'react-query';


const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();


root.render(
  <React.StrictMode>
    <Auth0Provider
        domain="dev-l7zvad7ntj6acy3t.uk.auth0.com"
        clientId="luMvNOI7P6uVQisymwJATrXRlFSNsVQO"
        scope='openid profile email'
        cacheLocation='localstorage'
        useRefreshTokens={true}
        authorizationParams={{
          redirect_uri: window.location.origin + '/profile',
          audience: "https://dev-l7zvad7ntj6acy3t.uk.auth0.com/api/v2/",
          scope: "openid profile"
        }}
      >
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Auth0Provider>
  </React.StrictMode>
);

