import React from 'react'
import { Card, CardHeader, CardDescription, CardTitle, CardContent, CardFooter } from '../shadcn/ui/card'
import { Label } from '../shadcn/ui/label'
import { Input } from '../shadcn/ui/input'
import { Button } from '../shadcn/ui/button'
import { AlertCircle } from 'lucide-react'
import CheckMarkAnimated from '../other_ui/CheckMarkAnimated'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../shadcn/ui/select'

function UserDetailsForm({ 
    email, 
    setEmail, 
    emailConfirm, 
    setEmailConfirm, 
    firstName, 
    setFirstName, 
    lastName, 
    setLastName, 
    referralMethod,
    setReferralMethod,
    formSuccess, 
    setFormSuccess, 
    formError, 
    setFormError, 
    token,
    csrfToken,
    setSkipProfileContent
    }) {
    
    const handleSkip = () => {
        setSkipProfileContent(true)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Step 1: Check if email and emailConfirm are the same
        if (email !== emailConfirm) {
        setFormError(`Emails don't match.`);
        return;
        }

        // Clear any existing error
        setFormError(null);

        // Step 2: Prepare the data and headers
        const data = {
        email,
        first_name: firstName,
        last_name: lastName,
        referral_method: referralMethod,
        };

        const headers = {
            Authorization: `Bearer ${token}`, 
            'X-CSRFToken': csrfToken, 
            'Content-Type': 'application/json',
        };

        try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/users/update-user-data/`, {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            // If the response is not okay, handle the error
            const errorData = await response.json();
            setFormError(errorData.error || 'An error occurred while updating your details.');
            return;
        }

            // If the response is successful, handle success
            setFormSuccess(true);
        } catch (error) {
            // Step 5: Handle network or other errors
            setFormError('An unknown error occurred. Please try again.');
        }
    };

    return (
        <div>
            <Card className="w-full max-w-md mx-auto">
                {!formSuccess ?
                    (
                        <>
                        <CardHeader>
                            <CardTitle className="text-2xl font-bold text-center">Contact Details</CardTitle>
                            <CardDescription>We are gathering this information to more easily understand which user is linked with which account. We will only use your contact details for vital information regarding Exam Vitals.</CardDescription>
                        </CardHeader>
                        <form onSubmit={handleSubmit}>
                            <CardContent className="space-y-4">
                                
                                        <div className="space-y-2">
                                            <Label htmlFor="email">Email <br /><span className='text-xs font-normal'>This will be how we contact you. It can be the same email you used to login, or a different one.</span></Label>
                                            <Input 
                                            id="email" 
                                            type="email" 
                                            placeholder="Enter your email" 
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="emailConfirm">Confirm Email</Label>
                                            <Input 
                                            id="emailConfirm" 
                                            type="email" 
                                            placeholder="Confirm your email" 
                                            value={emailConfirm}
                                            onChange={(e) => setEmailConfirm(e.target.value)}
                                            required
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="firstName">First Name</Label>
                                            <Input 
                                            id="firstName" 
                                            placeholder="Enter your first name" 
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            required
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="lastName">Last Name</Label>
                                            <Input 
                                            id="lastName" 
                                            placeholder="Enter your last name" 
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            required
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="referralMethod">How did you hear about us?</Label>
                                            <Select onValueChange={(e) => setReferralMethod(e)}>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select an option" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectGroup>
                                                        <SelectItem value="LinkedIn">LinkedIn</SelectItem>
                                                        <SelectItem value="Instagram">Instagram</SelectItem>
                                                        <SelectItem value="Reddit">Reddit</SelectItem>
                                                        <SelectItem value="Google">Google</SelectItem>
                                                        <SelectItem value="Other">Other</SelectItem>
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                        </div>
                            </CardContent>
                            <CardFooter>
                                <div className="flex flex-col gap-2 w-full">
                                    <Button type="submit">Submit</Button>
                                    <Button type="button" variant='outline' onClick={() => handleSkip()}>Skip for now</Button>
                                </div>
                            </CardFooter>
                                {formError && (
                                    <p className="mx-6 mb-4 text-sm text-red-500 flex items-center gap-1">
                                        <AlertCircle className="h-4 w-4" />
                                        {formError}
                                    </p>
                                )}
                        </form>
                    </>
                ) : (
                    <CheckMarkAnimated page={"Contact Details"} />
                )}
            </Card>
        </div>
  )
}

export default UserDetailsForm