import React from 'react';
import HeaderMedium from '../typography/HeaderMedium';

function HomePageTextbookDisplay() {
  const topics = [
    'Professional Dilemmas',
    'Psychiatry',
    'Pharmacology',
    'Paediatrics',
    'Ophthalmology',
    'Obstetrics and Gynaecology',
    'Musculoskeletal',
    'Ear, Nose, and Throat',
    'Dermatology',
    'Surgery',
    'Infectious Diseases',
    'Rheumatology',
    'Urology',
    'Endocrinology',
    'Respiratory',
    'Renal',
    'Oncology',
    'Neurology',
    'Haematology',
    'Elderly Care',
    'Gastroenterology',
    'Cardiology'
  ];

  // Sort the topics alphabetically
  const sortedTopics = topics.sort();

  return (
    <>
      <div className="text-center my-8">
        <HeaderMedium
          text={`500+ textbook items on the following topics:`}
          blackText={true}
          underline={true}
        />
      </div>
      <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 my-8 gap-1 text-left max-w-4xl mx-auto">
        {sortedTopics.map((topic, index) => {
          return <li key={index} className="ml-4 md:text-lg">•&nbsp;&nbsp;{topic}</li>;
        })}
      </ul>
    </>
  );
}

export default HomePageTextbookDisplay;
