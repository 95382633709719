/**
 * v0 by Vercel.
 * @see https://v0.dev/t/JeGlyVw
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { useState } from "react";
import { Button } from "../shadcn/ui/button"
import useGrantSubscription from "../hooks/subscriptions/useGrantSubscription";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export default function PricingOptions() {
  // currently hardcoded 
  const [examId, setExamId] = useState(1);
  const [subDuration, setSubDuration] = useState(3)
  const { grantSubscription } = useGrantSubscription();
  const navigate = useNavigate()
  const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0();

  const handleSubmit = (e) => {
    e.preventDefault();
    grantSubscription(examId, subDuration);
  }

  return (
    <>
      <h1 className="text-xl mb-6 text-center sm:mb-10 font-bold tracking-tighter sm:text-3xl xl:text-4xl/none">
          Subscription Options
      </h1>
      <section className="mx-auto pb-12 flex items-center justify-center">
        <div className="container px-4 md:px-6">
          <div className="relative flex flex-col p-6 shadow-lg rounded-lg dark:bg-black mx-auto text-black dark:text-white border-4 border-purple-500 
              w-full 
              max-w-[95%] sm:max-w-[75%] lg:max-w-[40%]">
            <div className="text-center px-3 py-1 text-sm text-white bg-gradient-to-r from-pink-500 to-purple-500 rounded-full inline-block absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              January/February 2025 sitting
            </div>
            <div>
              <h3 className="text-2xl mt-4 font-bold text-center">Access until March 1st, 2025</h3>
              <div className="mt-4 text-center">
                <div className="mt-2">
                  {/* <p className="text-md">Regular price:</p> */}
                  <p className="text-3xl font-bold">£35</p>
                </div>
                {/* <div>
                  <p className="text-lg">Price for first 100 users using code "FIRST100" at checkout:</p>
                  <p className="text-4xl font-bold">£15</p>
                </div> */}
              </div>
              <ul className="my-6 space-y-2">
                <li className="flex items-center">
                  <span className="bg-green-500 rounded-full mr-2 p-1">
                    <CheckIcon className="text-white text-xs" />
                  </span>
                  2000+ Clinical Problem Solving Questions
                </li>
                <li className="flex items-center">
                  <span className="bg-green-500 rounded-full mr-2 p-1">
                    <CheckIcon className="text-white text-xs" />
                  </span>
                  200+ Professional Dilemma Questions
                </li>
                <li className="flex items-center">
                  <span className="bg-green-500 rounded-full mr-2 p-1">
                    <CheckIcon className="text-white text-xs" />
                  </span>
                  500+ Online Textbook Pages
                </li>
                <li className="flex items-center">
                  <span className="bg-green-500 rounded-full mr-2 p-1">
                    <CheckIcon className="text-white text-xs" />
                  </span>
                  User Analytics Dashboard
                </li>
                <li className="flex items-center">
                  <span className="bg-green-500 rounded-full mr-2 p-1">
                    <CheckIcon className="text-white text-xs" />
                  </span>
                  Option to reset progress and start from scratch
                </li>
              </ul>
            </div>
            {isAuthenticated ? (
              <>
                <div className="mt-6">
                  <form onSubmit={handleSubmit}>
                    <Button type="submit" className="w-full">
                      Buy now*
                    </Button>
                  </form>
                </div>
                <p className="mt-2 text-sm text-muted-foreground">*By clicking, you will be redirected to our payment provider, Stripe.</p>
              </>
            ) : (
              <div className="mt-6">
                <Button 
                  className="w-full"
                  onClick={() => loginWithRedirect()}
                  >
                  Log in / Sign up to Purchase 
                </Button>
              </div>
            )
            }
          </div>
        </div>
      </section>

    </>
  )
}

function CheckIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20 6 9 17l-5-5" />
    </svg>
  )
}

