import React, { useState } from 'react';
import HeaderMedium from '../typography/HeaderMedium';
import { Button } from '../shadcn/ui/button';
import AllTopicsToggle from './AllTopicsToggle';
import { Checkbox } from '../shadcn/ui/checkbox';
import { Label } from '../shadcn/ui/label';
import { ChevronDown, ChevronUp } from 'lucide-react';

const CategoryFilter = ({ categoryNames, subcategoryNames, checkedItems, handleCheckChange, areAllChecked, handleAllQuestionsChange, findMatchingCategory, findMatchingSubcategory }) => {
    const [toggleSubs, setToggleSubs] = useState(false)

    return (
        <div>
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-semibold">Topics</h2>
              <AllTopicsToggle
                  areAllChecked={areAllChecked}
                  handleAllQuestionsChange={handleAllQuestionsChange}
              />
            </div>
            <div className="grid mx-auto gap-3">
              {categoryNames.map((category) => {
                const matchingCategory = findMatchingCategory(category.name);

                if (category.name === 'General Medicine' || category.name === 'General Medicine Free Trial') {
                  return (
                    <>
                      <div
                        key={category.id} 
                        onClick={() => handleCheckChange(category.id, !checkedItems[`category-${category.id}`], true)} 
                        className="flex hover:bg-gray-100 rounded-md py-2 px-4 dark:hover:bg-opacity-10 items-center gap-4 justify-between"
                      >
                        <div className="flex items-center gap-2">
                          <Checkbox 
                            checked={checkedItems[`category-${category.id}`]} 
                            onCheckedChange={() => handleCheckChange(category.id, !checkedItems[`category-${category.id}`], true)} 
                            id={`category-${category.id}`} 
                            />
                          <Label className="underline hover:cursor-pointer">{category.name} </Label> 
                          <span  onClick={(e) => {
                            e.stopPropagation();
                            setToggleSubs(!toggleSubs);
                            }} 
                            className='hover:cursor-pointer'
                            >
                              {toggleSubs ? <ChevronUp size={14}/> : <ChevronDown size={14} />} 
                          </span> 
                          <span onClick={(e) => {
                            e.stopPropagation();
                            setToggleSubs(!toggleSubs);
                            }} 
                            className='hidden md:inline hover:cursor-pointer italic text-xs'>
                              ({toggleSubs ? 'hide' : 'view'} subcategories)
                            </span>
                        </div>
                            {category.question_count > 0 ? (
                              matchingCategory ?(
                                <div className="text-sm text-muted-foreground">
                                  <span className="font-medium">{matchingCategory.total_possible_points}</span> / <span className="font-medium">{category.question_count}</span> <span className="text-xs">attempted</span> <br />
                                  <span className="font-medium">{((matchingCategory.total_points_scored / matchingCategory.total_possible_points) * 100).toFixed(1)}%</span> <span className="text-xs">correct</span>
                                </div>
                              ) : (
                                <div className="text-sm text-muted-foreground">
                                  <span className="font-medium">0</span> / <span className="font-medium">{category.question_count}</span> <span className="text-xs">attempted</span> 
                                </div>
                              )                       
                            ) : (
                                <div className="text-sm text-muted-foreground">
                                  No questions available
                                </div>
                            )}
                      </div>
                      {toggleSubs && subcategoryNames.map((sub) => {
                        const matchingSubcategory = findMatchingSubcategory(sub.name);

                        if(sub.name !== "Urology") {
                          return (
                            <>
                              <div 
                                key={sub.id}
                                onClick={() => handleCheckChange(sub.id, !checkedItems[`subcategory-${sub.id}`], false)} 
                                className="mx-4 flex hover:bg-gray-100 hover:cursor-pointer rounded-md py-2 px-4 dark:hover:bg-opacity-10 items-center gap-4 justify-between"
                                >
                                <div className="flex items-center gap-2">
                                  <Checkbox 
                                    checked={checkedItems[`subcategory-${sub.id}`]} 
                                    onCheckedChange={() => handleCheckChange(sub.id, !checkedItems[`subcategory-${sub.id}`], false)} 
                                    id={`subcategory-${sub.id}`} 
                                    />
                                  <Label className="hover:cursor-pointer">{sub.name}</Label>
                                </div>
                                {sub.question_count > 0 ? (
                                  matchingSubcategory ?(
                                    <div className="text-sm text-muted-foreground">
                                      <span className="font-medium">{matchingSubcategory.total_possible_points}</span> / <span className="font-medium">{sub.question_count}</span> <span className="text-xs">attempted</span> <br />
                                      <span className="font-medium">{((matchingSubcategory.total_points_scored / matchingSubcategory.total_possible_points) * 100).toFixed(1)}%</span> <span className="text-xs">correct</span>
                                    </div>
                                  ) : (
                                    <div className="text-sm text-muted-foreground">
                                      <span className="font-medium">0</span> / <span className="font-medium">{sub.question_count}</span> <span className="text-xs">attempted</span> 
                                    </div>
                                  )                       
                                ) : (
                                    <div className="text-sm text-muted-foreground">
                                      No questions available
                                    </div>
                                )}
                              </div>
                            </>
                          )
                        } else {
                          return null
                        }

                      })}
                    </>
                  );
                }

                return (
                <div onClick={() => handleCheckChange(category.id, !checkedItems[`category-${category.id}`], true)} className="flex hover:bg-gray-100 hover:cursor-pointer rounded-md py-2 px-4 dark:hover:bg-opacity-10 items-center gap-4 justify-between">
                  <div className="flex items-center gap-2">
                    <Checkbox checked={checkedItems[`category-${category.id}`]} onCheckedChange={() => handleCheckChange(category.id, !checkedItems[`category-${category.id}`], true)} id={`category-${category.id}`} />
                    <Label className="hover:cursor-pointer">{category.name}</Label>
                  </div>
                      {category.question_count > 0 ? (
                        matchingCategory ?(
                          <div className="text-sm text-muted-foreground">
                            <span className="font-medium">{matchingCategory.total_possible_points}</span> / <span className="font-medium">{category.question_count}</span> <span className="text-xs">attempted</span> <br />
                            <span className="font-medium">{((matchingCategory.total_points_scored / matchingCategory.total_possible_points) * 100).toFixed(1)}%</span> <span className="text-xs">correct</span>
                          </div>
                        ) : (
                          <div className="text-sm text-muted-foreground">
                            <span className="font-medium">0</span> / <span className="font-medium">{category.question_count}</span> <span className="text-xs">attempted</span> 
                          </div>
                        )                       
                      ) : (
                          <div className="text-sm text-muted-foreground">
                            No questions available
                          </div>
                      )}
                </div>
                )
              })}
            </div>
        </div>
    );
}

export default CategoryFilter;
