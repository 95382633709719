import { Checkbox } from '../../../../../components/shadcn/ui/checkbox'
import { Label } from '../../../../../components/shadcn/ui/label'
import React, { useEffect, useMemo, useState } from 'react'
import { Button } from '../../../../../components/shadcn/ui/button'
import { RadioGroup, RadioGroupItem } from "../../../../../components/shadcn/ui/radio-group"
import QuestionFilter from "../../../../../components/cps_filter/QuestionFilter.jsx"
import { useFetchMSRASessionData } from '../../../../../components/hooks/MSRA/fetches/FetchActiveMSRASessionData'
import { useAuth0 } from '@auth0/auth0-react'
import { useFetchUserMSRAPerformance } from '../../../../../components/hooks/MSRA/user_performance/FetchUserPerformance'
import { useFetchCPSCategories } from '../../../../../components/hooks/MSRA/fetches/FetchCPSCategories'
import { useFetchCPSSubcategories } from '../../../../../components/hooks/MSRA/fetches/FetchCPSSubcategories'
import { useFetchSubscriptions } from '../../../../../components/hooks/MSRA/fetches/FetchSubscriptions'
import { useToast } from '../../../../../components/shadcn/ui/use-toast'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import CategoryFilter from '../../../../../components/cps_filter/CategoryFilter'
import StartSessionCard from '../../../../../components/cps_filter/StartSessionCard'
import SpinLoader from '../../../../../components/loader/SpinLoader'
import GoBackButton from '../../../../../components/other_ui/GoBackButton'
import { useFetchQuestionCountByPaper } from '../../../../../components/hooks/MSRA/fetches/FetchQuestionCountByPaper'
import { getCsrfToken } from '../../../../../components/hooks/csrfTokenHelper'

function TrialCPSHomePage() {
  const [questionFilter, setQuestionFilter] = useState('unanswered');
  const [token, setToken] = useState(null)
  const [tokenLoading, setTokenLoading] = useState(true)
  const { getAccessTokenSilently, isLoading: authLoading } = useAuth0()
  const [showDialog, setShowDialog] = useState(false);  
  const [clinicalProblemSolvingPaper, setClinicalProblemSolvingPaper] = useState(null);
  const [currentSubscriptionId, setCurrentSubscriptionId] = useState(null);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState(true)
  const [CPSPaperData, setCPSPaperData] = useState()
  const [findPaperLoading, setFindPaperLoading] = useState(true)
  const [questionCalculationLoading, setQuestionCalculationLoading] = useState(true)
  const [newSessionLoading, setNewSessionLoading] = useState(false)
  const { toast } = useToast()
  const navigate = useNavigate()

  
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setToken(token);
      } catch (error) {
        console.error('Error fetching token:', error);
      } finally {
        setTokenLoading(false);
      }
    };
    
    fetchToken();
  }, [getAccessTokenSilently]);
  
  // check if session exists for CPS
  const { data: existingSession, loading: existingLoading, error: existingError } = useFetchMSRASessionData(6, token, "MSRA Free Trial")
  // imports category names and number of questions
  const { categoryNames, loading: categoryLoading, error } = useFetchCPSCategories(token, "Clinical Problem Solving Free Trial");
  categoryNames.sort((a, b) => a.name.localeCompare(b.name)); 
  // imports subcategory names and number of questions
  const { subcategoryNames, loading: subcategoryLoading, error: subcategoryError } = useFetchCPSSubcategories(token, "Clinical Problem Solving Free Trial");
  const { data, loading: performanceLoading, error: performanceError } = useFetchUserMSRAPerformance(token, "MSRA Free Trial")
  const { subscriptions, loading: subLoading, error: subError } = useFetchSubscriptions(token)
  
  const allLoading = subcategoryLoading || performanceLoading || subLoading || existingLoading || categoryLoading || authLoading

  const handleOpenDialog = () => {
    setShowDialog(true);
  };

  const handleResumeSession = () => {
    navigate('/trial-MSRA/CPS/session');
  };

  useEffect(() => {
      if (!subLoading && subscriptions) {
        const activeSubscription = subscriptions.find(sub => sub.exam_name === "MSRA Free Trial" && sub.is_active);
          if (activeSubscription) {
              setCurrentSubscriptionId(activeSubscription.id);
          }
      }
  }, [subscriptions, subLoading]); 

  useEffect(() => {
    if (data && currentSubscriptionId) {
        const foundSubscription = data.find(sub => sub.subscription_id === currentSubscriptionId);
        setCurrentSubscription(foundSubscription);
    }
  }, [data, currentSubscriptionId]);

  useEffect(() => {
    if (!currentSubscription) {
        return;
    }

    const paper = currentSubscription.papers.find(p => p.name === "Clinical Problem Solving Free Trial");
    if (paper) {
        setCPSPaperData(paper);
    } else {
        setCPSPaperData(null); // Explicitly set to null if no paper found
    }

    setFindPaperLoading(false); // Set loading to false regardless of finding the paper
}, [currentSubscription]);

const GENERAL_MEDICINE_ID = 24; // Assuming 1 is the ID for General Medicine
const calculateSelectedQuestions = () => {
  
  let totalSelected = 0;

  categoryNames.forEach(category => {
      if (checkedItems[`category-${category.id}`]) {
          if (category.id === GENERAL_MEDICINE_ID) {
              // Handle General Medicine based on checked subcategories
              subcategoryNames.forEach(subcategory => {
                if (subcategory.name === "Urology Free Trial") return
                if (subcategory.category_id === GENERAL_MEDICINE_ID && checkedItems[`subcategory-${subcategory.id}`]) {
                      // Use the helper function to find the matching subcategory across subscriptions
                      const matchingSubcategory = findMatchingSubcategory(subcategory.name);

                      let subcategoryTotal = subcategory.question_count;  // Total questions for the subcategory

                      // If there's no performance data for the subcategory, assume all questions are unanswered
                      let subcategoryAnswered = matchingSubcategory ? matchingSubcategory.total_possible_points : 0;

                      // Calculate selected based on questionFilter
                      switch (questionFilter) {
                          case 'unanswered':
                              totalSelected += subcategoryTotal - subcategoryAnswered;
                              break;
                          case 'answered':
                              totalSelected += subcategoryAnswered;
                              break;
                          case 'all':
                          default:
                              totalSelected += subcategoryTotal;
                      }
                  }
              });
          } else {
              // Handle other categories
              // Use the helper function to find the matching category across subscriptions
              const matchingCategory = findMatchingCategory(category.name);

              let categoryTotal = category.question_count;  // Total questions for the category

              // If there's no performance data for the category, assume all questions are unanswered
              let categoryAnswered = matchingCategory ? matchingCategory.total_possible_points : 0;

              // Calculate selected based on questionFilter
              switch (questionFilter) {
                  case 'unanswered':
                      totalSelected += categoryTotal - categoryAnswered;
                      break;
                  case 'answered':
                      totalSelected += categoryAnswered;
                      break;
                  case 'all':
                  default:
                      totalSelected += categoryTotal;
              }
          }
      }
  });

  return totalSelected;
};

useEffect(() => {
  const allChecked = {};
  // Initialize categories as checked
  categoryNames.forEach(category => {
      allChecked[`category-${category.id}`] = true;
  });
  // Initialize subcategories as checked
  subcategoryNames.forEach(subcategory => {
      allChecked[`subcategory-${subcategory.id}`] = true;
  });
  setCheckedItems(allChecked);

  // Here you should not call calculateSelectedQuestions or manage loading because this effect
  // is only for initializing checked items. Calculation and loading management should be handled separately.
}, [categoryNames, subcategoryNames]); // Correct dependencies


useEffect(() => {
  if (data && !performanceLoading && !performanceError) {
  let foundPaper = null;

  // Iterate through each subscription
  for (const subscription of data) {
      // Attempt to find the paper with the name "Clinical Problem Solving" within this subscription
      const paper = subscription.papers.find(p => p.paper_name === "Clinical Problem Solving Free Trial");

      // If the paper is found, set it and break out of the loop
      if (paper) {
      foundPaper = paper;
      break;
      }
  }
  setSubscriptionLoading(false)
  // Update the state with the found paper, or null if not found
  setClinicalProblemSolvingPaper(foundPaper);
  }
}, [data, performanceLoading, performanceError]);


const [checkedItems, setCheckedItems] = useState(() => {
const allChecked = {};
// Initialize categories as checked
categoryNames.forEach(category => {
  allChecked[`category-${category.id}`] = true;
});
// Initialize subcategories as checked
subcategoryNames.forEach(subcategory => {
  allChecked[`subcategory-${subcategory.id}`] = true;
});
return allChecked;
});
const areAllChecked = Object.values(checkedItems).every(isChecked => isChecked);

const [selectedQuestions, setSelectedQuestions] = useState(0);

useEffect(() => {
  // Assuming calculateSelectedQuestions is synchronous
  if(findPaperLoading) return
  const totalSelected = calculateSelectedQuestions();
  setSelectedQuestions(totalSelected);
  setQuestionCalculationLoading(false);  // Set loading false only after calculation is done
}, [questionFilter, findPaperLoading, checkedItems, categoryNames, subcategoryNames, data]); // Correct dependencies



const handleCheckChange = (id, isChecked, isCategory) => {
  setCheckedItems(prevState => {
      const newState = { ...prevState };

      if (isCategory) {
          const categoryId = `category-${id}`;
          newState[categoryId] = isChecked;

          // If it's General Medicine, check/uncheck its subcategories accordingly
          if (id === GENERAL_MEDICINE_ID) {
              subcategoryNames.forEach(subcategory => {
                  if (subcategory.category_id === id && subcategory.name !== "Urology") {
                      newState[`subcategory-${subcategory.id}`] = isChecked;
                  }
              });
          }
      } else {
          const subcategoryId = `subcategory-${id}`;
          newState[subcategoryId] = isChecked;

          const parentCategory = subcategoryNames.find(sub => sub.id === id).category;

          // If any subcategory under General Medicine is checked, ensure General Medicine is checked
          if (isChecked) {
              newState[`category-24`] = true;
          } else {
              // Check if any other subcategories under General Medicine are still checked
              const isAnySubcategoryChecked = subcategoryNames.some(sub => {
                  return sub.category === parentCategory && newState[`subcategory-${sub.id}`];
              });

              // If no other subcategories are checked, uncheck General Medicine
              if (!isAnySubcategoryChecked) {
                  newState[`category-24`] = false;
              }
          }
      }

      return newState;
  });
};



const handleAllQuestionsChange = (isChecked) => {
  const newState = {};
  // Set all categories and subcategories to the isChecked value
  categoryNames.forEach(category => {
      newState[`category-${category.id}`] = isChecked;
  });
  subcategoryNames.forEach(subcategory => {
      newState[`subcategory-${subcategory.id}`] = isChecked;
  });
  setCheckedItems(newState);
};

const handleSubmit = async (e) => {
  e.preventDefault(); 

  if(selectedQuestions === 0){
      toast({
          variant: "destructive",
          title: "Uh oh! You haven't selected any questions.",
          description: "Please double check your preferences to make sure there are questions in your new revision session.",
          duration: 4000,
      });
      return
  }
  // Gather selected category IDs
  const selectedCategoryIds = categoryNames.filter(cat => checkedItems[`category-${cat.id}`]).map(cat => cat.id);

  // Gather selected subcategory IDs
  const selectedSubcategoryIds = subcategoryNames.filter(sub => checkedItems[`subcategory-${sub.id}`]).map(sub => sub.id);

  // Construct the payload, explicitly setting 'exam' and 'paper' to 1
  const payload = {
      exam: 5,  // Explicitly setting 'exam' to MSRA id
      paper: 6,  // Explicitly setting 'paper' to CPS id
      selectedCategoryIds,
      selectedSubcategoryIds,
      questionFilter: questionFilter.toUpperCase()
  };

  try {
      setNewSessionLoading(true)
      const csrfToken = getCsrfToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/MSRA/user-session/create-user-session/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
              "X-CSRFToken": csrfToken,  
          },
          body: JSON.stringify(payload)  
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setNewSessionLoading(false)
      navigate('/trial-MSRA/CPS/session')
  } catch (error) {
      console.error('Error creating user session:', error);
  }
};

  const findMatchingCategory = (categoryName) => {
  if (!currentSubscription) return null; // Return null if the category is not found in any subscription
  const matchingCategory = currentSubscription.categories.find(sub => sub.name === categoryName);
    if (matchingCategory) return matchingCategory;
};

  const findMatchingSubcategory = (subcategoryName) => {
    if (!currentSubscription) return null; // Return null if the subcategory is not found in any subscription
    const matchingSubcategory = currentSubscription.subcategories.find(sub => sub.name === subcategoryName);
    if (matchingSubcategory) return matchingSubcategory;
};

if ( allLoading) {
  return <SpinLoader loadingText={`Loading questions...`} />; // or a loader
}
if ( newSessionLoading ) {
  return (
    <SpinLoader loadingText={`Creating your session, please wait...`}/>
  );
}

  return (
    <>
      <GoBackButton 
        altText='All Trial MSRA Papers'
        navigateTo='/trial-MSRA/home'
        />
      <div className="container mx-auto px-4 pb-8 md:px-6 md:pb-12">
        <div className="space-y-8">
          <div className="text-center sm:text-left sm:max-w-[80%] mx-auto">
            <h1 
              className="text-3xl font-bold"
            >FREE TRIAL - Clinical Problem Solving Paper</h1>
            <h1 className="text-xl font-semibold">Question Bank</h1>
            <div>
              <span
                className="mt-1 text-sm hover:underline cursor-pointer"
                onClick={() => navigate("/trial-MSRA/all-session-summary/CPS")}
              >
                Review Previous Sessions
              </span>
            </div>
          </div>

          <div className="grid sm:max-w-[70%] mx-auto gap-8">
            <div className="mx-auto">
              <QuestionFilter
                  selectedFilter={questionFilter}
                  setSelectedFilter={setQuestionFilter}
                />
            </div>
            <CategoryFilter 
              categoryNames={categoryNames}
              subcategoryNames={subcategoryNames}
              findMatchingCategory={findMatchingCategory}
              findMatchingSubcategory={findMatchingSubcategory}
              checkedItems={checkedItems}
              handleAllQuestionsChange={handleAllQuestionsChange}
              areAllChecked={areAllChecked}
              handleCheckChange={handleCheckChange}
            />
            <StartSessionCard 
              selectedQuestions={selectedQuestions}
              existingSession={existingSession}
              handleOpenDialog={handleOpenDialog}
              handleResumeSession={handleResumeSession}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default TrialCPSHomePage

