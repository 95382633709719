import { format, parseISO } from 'date-fns';

const useFormattedDate = (dateString) => {
  const formatDate = () => {
    // Parse the ISO string to a Date object
    if (!dateString) return null
    
    const date = parseISO(dateString);

    // Format the date to a user-friendly string
    return format(date, 'MMM do, yyyy'); // E.g., "May 3rd, 2024"
  };

  return formatDate();
};

export default useFormattedDate;
