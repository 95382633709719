import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '../shadcn/ui/button'

function GoBackButton({ navigateTo, altText }) {
    const navigate = useNavigate()

  return (
    <Link
      to={navigateTo}
      className="inline-flex items-center gap-2 mx-3 my-2"
      prefetch={false}
    >
      <Button variant='outline'>
        <ArrowLeftIcon className="h-4 w-4" />
        {altText ? altText : 'Go Back'}
      </Button>
    </Link>
  )
}

export default GoBackButton

function ArrowLeftIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="m12 19-7-7 7-7" />
        <path d="M19 12H5" />
      </svg>
    )
  }