import { ResponsiveBar } from '@nivo/bar';
import '../../styles/index.css';
import { useTheme } from '../common/ThemeContext';


function BarChart({ data, performancePercentage, viewPort, homePage }) {
  const { theme } = useTheme();
  const barColor = theme === 'light' ? 'hsl(222.2, 67%, 28%)' : 'hsl(222.2, 67%, 70%)'
  const textColor = theme === 'light' ? 'black' : 'hsl(210 40% 98%)'
  const toolTipBackground = theme === 'light' ? 'white' : 'hsl(223 47% 11%)'
  const graphHeight = viewPort === 'small' ? 300 : viewPort === 'medium' ? 400 : viewPort === 'large' ? 500 : 0
  const axisLegendSize = viewPort === 'small' ? 12 : viewPort === 'medium' ? 16 : viewPort === 'large' ? 18 : 0

  const nivoData = Object.entries(data).map(([key, value]) => ({
    category: key,
    percentage: value['proportion_of_users'],
    cumPercentage: value['cumulative_proportion'],
  }));

  // Determine the category corresponding to performancePercentage
  const sortedCategories = Object.keys(data).sort((a, b) => {
    const [aMin] = a.split('-').map(Number);
    const [bMin] = b.split('-').map(Number);
    return aMin - bMin;
  });

  // const maxValue = Math.max(...Object.values(data)) % 2 === 0 ? Math.max(...Object.values(data)) + 2 : Math.max(...Object.values(data)) + 1;

  // Custom Tooltip Function
  const customTooltip = (bar) => (
    <div 
      style={{
        padding: '5px 10px',
        background: toolTipBackground,
        border: theme === 'light' ? '1px solid black' : '1px solid hsl(210 40% 98%)',
      }}
    >
        <strong>{bar.data.cumPercentage.toFixed(1)}% of users have a score of {bar.data.category} or higher.</strong>
    </div>
  );

  const customTheme = {
    axis: {
      ticks: {
        line: {
          stroke: textColor,
        },
        text: {
          fill: textColor
        }
      },
      legend: {
        text: {
          fontSize: axisLegendSize, // Increase font size
          fontWeight: 'bold', // Make text bold
          fill: textColor,
        },
      },
    },
  };

  const tickValues = viewPort === 'small' ? sortedCategories.filter((_, index) => index % 10 === 0) : sortedCategories.filter((_, index) => index % 5 === 0);
  
  
  return (
    <div style={{height: graphHeight}}>
      <ResponsiveBar
        data={nivoData}
        keys={['percentage']}
        indexBy="category"
        margin={{ top: 10, right: 0, bottom: 70, left: 55 }}
        padding={0}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        // Dynamically assign colors to bars
        colors={({ data }) =>
          homePage ? (
            data.category === `70%`
              ? 'hsl(120, 53%, 47%)' // Highlight color for the specific bar
              : barColor // Default bar color
          ) : (
            data.category === `${Math.floor(performancePercentage)}%`
              ? 'hsl(120, 53%, 47%)' // Highlight color for the specific bar
              : barColor // Default bar color
          )
        }
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickValues: tickValues,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -40,
          legend: 'Score',
          legendPosition: 'middle',
          legendOffset: 45,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Proportion of Users',
          legendPosition: 'middle',
          legendOffset: -45,
          format: (d) => `${d}%`, // Format ticks as percentages
        }}
        animate={true}
        enableLabel={false}
        tooltip={customTooltip} // Use custom tooltip function
        barAriaLabel={(e) => `${e.id}: ${e.value}%`}
        theme={customTheme} // Apply custom theme
      />

    </div>
  );
}

export default BarChart;
