import React from 'react';
import { useTheme } from './ThemeContext';

export default function LogoIcon({ width, height }) {
    const { theme } = useTheme()
  const fillColor = theme === 'dark' ? '#eeeeee' : '#183477'; // Default to light theme color

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="770 650 500 500" width={width} height={height} fill={fillColor}>
        <path d="M854,1034.27l72.71,44.43c34.54-57.36,35.42-139.42,35.42-139.42-51.48-11.56-75.57-42.26-86.82-69.66a126.09,126.09,0,0,1-4.64-13.24c-5.95-20.64-5.16-36.75-5.16-36.75s7.55,18,26.61,36.75C912.84,876.81,947.08,898,1000,898c35.3,0,63.13-18,84.4-41.65,18.26-20.2,31.72-44.51,40.94-64.94l.53-1.19a304.21,304.21,0,0,0,15.4-41.78c-1.37,2.65-13.72,26.69-30.26,36l-111-43.15L772.59,829.69,854,861.32Zm146-239.52a37.87,37.87,0,1,1-37.9,37.9A37.86,37.86,0,0,1,1000,794.75Z" />
        <path d="M1000,1016.36c-12.17,36.53-32.91,59.95-52.9,74.82l47.43,29,55.28-31.32C1030.81,1073.89,1011.58,1051,1000,1016.36Z" />
        <path d="M1146.05,1034.27v-173l81.36-31.59-82.64-32.12A152.39,152.39,0,0,1,1126,856.38a171.53,171.53,0,0,1-10.94,17c-14.78,20.12-36.31,40.37-67.37,58.72,0,0-5.21,84.36,25.54,143.48Z" />
        <polygon points="1126.34 749 1145.62 738.87 1164.89 749 1161.21 727.53 1176.81 712.33 1155.26 709.2 1145.62 689.67 1135.97 709.2 1114.42 712.33 1130.02 727.53 1126.34 749" />
        <polygon points="1164.14 873.28 1164.14 1044.81 994.21 1141.16 835.9 1044.42 835.9 873.12 819.13 867.88 819.13 1053.82 993.88 1160.61 1180.91 1054.58 1180.91 865.43 1164.14 873.28 1164.14 873.28" />
    </svg>
  );
}

