import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from './components/shadcn/ui/toaster';
import { ThemeProvider } from './components/common/ThemeContext';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';


// OLD
import Textbook from './pages/MSRA/old/Textbook';


// UNAUTHEDPAGES
import HomePage from './pages/public/HomePage';
import About from './pages/public/About'
import Pricing from './pages/public/Pricing';
import ContactUs from './pages/public/ContactUs';
import Privacy from './pages/public/Privacy';
import TermsAndConditions from './pages/public/TermsAndConditions';

// COMPONENTS
import Navbar from './components/common/Navbar';
import ProtectedRoute from './components/Middleware/ProtectedRoute'
import Footer from './components/common/Footer';
import MSRAProtectedRoute from './components/Middleware/MSRAProtectedRoute';

// AUTHED PAGES
import Profile from './pages/MSRA/new/all_auth/Profile';
import MSRAHomePage from './pages/MSRA/new/MSRA/MSRAHomePage';
import NoMSRASubscription from './pages/errors/NoMSRASubscription';
import CPSHomePage from './pages/MSRA/new/MSRA/CPS/CPSHomePage';
import PDHomePage from './pages/MSRA/new/MSRA/PD/PDHomePage';
import CPSActiveSession from './pages/MSRA/new/MSRA/CPS/CPSActiveSession';
import PDActiveSession from './pages/MSRA/new/MSRA/PD/PDActiveSession';
import CPSSessionSummary from './pages/MSRA/new/MSRA/CPS/CPSSessionSummary';
import PDSessionSummary from './pages/MSRA/new/MSRA/PD/PDSessionSummary';
import PDAllSessionSummary from './pages/MSRA/new/MSRA/PD/PDAllSessionSummary';
import CPSAllSessionSummary from './pages/MSRA/new/MSRA/CPS/CPSAllSessionSummary';
import CPSSingleSessionReview from './pages/MSRA/new/MSRA/CPS/CPSSingleSessionReview';
import PDSingleSessionReview from './pages/MSRA/new/MSRA/PD/PDSingleSessionReview';
import MSRADashboard from './pages/MSRA/new/MSRA/MSRADashboard';
import MSRATextbook from './pages/MSRA/new/MSRA/MSRATextbook';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { CookiePolicyBanner } from './components/common/CookieBanner';
import MSRATrialHomePage from './pages/MSRA/new/Trial_MSRA/MSRATrialHomePage';
import TrialCPSHomePage from './pages/MSRA/new/Trial_MSRA/CPS/TrialCPSHomePage';
import TrialPDHomePage from './pages/MSRA/new/Trial_MSRA/PD/TrialPDHomePage';
import TrialCPSActiveSession from './pages/MSRA/new/Trial_MSRA/CPS/TrialCPSActiveSession';
import TrialPDActiveSession from './pages/MSRA/new/Trial_MSRA/PD/TrialPDActiveSession';
import TrialCPSSessionSummary from './pages/MSRA/new/Trial_MSRA/CPS/TrialCPSSessionSummary';
import TrialPDSessionSummary from './pages/MSRA/new/Trial_MSRA/PD/TrialPDSessionSummary';
import TrialCPSAllSessionSummary from './pages/MSRA/new/Trial_MSRA/CPS/TrialCPSAllSessionSummary';
import TrialPDAllSessionSummary from './pages/MSRA/new/Trial_MSRA/PD/TrialPDAllSessionSummary';
import TrialCPSSingleSessionReview from './pages/MSRA/new/Trial_MSRA/CPS/TrialCPSSingleSessionReview';
import TrialPDSingleSessionReview from './pages/MSRA/new/Trial_MSRA/PD/TrialPDSingleSessionReview';
import MSRATrialTextbook from './pages/MSRA/new/Trial_MSRA/MSRATrialTextbook';
import TrialMSRAProtectedRoute from './components/Middleware/TrialMSRAProtectedRoute';
import TrialMSRADashboard from './pages/MSRA/new/Trial_MSRA/TrialMSRADashboard';


const initializeAnalytics = () => {
  ReactGA.initialize('G-7BN3YH058H', {
    gaOptions: {
      anonymizeIp: true, // This anonymizes the IP
    },
  }); // Replace with your Google Analytics ID
}

// Component to handle page view tracking
const PageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const consent = localStorage.getItem('cookie-consent')
    if (consent === 'accepted') {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })
    }
  }, [location]);

  return null;
};
//pages & components

const App = () => {
  const [analyticsEnabled, setAnalyticsEnabled] = useState(false)

  useEffect(() => {
    const consent = localStorage.getItem('cookie-consent')
    if (consent === 'accepted') {
      initializeAnalytics()
      setAnalyticsEnabled(true)
    }
  }, [])

  const handleAcceptCookies = () => {
    initializeAnalytics()
    setAnalyticsEnabled(true)
  }

  const handleRejectCookies = () => {
    setAnalyticsEnabled(false)
  }

  return ( 
    <ThemeProvider>
      <div className="App flex flex-col min-h-screen">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              {analyticsEnabled && <PageViewTracker />}
              <Navbar />
              <main className="flex flex-col flex-grow">
                <div className="pages flex-grow">
                  <Routes>
                    <Route
                      path='/'
                      element={<HomePage />}
                      />
                    <Route
                      path='/pricing'
                      element={
                          <Pricing />
                      }
                      />
                    <Route
                      path='/privacy-policy'
                      element={<Privacy />}
                      />
                    <Route
                      path='/terms-and-conditions'
                      element={<TermsAndConditions />}
                      />
                    <Route
                      path="/profile"
                      element={
                        <ProtectedRoute>
                          <Profile />
                        </ProtectedRoute>
                      }
                      />
                    <Route 
                      path="/no-access/MSRA"
                      element={
                        <ProtectedRoute>
                          <NoMSRASubscription />
                        </ProtectedRoute>
                      }
                    />
                    <Route 
                      path="/trial-MSRA/home"
                      element={
                        <TrialMSRAProtectedRoute>
                          <MSRATrialHomePage />
                        </TrialMSRAProtectedRoute>
                      }
                      />
                    <Route 
                      path="/MSRA/home"
                      element={
                        <MSRAProtectedRoute>
                          <MSRAHomePage />
                        </MSRAProtectedRoute>
                      }
                      />
                    <Route
                      path="/MSRA/CPS/start-session"
                      element={
                        <MSRAProtectedRoute>
                          <CPSHomePage />                          
                        </MSRAProtectedRoute>
                      }
                      />
                    <Route
                      path="/MSRA/PD/start-session"
                      element={
                        <MSRAProtectedRoute>
                          <PDHomePage />
                        </MSRAProtectedRoute>
                      }
                      />
                    <Route
                      path="/trial-MSRA/CPS/start-session"
                      element={
                        <TrialMSRAProtectedRoute>
                          <TrialCPSHomePage />                          
                        </TrialMSRAProtectedRoute>
                      }
                      />
                    <Route
                      path="/trial-MSRA/PD/start-session"
                      element={
                        <TrialMSRAProtectedRoute>
                          <TrialPDHomePage />
                        </TrialMSRAProtectedRoute>
                      }
                      />
                    <Route 
                      path="/MSRA/CPS/session"
                      element={
                        <MSRAProtectedRoute>
                          <CPSActiveSession />
                        </MSRAProtectedRoute>
                      }
                      />
                    <Route 
                      path="/MSRA/PD/session"
                      element={
                        <MSRAProtectedRoute>
                          <PDActiveSession />
                        </MSRAProtectedRoute>
                      }
                      />
                    <Route 
                      path="/trial-MSRA/CPS/session"
                      element={
                        <TrialMSRAProtectedRoute>
                          <TrialCPSActiveSession />
                        </TrialMSRAProtectedRoute>
                      }
                      />
                    <Route 
                      path="/trial-MSRA/PD/session"
                      element={
                        <TrialMSRAProtectedRoute>
                          <TrialPDActiveSession />
                        </TrialMSRAProtectedRoute>
                      }
                      />
                    <Route
                      path="/MSRA/session-summary/CPS/:sessionId"
                      element={
                        <MSRAProtectedRoute>
                          <CPSSessionSummary />
                        </MSRAProtectedRoute>
                      }
                      />
                    <Route
                      path="/MSRA/session-summary/PD/:sessionId"
                      element={
                        <MSRAProtectedRoute>
                          <PDSessionSummary />
                        </MSRAProtectedRoute>
                      }
                      />
                    <Route
                      path="/trial-MSRA/session-summary/CPS/:sessionId"
                      element={
                        <TrialMSRAProtectedRoute>
                          <TrialCPSSessionSummary />
                        </TrialMSRAProtectedRoute>
                      }
                      />
                    <Route
                      path="/trial-MSRA/session-summary/PD/:sessionId"
                      element={
                        <TrialMSRAProtectedRoute>
                          <TrialPDSessionSummary />
                        </TrialMSRAProtectedRoute>
                      }
                      />
                    <Route
                      path="/MSRA/all-session-summary/CPS"
                      element={
                        <MSRAProtectedRoute>
                          <CPSAllSessionSummary />
                        </MSRAProtectedRoute>
                      }
                      />
                    <Route
                      path="/MSRA/all-session-summary/PD"
                      element={
                        <MSRAProtectedRoute>
                          <PDAllSessionSummary />
                        </MSRAProtectedRoute>
                      }
                      />
                    <Route
                      path="/trial-MSRA/all-session-summary/CPS"
                      element={
                        <TrialMSRAProtectedRoute>
                          <TrialCPSAllSessionSummary />
                        </TrialMSRAProtectedRoute>
                      }
                      />
                    <Route
                      path="/trial-MSRA/all-session-summary/PD"
                      element={
                        <TrialMSRAProtectedRoute>
                          <TrialPDAllSessionSummary />
                        </TrialMSRAProtectedRoute>
                      }
                      />
                    <Route 
                      path="/MSRA/session-review/CPS/:id" 
                      element={
                        <MSRAProtectedRoute>
                          <CPSSingleSessionReview />
                        </MSRAProtectedRoute>
                      } />
                    <Route 
                      path="/MSRA/session-review/PD/:id" 
                      element={
                        <MSRAProtectedRoute>
                          <PDSingleSessionReview />
                        </MSRAProtectedRoute>
                      } />
                    <Route 
                      path="/trial-MSRA/session-review/CPS/:id" 
                      element={
                        <TrialMSRAProtectedRoute>
                          <TrialCPSSingleSessionReview />
                        </TrialMSRAProtectedRoute>
                      } />
                    <Route 
                      path="/trial-MSRA/session-review/PD/:id" 
                      element={
                        <TrialMSRAProtectedRoute>
                          <TrialPDSingleSessionReview />
                        </TrialMSRAProtectedRoute>
                      } />
                    <Route
                      path="/MSRA/dashboard"
                      element={
                        <MSRAProtectedRoute>
                          <MSRADashboard />
                        </MSRAProtectedRoute>
                      }
                      />
                    <Route
                      path="/MSRA/textbook/:id"
                      element={
                        <MSRAProtectedRoute>
                          <MSRATextbook />
                        </MSRAProtectedRoute>
                      }
                      />
                    <Route
                      path="/trial-MSRA/dashboard"
                      element={
                        <TrialMSRAProtectedRoute>
                          <TrialMSRADashboard />
                        </TrialMSRAProtectedRoute>
                      }
                      />
                    <Route
                      path="/trial-MSRA/textbook/:id"
                      element={
                        <TrialMSRAProtectedRoute>
                          <MSRATrialTextbook />
                        </TrialMSRAProtectedRoute>
                      }
                      />
                  </Routes>
                </div>
                <Footer />
                <CookiePolicyBanner
                  onAccept={handleAcceptCookies}
                  onReject={handleRejectCookies}
                />
              </main>
            </BrowserRouter>
          </PersistGate>
        </Provider>
        <ToastContainer />
        <Toaster />
      </div>
    </ThemeProvider>
   );
}
 
export default App;