import React from 'react'
import { Card } from '../shadcn/ui/card'
import { Separator } from '../shadcn/ui/separator'
import HeaderMedium from '../typography/HeaderMedium'
import { ExternalLinkIcon } from 'lucide-react'

function DemoTextbookPage() {
  return (
    <div className='my-2'>
        <Card className='w-full lg:w-[75vw] mx-auto p-1 md:p-8 px-2 mb-5 border-none'>
            <h2 className='scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-2'>Hypertension - Diagnosis and Management</h2>
            <div id="ckeditor" className="ckeditor-content">
                <p>
                    <strong>Diagnosis</strong>
                </p>
                <p>
                    <i>Clinic blood pressure reading</i>
                </p>
                <p style={{ marginLeft: '35.0pt' }}>
                    &#8226;&nbsp;&nbsp; BP &lt; 140/90 mmHg:
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Check BP at least every 5 years (more often if close to 140/90 mmHg)
                </p>
                <p style={{ marginLeft: '35.0pt' }}>
                    &#8226;&nbsp;&nbsp; BP between 140/90 mmHg and 179/119 mmHg:
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Offer ambulatory blood pressure monitoring (ABPM) or home blood pressure monitoring (HBPM)
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Investigate for target organ damage
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Assess cardiovascular risk
                </p>
                <p style={{ marginLeft: '35.0pt' }}>
                    &#8226;&nbsp;&nbsp; BP 180/120 mmHg or more:
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Assess for target organ damage ASAP
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; If there is damage, consider starting drug treatment immediately
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; If there is not damage, confirm diagnosis with repeat clinic BP measurement within 7 days <strong>or</strong> ABPM/HBPM with a clinical review within 7 days
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Arrange same-day specialist assessment if there are any of the following:
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; Retinal haemorrhage or papilloedema
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; Life-threatening symptoms
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; Suspected pheochromocytoma
                </p>
                <p>
                    <i>
                        ABPM/HBPM
                    </i>
                </p>
                <p style={{ marginLeft: '35.0pt' }}>
                    &#8226;&nbsp;&nbsp; BP &lt; 135/85 mmHg:
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Check BP at least every 5 years (more often if close to 140/90 mmHg)
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; If there is evidence of target organ damage, consider alternative causes
                </p>
                <p style={{ marginLeft: '35.0pt' }}>
                    &#8226;&nbsp;&nbsp; BP between 135/85 mmHg and 149/94 mmHg (Stage 1)
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Age &gt;80 with clinic BP &gt;150/90 mmHg:
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; Offer lifestyle advice
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; Consider drug treatment
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Age &lt;80 with target organ damage, CVD, renal disease, diabetes, or 10-year CVD risk ≥10%:
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; Offer lifestyle advice
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; Discuss starting drug treatment
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Age &lt;60 with 10-year CVD risk &lt;10%:
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; Offer lifestyle advice
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; Consider drug treatment
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Age &lt;40:
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; Consider specialist evaluation of secondary causes and assessment of long-term benefits and risks of treatment
                </p>
                <p style={{ marginLeft: '35.0pt' }}>
                    &#8226;&nbsp;&nbsp; BP 150/95 mmHg or more:
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Age ≥40:
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; Offer lifestyle advice
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; Discuss starting drug treatment
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Age &lt;40:
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; Consider specialist evaluation of secondary causes and assessment of long-term benefits and risks of treatment
                </p>
                <p>
                    &nbsp;
                </p>
                <p>
                    <strong>
                        Management
                    </strong>
                </p>
                <p>
                    <i>Hypertension with type 2 diabetes</i> <strong>or</strong> <i>Age &lt;55 </i><strong>and</strong><i> not of black African or African-Caribbean family origin</i>
                </p>
                <p style={{ marginLeft: '35.0pt' }}>
                    &#8226;&nbsp;&nbsp; Step 1:
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; ACE inhibitor (ACEi) i.e. lisinopril, ramipril, enalapril <strong>or:</strong>
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Angiotensin receptor blocker (ARB) i.e. losartan, candesartan, irbesartan, valsartan
                </p>
                <p style={{ marginLeft: '35.0pt' }}>
                    &#8226;&nbsp;&nbsp; Step 2:
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; ACE or ARB <strong>plus:</strong>
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; Calcium channel blocker (CCB) i.e. amlodipine, felodipine <strong>or:</strong>
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; Thiazide-like diuretic i.e. indapamide
                </p>
                <p style={{ marginLeft: '35.0pt' }}>
                    &#8226;&nbsp;&nbsp; Step 3:
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; ACEi or ARB <strong>plus</strong> CCB <strong>plus</strong> thiazide-like diuretic
                </p>
                <p style={{ marginLeft: '35.0pt' }}>
                    &#8226;&nbsp;&nbsp; Step 4:
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Confirm elevated BP with ABPM/HBPM
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Check for postural hypotension
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Discuss adherence
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Consider seeking expert advice <strong>or</strong> adding a:
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; Low-dose spironolactone if blood potassium is ≤ 4.5 mmol/L
                </p>
                <p style={{ marginLeft: '108.0pt' }}>
                    &#8226;&nbsp; Alpha-blocker or beta-blocker if blood potassium is &gt; 4.5 mmol/L
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Seek expert advice if BP is uncontrolled on optimal tolerated doses of 4 drugs
                </p>
                <p>
                    <i>Hypertension without type 2 diabetes</i> <strong>and</strong><i> age 55 or over</i> <strong>or </strong><i>Black African or African-Caribbean family origin of any age</i>
                </p>
                <p style={{ marginLeft: '35.0pt' }}>
                    &#8226;&nbsp;&nbsp; Step 1:
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; CCB
                </p>
                <p style={{ marginLeft: '35.0pt' }}>
                    &#8226;&nbsp;&nbsp; Step 2:
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; CCB <strong>plus</strong> ACEi or ARB or thiazide-like diuretic
                </p>
                <p style={{ marginLeft: '35.0pt' }}>
                    &#8226;&nbsp;&nbsp; Step 3 and 4:
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Identical as above
                </p>
                <p>
                    &nbsp;
                </p>
                <p>
                    <strong>Monitoring treatment</strong>
                </p>
                <p style={{ marginLeft: '35.0pt' }}>
                    &#8226;&nbsp;&nbsp; Measure standing and sitting BP in people with:
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; T2DM <strong>or</strong>
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Symptoms of postural hypotension <strong>or</strong>
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Aged 80+
                </p>
                <p>
                    &nbsp;
                </p>
                <p>
                    <strong>BP targets</strong>
                </p>
                <p style={{ marginLeft: '35.0pt' }}>
                    &#8226;&nbsp;&nbsp; Age &lt; 80:
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Clinic BP &lt;140/90 mmHg
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; ABPM/HBPM &lt;135/85 mmHg
                </p>
                <p style={{ marginLeft: '35.0pt' }}>
                    &#8226;&nbsp;&nbsp; Age ≥80:
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Clinic BP &lt;150/90 mmHg
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; ABPM/HBPM &lt;145/85 mmHg
                </p>
                <p style={{ marginLeft: '35.0pt' }}>
                    &#8226;&nbsp;&nbsp; Postural hypotension:
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Baste target on standing BP
                </p>
                <p style={{ marginLeft: '35.0pt' }}>
                    &#8226;&nbsp;&nbsp; Frailty or multimorbidity:
                </p>
                <p style={{ marginLeft: '72.0pt' }}>
                    &#8226;&nbsp;&nbsp; Use clinical judgement
                </p>
            </div>
            <Separator />
            <div className='my-2'>
                <HeaderMedium blackText={false} text="Recommended Reading:" underline={true} /> 
                <ul className='list-disc pl-5'>
                    <li className='py-1'>
                      <a
                        href="https://cks.nice.org.uk/topics/hypertension/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline flex items-center space-x-1"
                      >
                        <span>NICE Guidelines - Hypertension</span>
                        <ExternalLinkIcon size={14}/>
                      </a>
                    </li>
                </ul>
            </div>
        </Card>
    </div>
  )
}

export default DemoTextbookPage