import React from 'react'
import { RadioGroup, RadioGroupItem } from '../shadcn/ui/radio-group'
import { Label } from '../shadcn/ui/label'

function QuestionFilter({ selectedFilter, setSelectedFilter }) {
  return (
    <div>
      <h2 className="text-xl md:text-center font-semibold mb-4">Question Filter</h2>
      <RadioGroup value={selectedFilter} onValueChange={setSelectedFilter}>
        <div className="space-y-2 md:space-y-0 md:flex md:items-center md:gap-4">
          <div className="flex items-center gap-2">
            <RadioGroupItem value="unanswered" id="unanswered" />
            <Label className="hover:cursor-pointer" htmlFor="unanswered">Unanswered Questions</Label>
          </div>
          <div className="flex items-center gap-2">
            <RadioGroupItem value="all" id="all" />
            <Label className="hover:cursor-pointer" htmlFor="all">All Questions</Label>
          </div>
          <div className="flex items-center gap-2">
            <RadioGroupItem value="answered" id="answered" />
            <Label className="hover:cursor-pointer" htmlFor="answered">Answered Questions</Label>
          </div>
        </div>
      </RadioGroup>
    </div>
  )
}

export default QuestionFilter