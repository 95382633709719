import { getCsrfToken } from "../../csrfTokenHelper";
const baseUrl = `${process.env.REACT_APP_API_URL}custom_api`;
const csrfToken = getCsrfToken();

export async function fetchTextbookPages(sessionId, token) {
    if (!token) return;  // Ensure no request is made without a token
    const response = await fetch(`${baseUrl}/MSRA/user-session/user-session/${sessionId}/textbook-pages/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        "X-CSRFToken": csrfToken,  
      },
    });
    if (!response.ok) {
      console.error("Failed to fetch textbook pages");
      throw new Error("Failed to fetch textbook pages");
    }
    return response.json();
}

export async function fetchUserQuestionStates(sessionId, token) {
    if (!token) return;  // Ensure no request is made without a token
    const response = await fetch(`${baseUrl}/MSRA/user-session/${sessionId}/question-states/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        "X-CSRFToken": csrfToken,  
      },
    });
    if (!response.ok) {
      console.error("Failed to fetch question states");
      throw new Error("Failed to fetch question states");
    }
    return response.json();
}

export async function fetchSessionQuestions(sessionId, token) {
    if (!token) return;  // Ensure no request is made without a token
    const response = await fetch(`${baseUrl}/MSRA/user-session/session/${sessionId}/questions/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        "X-CSRFToken": csrfToken,  
      },
    });
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    return response.json();
}

export async function enrichQuestionsWithDetails(questions, token) {
  const questionIds = questions.map(q => q.id);

  const [scenarios, options] = await Promise.all([
      fetch(`${baseUrl}/questions/scenarios/`, {
          method: 'POST',
          headers: { 
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              "X-CSRFToken": csrfToken,
          },
          body: JSON.stringify({ question_ids: questionIds }),
      }).then(res => res.json()),
      fetch(`${baseUrl}/questions/options/`, {
          method: 'POST',
          headers: { 
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              "X-CSRFToken": csrfToken,
          },
          body: JSON.stringify({ question_ids: questionIds }),
      }).then(res => res.json()),
  ]);

  return questions.map(question => ({
      ...question,
      scenarios: scenarios.filter(scenario => scenario.question === question.id),
      options: options.filter(option => option.question === question.id),
  }));
}


export async function fetchQuestionAttempts(sessionId, token) {
    if (!token) return;  // Ensure no request is made without a token
    const response = await fetch(`${baseUrl}/MSRA/user-session/session-attempts/${sessionId}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        "X-CSRFToken": csrfToken,  
      },
    });
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    return response.json();
}

export async function fetchQuestionAttemptStats(sessionId, token) {
    if (!token) return;  // Ensure no request is made without a token
    const response = await fetch(`${baseUrl}/questions/MSRA/session-question-stats/${sessionId}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        "X-CSRFToken": csrfToken,  
      },
    });
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    return response.json();
}

export async function fetchSingleTextbookPage(questionId, token) {
  if (!token) return;  // Ensure no request is made without a token
  const response = await fetch(`${baseUrl}/MSRA/user-session/user-session/question/${questionId}/textbook-page/`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
      },
  });
  if (!response.ok) {
      throw new Error(`Failed to fetch textbook page for question ${questionId}`);
  }
  return response.json();
}
