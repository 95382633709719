import React from 'react'

function SpinLoader( {loadingText} ) {
    return (
        <div>
        <div className="flex items-center justify-center min-h-[75vh] flex-col">
            <div className="animate-spin rounded-full border-4 border-primary border-t-transparent h-16 w-16" />
            {loadingText && (
                <p className="mt-4 text-center text-lg">{loadingText}</p>
            )}
        </div>
    </div>

      )
}

export default SpinLoader