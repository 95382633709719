import { useEffect } from "react";

export const useIncrementSubscription = (
    setIncrementSuccess,
    incrementSuccess,
    setIncrementError,
    incrementError,
    setIncrementLoading,
    incrementLoading,
    token,
    csrfToken
) => {
    useEffect(() => {
        if (!token || !incrementLoading) {
            return; // Exit if token is not available or not triggered
        }

        const incrementSubscription = async () => {
            try {
                let response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/subscriptions/increment_subscription/`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "X-CSRFToken": csrfToken,
                    },
                });

                if (!response.ok) throw new Error('Failed to reset question bank data.');

                const data = await response.json();
                setIncrementSuccess(true);
            } catch (error) {
                console.error('Error resetting question bank data:', error);
                setIncrementError(error);
            } finally {
                setIncrementLoading(false);
            }
        };

        incrementSubscription();
    }, [token, incrementLoading]); // Re-run when token or incrementLoading changes

    return { incrementSuccess, incrementLoading, incrementError };
};
