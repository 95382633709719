/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useMemo, useState } from 'react'
import { useFetchSessionList } from '../../../../../components/hooks/MSRA/fetches/FetchSessionList'
import { Button } from '../../../../../components/shadcn/ui/button'
import SpinLoader from '../../../../../components/loader/SpinLoader'
import { useSprings } from 'react-spring'
import SessionItem from '../../../../../components/common/SessionItem'
import PaginationControls from '../../../../../components/common/PaginationControls'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import GoBackButton from '../../../../../components/other_ui/GoBackButton'


function TrialPDAllSessionSummary() {
  const [sessionList, setSessionList] = useState([]);
  const [sessionLoading, setSessionLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 4;
  const totalSessions = sessionList.length;
  const totalPages = Math.ceil(totalSessions / pageSize);
  const navigate = useNavigate()

  const [token, setToken] = useState(null)
  const [tokenLoading, setTokenLoading] = useState(true)
  const { getAccessTokenSilently, isLoading: authLoading } = useAuth0()

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setToken(token);
      } catch (error) {
        console.error('Error fetching token:', error);
      } finally {
        setTokenLoading(false);
      }
    };
    
    fetchToken();
  }, [getAccessTokenSilently]);


  const { sessionList: data, loading, error } = useFetchSessionList(5, token, "MSRA Free Trial");
  const allLoading = loading || tokenLoading || sessionLoading

  useEffect(() => {
    if (data) {
      const sessions = data.sessions;
      if (sessions) {
        // Sort sessions by end_time in descending order
        const sortedSessions = sessions.sort((a, b) => new Date(b.end_time) - new Date(a.end_time));
        setSessionList(sortedSessions.map(session => ({ ...session, isOpen: false })));
      }
    }
    setSessionLoading(false);
  }, [data]);
  

  const paginatedSessions = useMemo(() => {
    const start = (currentPage - 1) * pageSize;
    return sessionList.slice(start, start + pageSize);
  }, [currentPage, sessionList]);


  const toggleItems = localIndex => {
    setSessionList(prev => prev.map((session, idx) => idx === localIndex ? { ...session, isOpen: !session.isOpen } : session));
  };
  

  const springs = useSprings(
    sessionList.length,
    sessionList.map(session => ({
      to: {
        opacity: session.isOpen ? 1 : 0,
        maxHeight: session.isOpen ? '1000px' : '0px',
        overflow: 'hidden',
        transform: session.isOpen ? 'translateY(0)' : 'translateY(-20px)'
      },
      from: {
        opacity: 0,
        maxHeight: '0px',
        overflow: 'hidden',
        transform: 'translateY(-20px)'
      },
      config: key => ({
        tension: 170,
        friction: 26,
      })
    }))
  );

  if (allLoading) return <SpinLoader />;


  return (
    <>
      <GoBackButton 
        navigateTo='/trial-MSRA/home'
        altText='Free Trial MSRA Home Page'
      />
      <div className="flex flex-col items-center mt-2 sm:mt-10">
        <h5 className={`${totalPages < 2 && 'mb-8'} text-center sm:text-left mx-2 text-2xl sm:text-3xl font-bold tracking-tight text-gray-900 dark:text-white`}>My Professional Dillemmas Free Trial Revision Sessions</h5>
        {totalPages > 1 && (
          <div className="my-2">
            <PaginationControls currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
          </div>
        )}
        {paginatedSessions.length > 0 ? (
        <ul className="w-full flex flex-col items-center">
          {paginatedSessions && paginatedSessions.map((session, index) => {
            const globalIndex = (currentPage - 1) * pageSize + index; // Calculate the global index
            return <SessionItem key={globalIndex} session={session} index={globalIndex} toggleItems={toggleItems} style={springs[globalIndex]} paperType={'PD Trial'} />;
          })}
        </ul>
        ) : (
            <div className={`w-11/12 lg:w-1/2 md:w-4/5 mb-4 p-6 bg-white border border-gray-200 shadow dark:bg-gray-800 dark:border-gray-700 rounded-lg overflow-visible`}>
            <div className="flex flex-col md:flex-row gap-4 md:gap-0 justify-between items-center">
              <h5 className="text-lg sm:text-xl font-semibold tracking-tight text-gray-900 dark:text-white">You have not completed any sessions yet for the Professional Development paper.</h5>
              <Button onClick={() => navigate('/trial-MSRA/PD/start-session')}>
                Start a session
                <svg className="ml-2 w-3.5 h-3.5" aria-hidden="true" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Button>
              </div>
            </div>
          )}
      </div>
    </>
  )
}

export default TrialPDAllSessionSummary