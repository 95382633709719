import { useAuth0 } from '@auth0/auth0-react';
import { useFetchMSRASessionData } from '../../../../../components/hooks/MSRA/fetches/FetchActiveMSRASessionData';
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { 
  fetchUserQuestionStates,
  fetchSessionQuestions,
  enrichQuestionsWithDetails,
  fetchQuestionAttempts,
  fetchQuestionAttemptStats,
} from '../../../../../components/hooks/MSRA/helper_functions/cpsFetches'
import SpinLoader from '../../../../../components/loader/SpinLoader';
import { RLDisplay } from '../../../../../components/hooks/MSRA/question_displays/RLDisplay';
import { MCQDisplay } from '../../../../../components/hooks/MSRA/question_displays/MCQDisplay';
import { Card } from '../../../../../components/shadcn/ui/card';
import { Button } from '../../../../../components/shadcn/ui/button';
import { CircleArrowLeft, CircleArrowRight } from 'lucide-react';
import { Separator } from '../../../../../components/shadcn/ui/separator';
import FormattedQuestionList from '../../../../../components/session_navigation/MSRA/sessionNavigator';
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '../../../../../components/shadcn/ui/dialog';
import { getCsrfToken } from '../../../../../components/hooks/csrfTokenHelper';
import { AlertCircle } from 'lucide-react';
import CheckMarkAnimated from '../../../../../components/other_ui/CheckMarkAnimated';
import { handleSubmitQuestionError } from '../../../../../components/hooks/handleSubmitQuestionError';
import { Label } from '../../../../../components/shadcn/ui/label';
import { Textarea } from '../../../../../components/shadcn/ui/textarea';

function TrialPDActiveSession() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [questionsLoading, setQuestionsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [questionStates, setQuestionStates] = useState([]);
  const [questionAttempts, setQuestionAttempts] = useState({});
  const [overallScore, setOverallScore] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [userAnswer, setUserAnswer] = useState({}); // Now an object to track answers by question ID
  // eslint-disable-next-line no-unused-vars
  const [showDialog, setShowDialog] = useState(false)
  const [optionOrder, setOptionOrder] = useState();
  const [orderLoading, setOrderLoading] = useState(true)
  const [questionAttemptsLoading, setQuestionAttemptsLoading] = useState(true)
  const [questionStats, setQuestionStats] = useState()
  const [manualScore, setManualScore] = useState({})
  const [answerSubmitted, setAnswerSubmitted] = useState(false)
  const [questionErrorSuccess, setQuestionErrorSuccess] = useState(false);
  const [questionError, setQuestionError] = useState('');
  const navigate = useNavigate()
  const csrfToken = getCsrfToken();

  
  const handleOpenDialog = () => {setShowDialog(true)}

  const [token, setToken] = useState(null)
    const [tokenLoading, setTokenLoading] = useState(true)
    const { getAccessTokenSilently, isLoading: authLoading } = useAuth0()

    useEffect(() => {
      const fetchToken = async () => {
        try {
          const token = await getAccessTokenSilently();
          setToken(token);
        } catch (error) {
          console.error('Error fetching token:', error);
        } finally {
          setTokenLoading(false);
        }
      };
      
      fetchToken();
    }, [getAccessTokenSilently]);

  const { data, loading, error } = useFetchMSRASessionData(5, token, "MSRA Free Trial")
  const currentQuestion = questions.length > 0 ? questions.find(q => q.id === data.questions[currentQuestionIndex]) : null;

  const allLoading = loading || questionsLoading || orderLoading || questionAttemptsLoading || authLoading || tokenLoading

  useEffect(() => {
    let newOptionsOrder = {};
    if (questions && !questionAttemptsLoading){
    questions.forEach(question => {
        if (question && question.options) {
            if (questionAttempts[question.id] && questionAttempts[question.id].user_answer) {
                const orderedOptions = questionAttempts[question.id].user_answer.map(index => question.options[index]);
                newOptionsOrder[question.id] = orderedOptions.map(option => {
                    const originalIndex = question.options.findIndex(o => o.id === option.id);
                    const label = String.fromCharCode(65 + originalIndex);
                    return { ...option, label };
                });
                setOrderLoading(false)
            } else {
                newOptionsOrder[question.id] = question.options.map(option => {
                    const originalIndex = question.options.findIndex(o => o.id === option.id);
                    const label = String.fromCharCode(65 + originalIndex);
                    return { ...option, label }                        
                })
                setOrderLoading(false)
            }
            setOptionOrder(newOptionsOrder);
        }
    });
    } else return

}, [questions, questionAttempts]);    

useEffect(() => {
  if (!data || !data.id) return;

  const fetchData = async () => {
      setQuestionsLoading(true);

      try {
          const questions = await fetchSessionQuestions(data.id, token);
          const questionsWithDetails = await enrichQuestionsWithDetails(questions, token);
          setQuestions(questionsWithDetails);

          const attempts = await fetchQuestionAttempts(data.id, token);
          const attemptsByQuestionId = {};
          const newUserAnswers = {};

          attempts.forEach(attempt => {
              attemptsByQuestionId[attempt.question] = attempt;
          });

          questionsWithDetails.forEach(question => {
              if (question.question_type === 4 && !attemptsByQuestionId[question.id]) {
                  newUserAnswers[question.id] = question.options.map((_, index) => index);
              }
          });

          setUserAnswer(newUserAnswers);
          setQuestionAttempts(attemptsByQuestionId);
      } catch (error) {
          console.error('Failed to fetch session data:', error);
      } finally {
          setQuestionsLoading(false);
          setQuestionAttemptsLoading(false);
      }
  };

  fetchData();
}, [data, token]); 

useEffect(() => {
  if (!data || !data.id) return;

  fetchQuestionAttemptStats(data.id, token)
      .then(stats => {
          setQuestionStats(stats); // Store stats in state
      })
      .catch(error => {
          console.error("Failed to fetch question stats", error);
      });
}, [data, token]); // Depends on 'data'

useEffect(() => {
  if (!data || !data.id) return;

  fetchUserQuestionStates(data.id, token)
      .then(questionStatesData => {
          setQuestionStates(questionStatesData);
      })
      .catch(error => {
          console.error("Failed to fetch question states", error);
      });
}, [data, token]);

useEffect(() => {
  const updateQuestionState = async () => {
      if (data && data.questions && data.questions.length > 0) {
          const currentQuestionId = data.questions[currentQuestionIndex];
          const currentQuestionState = questionStates.find(state => state.question_details.id === currentQuestionId);
          // Check if the current question's state has not been viewed yet
          if (currentQuestionState && !currentQuestionState.viewed) {
              const response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/MSRA/user-session/question-states/${currentQuestionState.id}/`, {
                  method: 'PATCH',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}`,
                      "X-CSRFToken": csrfToken
                  },
                  credentials: "include",
                  body: JSON.stringify({ viewed: true }),
              });

              if (response.ok) {
                  // Update the local state to reflect the change
                  setQuestionStates(prevStates => prevStates.map(state => state.id === currentQuestionState.id ? { ...state, viewed: true } : state));
              } else {
                  console.error("Failed to update question state");
              }
          }
      }
  };

  updateQuestionState();
}, [currentQuestionIndex, data, data.questions, questionStates, token]);

useEffect(() => {
  let totalScore = 0
  let totalPossiblePoints = 0

  if(Object.keys(questionAttempts).length > 0){
      Object.values(questionAttempts).forEach(attempt => {
          totalScore += attempt.points_scored;
          totalPossiblePoints += attempt.total_points;
      });

      setOverallScore(totalScore)
      setTotalPoints(totalPossiblePoints)
  }
}, [questionAttempts])
  
useEffect(() => {
  if(data && questions && data.questions && data.questions[currentQuestionIndex]){
      if(questionAttempts && questionAttempts[data.questions[currentQuestionIndex]]) return
      else{
          const currentQuestionId = data.questions[currentQuestionIndex]
          const currentQuestion = questions.find(q => q.id === currentQuestionId)
          if(currentQuestion.question_type === 4){
              setManualScore(prevScore => ({
                  ...prevScore,
                  [currentQuestionId]: scoreRLQuestion(userAnswer[currentQuestionId], currentQuestion.rl_answer),
              }));
          } else if (currentQuestion.question_type === 1 && userAnswer[currentQuestionId]){
              setManualScore(prevScore => ({
                  ...prevScore,
                  [currentQuestionId]: scoreMCQQuestion(userAnswer[currentQuestionId], currentQuestion.mcq_answer)
              }))
          }
      }
  }
  
}, [userAnswer, answerSubmitted, questionAttempts])


function scoreRLQuestion(userRanks, correctRanks) {
  const scoringMatrix = correctRanks.map((correctRank, correctIdx) => {
      return correctRanks.map((_, userIdx) => {
          // Assign points based on the position difference
          return Math.max(4 - Math.abs(correctIdx - userIdx), 0);
      });
  });

  let score = 0;
  userRanks.forEach((userRank, idx) => {
      const correctIndex = correctRanks.indexOf(userRank);
      if (correctIndex !== -1) {
          score += scoringMatrix[correctIndex][idx];
      }
  });

  return score;
}

function scoreMCQQuestion(userRanks, correctRanks) {
  let score = 0;
  let length = userRanks?.length || 5 
  // Iterate over each element in userRanks
  for (let i = 0; i < userRanks.length; i++) {
      // Check if the element is included in correctRanks
      if (correctRanks.includes(userRanks[i])) {
          score += 4;  // Increase score by 4 for each match
      }
  }
  return score;  // Return the final score
}

const goToPreviousQuestion = useCallback(() => {
  if (currentQuestionIndex > 0) {
      let newIndex = currentQuestionIndex - 1;
      setCurrentQuestionIndex(newIndex);
  }
}, [currentQuestionIndex]);

const goToNextQuestion = useCallback(() => {
  if (currentQuestionIndex < data.questions.length - 1) {
      let newIndex = currentQuestionIndex + 1;
      setCurrentQuestionIndex(newIndex);
  }
}, [currentQuestionIndex, data.questions]);

const navigateToQuestion = (questionNumber) => {
  setCurrentQuestionIndex(questionNumber)
};

const submitAnswer = async () => {
  setAnswerSubmitted(!answerSubmitted)
  const currentQuestionId = data.questions[currentQuestionIndex];
  const currentQuestionState = questionStates.find(state => state.question_details.id === currentQuestionId);
  
  if (currentQuestionState.answered) {
      return; // Stop if the question has already been answered
  }

  let userAnswerForSubmission = userAnswer[currentQuestionId]
  if (currentQuestion && currentQuestion.question_type === 2) {  // EMQ handling
      userAnswerForSubmission = currentQuestion.scenarios.map(scenario => {
          return userAnswer[currentQuestionId][scenario.id] || -1; // Default to -1 if not answered
      });
  }

  const submitData = {
      question: currentQuestionId,
      user_session: data.id,
      user_answer: userAnswerForSubmission,
  };

  try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/MSRA/user-session/user-question-attempt/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(submitData),
      });

      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      // Update the question state to reflect that it has been answered and link the new user question attempt
      await updateQuestionState(currentQuestionState.id, true, result.id);
  } catch (error) {
      console.error('Failed to submit user answer:', error);
  }
};

const updateQuestionState = async (questionStateId, answeredStatus, userQuestionAttemptId) => {
  try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/MSRA/user-session/question-states/${questionStateId}/`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ answered: answeredStatus, user_question_attempt: userQuestionAttemptId }),
      });

      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Assuming you have a way to fetch or re-fetch the state data; adjust accordingly
      setQuestionStates(prevStates => prevStates.map(state => 
          state.id === questionStateId ? { ...state, answered: answeredStatus, user_question_attempt: userQuestionAttemptId } : state
      ));
  } catch ( error ) {
      console.error("Failed to update question answered status:", error);
  }
};

const handleKeyDown = useCallback((event) => {
  if (event.key === 'ArrowRight') {
      goToNextQuestion();
  } else if (event.key === 'ArrowLeft') {
      goToPreviousQuestion();
  }
}, [goToNextQuestion, goToPreviousQuestion]);

// Add event listener on component mount and cleanup on unmount
useEffect(() => {
  window.addEventListener('keydown', handleKeyDown);

  return () => {
      window.removeEventListener('keydown', handleKeyDown);
  };
}, [handleKeyDown]);

const endSession = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/MSRA/user-session/user-session/${data.id}/end/`, {
          method: 'PATCH',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          },
      });

      if (response.ok) {
          navigate(`/trial-MSRA/session-summary/PD/${data.id}`)
          // Handle successful session ending, e.g., redirecting the user or updating UI
      } else {
          console.error('Failed to end session.');
          // Handle errors, e.g., by showing an error message
      }

};

const handleOptionMove = useCallback((questionId, dragIndex, hoverIndex) => {
  setUserAnswer(prevAnswers => {
      const currentOptions = prevAnswers[questionId] 
      const newOptions = [...currentOptions];
      const dragItem = newOptions.splice(dragIndex, 1)[0];
      newOptions.splice(hoverIndex, 0, dragItem);
      return {
          ...prevAnswers,
          [questionId]: newOptions  // Update user answer with new order of indices
      };
  });
  setOptionOrder(prevOrder => {
      const currentOptions = prevOrder[questionId]
      const newOptions = [...currentOptions]
      const dragItem = newOptions.splice(dragIndex, 1)[0]
      newOptions.splice(hoverIndex, 0, dragItem)
      return {
          ...prevOrder,
          [questionId]: newOptions
      }
  })
}, []);

const handleUserAnswerChange = useCallback((questionId, answer) => {
  setUserAnswer(prevAnswers => ({
      ...prevAnswers,
      [questionId]: answer,
  }));
}, []);

if (allLoading || !currentQuestion) return <SpinLoader />;
if (error) return <p>Error loading revision options: {error.message}</p>;
if (!data || !data.questions || data.questions.length === 0) {
    return <div>No questions available.</div>;  // Handle cases where no questions data is available
}
const currentQuestionId = data.questions[currentQuestionIndex];
const currentQuestionState = questionStates.find(qs => qs.question_details.id === currentQuestionId);
const OptionsComponent = currentQuestion && currentQuestion.question_type === 4 ? RLDisplay : MCQDisplay;

  return (
    <div>
        <div className="flex flex-col justify-center lg:flex-row mx-auto p-1 md:p-4 lg:p-10 gap-8">
            <Card className="flex flex-col w-full lg:w-[50vw] md:p-8 p-2">
                <div className="flex items-center justify-between mb-4">
                    <Button 
                        variant='outline' 
                        className={`hidden md:block ${currentQuestionIndex === 0 ? 'invisible' : ''}`} 
                        onClick={goToPreviousQuestion}
                        >
                        Previous
                    </Button>
                    <CircleArrowLeft
                        className={`block md:hidden ${currentQuestionIndex === 0 ? 'invisible' : ''}`}
                        onClick={goToPreviousQuestion}
                        />
                    <h5 className='text-xl font-bold'>
                        Question {currentQuestionIndex + 1} of {questions.length}
                    </h5>
                    <CircleArrowRight
                        className={`block md:hidden ${(currentQuestionIndex === data.questions.length - 1) ? 'invisible' : ''}`}
                        onClick={goToNextQuestion}
                    />
                    <Button
                        variant='outline' 
                        className={`hidden md:block ${(currentQuestionIndex === data.questions.length - 1) ? 'invisible' : ''}`} 
                        onClick={goToNextQuestion}
                        >
                        Next
                    </Button>
                </div>
                <Separator orientation='horizontal' />
                <div className="mb-4">
                    <OptionsComponent
                        questions={currentQuestion}
                        isAnswered={currentQuestionState?.answered}
                        questionType={currentQuestion && currentQuestion.question_type}
                        userAnswer={userAnswer[currentQuestionId]}
                        fetchedUserAnswer={questionAttempts[currentQuestionId]}
                        optionOrder={optionOrder[currentQuestionId]}
                        moveOption={(dragIndex, hoverIndex) => handleOptionMove(currentQuestionId, dragIndex, hoverIndex)}
                        manualScore={manualScore}
                        questionStats={questionStats.questionId[currentQuestionId]}
                        onUserAnswerChange={(answer) => handleUserAnswerChange(currentQuestionId, answer)}
                    />
                </div>
                <div className="flex flex-col md:flex-row md:justify-between">
                    <div className='hidden md:inline'>
                        <Dialog>
                            <DialogTrigger asChild>
                                <Button className='text-sm items-center' variant={'link'}>
                                    <AlertCircle size={16} />&nbsp; Report a problem with this question
                                </Button>
                            </DialogTrigger>
                            <DialogContent>
                                {!questionErrorSuccess ? (
                                <>
                                    <DialogHeader>
                                        <DialogTitle>
                                            Please fill out some brief information on the error:
                                        </DialogTitle>
                                        <DialogDescription className='text-xs italic'>
                                            Question ID: {currentQuestionId}
                                        </DialogDescription>
                                    </DialogHeader>
                                    <form onSubmit={(e) => handleSubmitQuestionError(e, questionError, null, null, currentQuestionId, setQuestionError, token, csrfToken, questionErrorSuccess, setQuestionErrorSuccess)}>
                                        <div className='mb-4'>
                                            <Label htmlFor='questionError'>Please summarize the error:</Label>
                                            <Textarea
                                                id="questionError"
                                                value={questionError}
                                                onChange={(e) => setQuestionError(e.target.value)}
                                                required
                                                placeholder="Describe the error here..."
                                                />
                                        </div>
                                        <Button className='w-full' type='submit'>Submit</Button>
                                    </form>
                                </>
                                ) : (
                                    <>
                                        <CheckMarkAnimated page="Question Error" />
                                    </>
                                )}
                            </DialogContent>
                        </Dialog>
                    </div>
                    <div className="flex flex-col md:flex-row md:justify-between">
                        <Button className={`${currentQuestionState?.answered ? 'hidden' : ''}`}
                            onClick={submitAnswer}
                            disabled={currentQuestionState?.answered} // Disable button if question is answered
                        >
                            Submit Answer
                        </Button>
                    </div>
                    <div className='inline md:hidden'>
                        <Dialog>
                            <DialogTrigger asChild>
                                <Button className='text-sm items-center' variant={'link'}>
                                    <AlertCircle size={16} />&nbsp; Report a problem with this question
                                </Button>
                            </DialogTrigger>
                            <DialogContent>
                                {!questionErrorSuccess ? (
                                <>
                                    <DialogHeader>
                                        <DialogTitle>
                                            Please fill out some brief information on the error:
                                        </DialogTitle>
                                        <DialogDescription className='text-xs italic'>
                                            Question ID: {currentQuestionId}
                                        </DialogDescription>
                                    </DialogHeader>
                                    <form onSubmit={(e) => handleSubmitQuestionError(e, questionError, null, null, currentQuestionId, setQuestionError, token, csrfToken, questionErrorSuccess, setQuestionErrorSuccess)}>
                                        <div className='mb-4'>
                                            <Label htmlFor='questionError'>Please summarize the error:</Label>
                                            <Textarea
                                                id="questionError"
                                                value={questionError}
                                                onChange={(e) => setQuestionError(e.target.value)}
                                                required
                                                placeholder="Describe the error here..."
                                                />
                                        </div>
                                        <Button className='w-full' type='submit'>Submit</Button>
                                    </form>
                                </>
                                ) : (
                                    <>
                                        <CheckMarkAnimated page="Question Error" />
                                    </>
                                )}
                            </DialogContent>
                        </Dialog>
                    </div>
                </div>
            </Card>
            <div>
                <Card className="w-full lg:min-w-[17vw] p-4">
                    <FormattedQuestionList 
                        expandedQuestionOrder={data.questions} 
                        questionStates={questionStates} 
                        questionAttempts={questionAttempts}
                        navigateToQuestion={navigateToQuestion}
                        localUserAnswer={userAnswer}
                        currentQuestionIndex={currentQuestionIndex}
                        allQuestions={questions}
                        totalPoints={totalPoints} 
                        overallScore={overallScore}
                        manualScore={manualScore}
                    />
                    <div className="flex">
                        <Dialog>
                            <DialogTrigger asChild>
                                <Button onClick={handleOpenDialog} className="mx-auto">End Session</Button>
                            </DialogTrigger>
                            <DialogContent>
                                <DialogHeader>
                                <DialogTitle>End session?</DialogTitle>
                                <DialogDescription>
                                    By ending the session, all progress will be saved and it will become locked. You will be able to review your answers but not change them.
                                </DialogDescription>
                                </DialogHeader>
                                <Button className="mx-auto" onClick={endSession}>End Session</Button>
                            </DialogContent>
                        </Dialog>
                    </div>
                </Card>
            </div>
        </div>
    </div>
  )
}

export default TrialPDActiveSession