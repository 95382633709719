import { useState, useEffect } from 'react';
import { getCsrfToken } from '../../../../../components/hooks/csrfTokenHelper';

export const useFetchSingleTextbookItem = (textbookId, token) => {
    const [textbookItem, setTextbookItem] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSingleTextbookItem = async () => {
            if(!token) return
            if(textbookId === 'home') {
                setLoading(false)
                return
            }
            try {
                const csrfToken = getCsrfToken();

                let response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/textbook/single-textbook/${textbookId}/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "X-CSRFToken": csrfToken,
                    }
                });

                if (!response.ok) throw new Error('Failed to fetch textbook items');

                const data = await response.json();
                setTextbookItem(data);
            } catch (error) {
                console.error('Error fetching textbook item:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchSingleTextbookItem();
    }, [textbookId, token]);

    return { textbookItem, loading, error };
};
