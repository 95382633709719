import { useState, useEffect } from 'react';
import { getCsrfToken } from '../../../../../components/hooks/csrfTokenHelper';

export const useFetchTextbookByExam = (examId, token) => {
    const [textbookItems, setTextbookItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTextbookByExam = async () => {
            if(!token) return
            try {
                const csrfToken = getCsrfToken();
                let response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/textbook/by-exam/${examId}/simplified/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "X-CSRFToken": csrfToken,
                    }
                });

                if (!response.ok) throw new Error('Failed to fetch textbook items');

                const data = await response.json();
                setTextbookItems(data);
            } catch (error) {
                console.error('Error fetching textbook items:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchTextbookByExam();
    }, [token]);

    return { textbookItems, loading, error };
};
