import { useState, useEffect } from 'react';
import { getCsrfToken } from '../../csrfTokenHelper';

export const useFetchSessionList = (paperId, token, exam_name) => {
    const [sessionList, setSessionList] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!token) {
            return; // Exit the useEffect if the token is not yet available
        }
        const fetchSessionList = async () => {
            try {
                const csrfToken = getCsrfToken();
                let response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/MSRA/user-session/user-session-list/?paper_id=${paperId}&exam_name=${exam_name}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "X-CSRFToken": csrfToken
                    }
                });


                if (!response.ok) throw new Error('Failed to fetch subscriptions');

                const data = await response.json();
                setSessionList(data);
            } catch (error) {
                console.error('Error fetching session list:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchSessionList();
    }, [paperId, token]);

    return { sessionList, loading, error };
};
