import React from 'react'
import {Card, CardHeader, CardTitle, CardDescription, CardContent} from '..//shadcn/ui/card'
import { Button } from '../shadcn/ui/button'
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '../shadcn/ui/dialog'
import HeaderMedium from '../typography/HeaderMedium'

const StartSessionCard = ({selectedQuestions, existingSession, handleOpenDialog, handleResumeSession, handleSubmit }) => {
  return (
    <div className="flex lg:sticky top-4  flex-col lg:flex-row justify-center items-center  space-y-4 lg:space-y-0 lg:space-x-4">
        <div className="items-center text-center">
            <div className="mb-2">
                Total Eligible Questions: <span className="font-medium">{selectedQuestions}</span>
                <p className='text-muted-foreground text-sm'>There is a maximum of 100 questions per session.</p>
            </div>
            {existingSession.id ? (
                <Dialog>
                <DialogTrigger asChild>
                    <Button disabled={selectedQuestions === 0} onClick={handleOpenDialog}>Start Revision session</Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                    <DialogTitle>Existing Session Detected</DialogTitle>
                    <DialogDescription>
                        You already have an active session. Creating a new one will end the existing one--your progress will be saved. Would you like to resume the existing session or start a new one?
                    </DialogDescription>
                    </DialogHeader>
                    <div className="flex justify-between p-4">
                    <Button variant='outline' onClick={handleResumeSession}>Resume Session</Button>
                    <Button onClick={handleSubmit}>Start New Session</Button>
                    </div>
                </DialogContent>
                </Dialog>
                ) : (
                    <>
                        <Button disabled={selectedQuestions === 0} onClick={handleSubmit}>Start Session</Button>
                    </>
            )}
        </div>
    </div>  
)}

export default StartSessionCard