import { useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { getCsrfToken } from '../csrfTokenHelper';

const useGrantTrialSubscription = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [success, setSuccess] = useState(false)
    const csrfToken = getCsrfToken();

    const navigate = useNavigate()

    const grantTrialSubscription = async (examId) => {
        setLoading(true);
        setError(null);
        const today = new Date();
        const startDate = today.toISOString().split('T')[0];
        // const endDate = new Date(today.setMonth(today.getMonth() + subDuration)).toISOString().split('T')[0];
        
        try {
            const token = await getAccessTokenSilently();
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}custom_api/subscriptions/create_trial_subscription/`,
                {
                    exam_id: examId,
                    start_date: startDate,
                    end_date: "2026-01-01",
                    is_active: true
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "X-CSRFToken": csrfToken,  // Add CSRF token to headers
                    }
                }
            );
            const checkoutUrl = response.data.url;
            if (checkoutUrl) {
                // Redirect the user to the Stripe Checkout page
                window.location.href = checkoutUrl;
            } else {
                throw new Error("Failed to retrieve checkout URL");
            }
        } catch (err) {
            setError(err.response ? err.response.data : 'Something went wrong');
        } finally {
            setSuccess(true)
            setLoading(false);
        }
    }

  return { grantTrialSubscription, data, loading, error, success }
}

export default useGrantTrialSubscription