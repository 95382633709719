import { useSpring } from 'react-spring';

export function useDropdownAnimation(isOpen) {
  const animation = useSpring({
    opacity: isOpen ? 1 : 0,
    maxHeight: isOpen ? '500px' : '0px', // Adjust this based on your content size
    overflow: 'hidden',
    transform: isOpen ? 'translateY(0)' : 'translateY(-20px)'
  });

  return animation;
}
