import { useState } from 'react';
import axios from 'axios';

/**
 * Custom hook to grant a free subscription by interacting with the Django API.
 *
 * @param {string} csrfToken - The CSRF token for secure requests.
 * @param {string} token - The JWT bearer token for authentication.
 * @returns {object} - Object containing the subscription creation status, loading state, error, and a function to grant a subscription.
 */
const useGrantFreeSubscription = (csrfToken, token) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const grantFreeSubscription = async () => {
        setLoading(true);
        setError(null);
        setSuccess(null);
        const today = new Date().toISOString().split('T')[0]; // Today's date in YYYY-MM-DD format
        const marchFirst2025 = new Date(2025, 2, 1).toISOString().split('T')[0]; // March 1st, 2025
    
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}custom_api/subscriptions/free_subscription/`,
                {
                    exam_id: 1,
                    start_date: today,
                    end_date: marchFirst2025,
                    is_active: true,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'X-CSRFToken': csrfToken,
                        'Content-Type': 'application/json',
                    },
                }
            );

            setSuccess(response.data);
            return response.data; // Return the success response to the caller
        } catch (err) {
            console.error('Error granting free subscription:', err);
            setError(err.response ? err.response.data : err.message);
        } finally {
            setLoading(false);
        }
    };

    return { grantFreeSubscription, loading, error, success };
};

export default useGrantFreeSubscription;
