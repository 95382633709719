import React from 'react';
import { NavLink } from 'react-router-dom';
import { Input } from '../shadcn/ui/input';
import { Separator } from '../shadcn/ui/separator';
import { ChevronDown, ChevronUp } from 'lucide-react'; 

const TextbookSideBar = ({ textbookItems, navigate, setSearchTerm, searchTerm, exam }) => {

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    textbookItems.sort((a, b) => a.title.localeCompare(b.title));

    const filteredItems = textbookItems.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const categoryMap = textbookItems.reduce((acc, item) => {
        (Array.isArray(item.categories) ? item.categories : []).forEach(cat => {
          if (!acc[cat]) acc[cat] = [];
          acc[cat].push(item);
        });
        (Array.isArray(item.subcategories) ? item.subcategories : []).forEach(subcat => {
          if (!acc[subcat]) acc[subcat] = [];
          acc[subcat].push(item);
        });
        return acc;
      }, {});
      

    function Collapsible({ label, children, isLast }) {
        const [isOpen, setIsOpen] = React.useState(false);
        const contentRef = React.useRef(null);
    
        // Inline styles for animation
        const iconStyle = {
            transition: 'transform 0.3s ease',
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)'
        };

        const contentStyle = {
            maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : '0',
            transition: 'max-height 0.3s ease-in-out',
            overflow: 'hidden'
        };
        
        return (
            <div>
                <div 
                    onClick={() => setIsOpen(!isOpen)} 
                    className="flex items-center justify-between cursor-pointer p-2 my-1 text-sm text-black dark:text-white hover:bg-gray-200 dark:hover:bg-black/20 rounded"
                >
                    <span>
                        <span className="font-bold">{label}</span> <span className="text-xs italic">({children.length})</span>
                    </span>
                    <div className="flex-shrink-0 ml-2" style={iconStyle}>
                        <ChevronDown size={16} />
                    </div>
                </div>
                <div ref={contentRef} style={contentStyle}>
                    {children}
                </div>
                {!isLast && <Separator orientation='horizontal' />}
            </div>
        );
    }
      

    function SearchIcon(props) {
        return (
          <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="11" cy="11" r="8" />
            <path d="m21 21-4.3-4.3" />
          </svg>
        )
      }

      const sortedCategories = Object.keys(categoryMap).sort()

    return (
        <>
        <aside className="w-64 hidden md:inline md:min-h-[calc(100vh-72px)] min-h-[calc(100vh-62px)] overflow-y-auto border-r px-5">
            <div className="pt-4">
                <div className="relative flex-1">
                    <SearchIcon className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-500 dark:text-gray-400" />
                    <Input
                        className="w-full rounded-md bg-gray-100 px-8 py-2 text-sm shadow-none focus:bg-gray-200 dark:bg-gray-800 dark:focus:bg-gray-700"
                        placeholder="Search..."
                        type="search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
            </div>
            <div className="py-2">
                <Separator orientation='horizontal' />            
            </div>
            {searchTerm.trim() ? (
                filteredItems.map(item => (
                    <>
                        <NavLink 
                            key={item.id} 
                            to={exam === "MSRA Free Trial" ? `/trial-MSRA/textbook/${item.id}` : `/MSRA/textbook/${item.id}`}
                            className={({ isActive }) =>
                                `block p-2 my-1 text-sm hover:bg-gray-200 dark:hover:bg-black/20 rounded ${
                                isActive ? "bg-gray-200 dark:bg-black/20 font-semibold" : ""
                                }`
                            }
                        >
                            <span className="ml-2">{item.title}</span>
                        </NavLink>
                        <Separator orientation="horizontal"/>
                    </>
                ))
            ) : (
                <>
                <div className="text-center font-bold mt-1 mb-2">Topics</div>
                <Separator orientation="horizontal"/>
                {sortedCategories.map((category, index) => (
                    <Collapsible label={category} key={category} isLast={index === sortedCategories.length - 1}>
                        {categoryMap[category].map(item => (
                            <NavLink key={item.id} to={exam === "MSRA Free Trial" ? `/trial-MSRA/textbook/${item.id}` : `/MSRA/textbook/${item.id}`}
                            className={({ isActive }) =>
                                `block p-2 my-1 text-sm hover:bg-gray-200 dark:hover:bg-black/20 rounded ${
                                isActive ? "bg-gray-200 dark:bg-black/20 font-semibold" : ""
                                }`
                            }>
                            <span className="ml-2">{item.title}</span>
                        </NavLink>
                        ))}
                    </Collapsible>
                ))}
                </>
            )}
        </aside>
        </>
    );
};

export default TextbookSideBar;
