import React, { useState, useEffect } from 'react';
import { getCsrfToken } from '../../csrfTokenHelper';
import { useAuth0 } from '@auth0/auth0-react';

function useFetchUserDetailsCheck() {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchUserDetailsCheck = async () => {
            try {
                const token = await getAccessTokenSilently();
                const csrfToken = getCsrfToken();

                let response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/users/user-data/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "X-CSRFToken": csrfToken
                    }
                });

                if (!response.ok) throw new Error('Failed to fetch user details.');

                const data = await response.json();
                setEmail(data.email);
                setFirstName(data.first_name);
                setLastName(data.last_name);
            } catch (error) {
                console.error(`Error fetching user details: ${error}`);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        // Call the function
        fetchUserDetailsCheck();
        
    }, [getAccessTokenSilently]);  // Add the dependency array to control when useEffect runs

    return { email, firstName, lastName, error, loading };
}

export default useFetchUserDetailsCheck;
