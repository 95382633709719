import React from 'react'

const HeaderMedium = ({text, blackText, mx, underline, my}) => {
  return (
    <h3 className={`${blackText ? 'text-black dark:text-white' : ''} 
        ${mx && `mx-${mx}`} 
        ${my && `my-${my}`} 
        ${underline && 'underline'}
        scroll-m-20 text-3xl font-bold tracking-tight`}>
        {text}
    </h3>
  )
}

export default HeaderMedium