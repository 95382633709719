'use client'

import { useState, useEffect } from 'react'
import { X } from 'lucide-react'
import { Button } from '../shadcn/ui/button'
import { Card, CardContent, CardFooter } from '../shadcn/ui/card'
import { useNavigate } from 'react-router-dom'

export function CookiePolicyBanner({ onAccept, onReject }) {
  const [isVisible, setIsVisible] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    const consent = localStorage.getItem('cookie-consent')
    if (!consent) {
      setIsVisible(true)
    }
  }, [])

  const handleAccept = () => {
    localStorage.setItem('cookie-consent', 'accepted')
    setIsVisible(false)
    onAccept() // Trigger the accept callback
  }

  const handleReject = () => {
    localStorage.setItem('cookie-consent', 'rejected')
    setIsVisible(false)
    onReject() // Trigger the reject callback
  }

  const handleClose = () => {
    setIsVisible(false)
  }

  if (!isVisible) return null

  return (
    <Card className="fixed bottom-4 left-4 right-4 z-50 max-w-2xl mx-auto">
      <CardContent className="pt-6">
        <button
          onClick={handleClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
          aria-label="Close"
        >
          <X size={20} />
        </button>
        <h2 className="text-lg font-semibold mb-2">We value your privacy</h2>
        <p className="text-sm text-gray-600 mb-4">
            This website uses essential cookies for its core functionality. We also use non-essential cookies (Google Analytics) to help us understand where our users are visiting from. You can choose to accept or reject non-essential cookies below.
        </p>
        <a
          href="/privacy-policy"
          className="text-sm text-primary hover:underline"
        >
          Read our Privacy Policy
        </a>
      </CardContent>
      <CardFooter className="flex justify-end space-x-2">
        <Button variant="outline" onClick={handleReject}>
          Reject All
        </Button>
        <Button onClick={handleAccept}>Accept All</Button>
      </CardFooter>
    </Card>
  )
}
