import { useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

function useFetchUserSubscriptions() {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const fetchUserSubscriptions = async () => {
    setLoading(true);
    setError(null);
    // Function to get the CSRF token from cookies
    const getCookie = (name) => {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          // Check if the cookie starts with the name we are looking for
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    };
  
    // Get the CSRF token from the cookie
    const csrfToken = getCookie('csrftoken');

    try {
      const token = await getAccessTokenSilently();
      // const response = await axios.get('http://localhost:8000/subscriptions/my_subscriptions/', {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}custom_api/subscriptions/my_subscriptions/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,  // Add CSRF token to headers
        }
      });
      setData(response.data);
    } catch (err) {
      setError(err.response ? err.response.data : 'Something went wrong');
    } finally {
      setLoading(false);
    }
  }

  return { fetchUserSubscriptions, data, loading, error };
}

export default useFetchUserSubscriptions;
