import { useState, useEffect } from 'react';
import { getCsrfToken } from '../../csrfTokenHelper';

export const useFetchUserMSRAPerformance = (token, exam_name) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cpsPaper, setCpsPaper] = useState(null);
    const [pdPaper, setPdPaper] = useState(null);

    useEffect(() => {
        if (!token || !exam_name) {
            return; 
        }
        const fetchPerformance = async () => {

            try {
                const csrfToken = getCsrfToken();

                let response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/MSRA/user/analytics/user_performance/?exam_name=${encodeURIComponent(exam_name)}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "X-CSRFToken": csrfToken,  
                    }
                });
                if (!response.ok) throw new Error('Failed to fetch user performance');

                const data = await response.json();
                setData(data);

                // Extract cpsPaper and pdPaper data
                const cpsPaperData = data.find(item => item.papers.some(paper => paper.name === 'Clinical Problem Solving'));
                const pdPaperData = data.find(item => item.papers.some(paper => paper.name === 'Professional Dilemmas'));

                setCpsPaper(cpsPaperData ? cpsPaperData.papers.find(paper => paper.name === 'Clinical Problem Solving') : null);
                setPdPaper(pdPaperData ? pdPaperData.papers.find(paper => paper.name === 'Professional Dilemmas') : null);

            } catch (error) {
                console.error('Error fetching user performance:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchPerformance();
    }, [exam_name, token]);

    return { data, loading, error, cpsPaper, pdPaper };
};
