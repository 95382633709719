import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle, CardDescription } from '../../../../components/shadcn/ui/card'
import { Progress } from '../../../../components/shadcn/ui/progress';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/shadcn/ui/button';
import { Separator } from '../../../../components/shadcn/ui/separator';
import { useFetchMSRASessionData } from '../../../../components/hooks/MSRA/fetches/FetchActiveMSRASessionData';
import SpinLoader from '../../../../components/loader/SpinLoader';
import GoBackButton from '../../../../components/other_ui/GoBackButton';
import { useFetchActiveSubPerformance } from '../../../../components/hooks/MSRA/user_performance/FetchActiveSubPerformance';
import { useFetchQuestionCountByPaper } from '../../../../components/hooks/MSRA/fetches/FetchQuestionCountByPaper';
import MSRAResetDialog from '../../../../components/dialogs/MSRAResetDialog';
import { getCsrfToken } from '../../../../components/hooks/csrfTokenHelper';

function MSRAHomePage() {
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [incrementSuccess, setIncrementSuccess] = useState(false);
  const [incrementError, setIncrementError] = useState('')
  const [incrementLoading, setIncrementLoading] = useState(false)

  const csrfToken = getCsrfToken();
  const navigate = useNavigate()

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setToken(token);
      } catch (error) {
        console.error('Error fetching token:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchToken();
  }, [getAccessTokenSilently]);

  const { data: existingCps, loading: existingCpsLoading, error: existingCpsError } = useFetchMSRASessionData(1, token, "MSRA")
  const { data: existingPd, loading: existingPdLoading, error: existingPdError } = useFetchMSRASessionData(2, token, "MSRA")
  const { loading: cpsDataLoading , error: cpsDataError, cpsPerformance: cpsPerformanceData, cpsCoverage: cpsCoverageData, pdPerformance: pdPerformanceData, pdCoverage: pdCoverageData } = useFetchActiveSubPerformance(token, "MSRA")
  const { questionCount: cpsTotal, loading: cpsTotalLoading } = useFetchQuestionCountByPaper(1, token, "MSRA")
  const { questionCount: pdTotal, loading: pdTotalLoading } = useFetchQuestionCountByPaper(2, token, "MSRA")
  const allLoading = loading || cpsDataLoading || existingCpsLoading || existingPdLoading || isLoading || cpsTotalLoading || pdTotalLoading

  
  if (allLoading) return <SpinLoader />;

  return (
    <>
      <GoBackButton 
        navigateTo='/profile'
        altText='My Subscriptions'
        />
      <div className="container mx-auto px-4 pb-8 md:px-6 md:pb-12">
          <div className="space-y-4 mb-8">
          <h1 className="text-3xl font-bold">MSRA Question Banks</h1>
          <p className="text-muted-foreground max-w-[650px]">
            The MSRA is split into two papers: Clinical Problem Solving and Professional Dilemmas. We have split our question banks up the same way. 
          </p>
          
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <Card>
            <CardHeader>
              <CardTitle>Clinical Problem Solving</CardTitle>
              <CardDescription>{cpsTotal.total_points} Total Questions</CardDescription>
            </CardHeader>
            <CardContent className='space-y-4'>
              <div className="flex items-center justify-between">
                <div className="font-medium text-foreground">Questions Answered:</div>
                <div className='text-right'>
                  <div className="text-muted-foreground">{!cpsCoverageData || cpsCoverageData.coverage_percentage === 0 ? 0 : cpsCoverageData.coverage_percentage.toFixed(1)}% Complete</div>
                  {cpsCoverageData && cpsCoverageData.total_questions_answered > 0 && 
                    <div className="text-xs text-muted-foreground">({cpsCoverageData.total_questions_answered} / {cpsCoverageData.total_questions})</div>
                  }
                </div>
              </div>
              <Progress value={cpsCoverageData ? cpsCoverageData.coverage_percentage : 0} />
              <div className="flex items-center justify-between">
                <div className="font-medium text-foreground">Correct Answers:</div>
                <div className="text-muted-foreground">
                  {cpsCoverageData && cpsCoverageData.total_questions_answered > 0 ? `${cpsPerformanceData.performance_percentage.toFixed(1)}%` : "No questions answered yet"}
                </div>
              </div>
              <Progress value={cpsPerformanceData ? cpsPerformanceData.performance_percentage : 0} />
            </CardContent>
            <div className="inline sm:hidden">
              <Separator orientation="horizontal" className='mt-2 mb-4'/>
            </div>
            <CardFooter className='flex flex-col gap-2 sm:gap-0 sm:flex-row justify-between'>
              <Button 
                variant='link'
                onClick={() => navigate('/MSRA/all-session-summary/CPS')}
              >
                Review Previous Sessions
              </Button>
              <div className='flex flex-col sm:flex-row w-full gap-2 justify-end'>
                {existingCps.id && (
                  <Button
                    variant='outline'
                    onClick={() => navigate('/MSRA/CPS/session')}
                    className="inline-flex w-full sm:w-auto h-9 items-center justify-center"
                  >
                    Resume Session
                  </Button>              
                )}
                <Button
                  onClick={() => navigate('/MSRA/CPS/start-session')}
                  className="inline-flex w-full sm:w-auto h-9 items-center justify-center"
                >
                  Start New Session
                </Button>
              </div>
            </CardFooter>
          </Card> 
          <Card>
            <CardHeader>
              <CardTitle>Professional Dilemmas</CardTitle>
              <CardDescription>{pdTotal.total} Total Questions</CardDescription>
            </CardHeader>
            <CardContent className='space-y-4'>
              <div className="flex items-center justify-between">
                <div className="font-medium text-foreground">Questions Answered:</div>
                <div className='text-right'>
                  <div className="text-muted-foreground">{!pdCoverageData || pdCoverageData.coverage_percentage === 0 ? 0 : pdCoverageData.coverage_percentage.toFixed(1)}% Complete</div>
                  {pdCoverageData && pdCoverageData.total_questions_answered > 0 && 
                    <div className="text-xs text-muted-foreground">({pdCoverageData.total_questions_answered} / {pdCoverageData.total_questions})</div>
                  }
                </div>              
              </div>
              <Progress value={pdCoverageData ? pdCoverageData.coverage_percentage : 0} />
              <div className="flex items-center justify-between">
                <div className="font-medium text-foreground">Correct Answers:</div>
                <div className="text-muted-foreground">
                {pdCoverageData && pdCoverageData.total_questions_answered > 0 ? `${pdPerformanceData.performance_percentage.toFixed(1)}%` : "No questions answered yet"}
                </div>
              </div>
              <Progress value={pdPerformanceData ? pdPerformanceData.performance_percentage : 0} />
            </CardContent>
            <div className="inline sm:hidden">
              <Separator orientation="horizontal" className='mt-2 mb-4'/>
            </div>
            <CardFooter className='flex flex-col gap-2 sm:gap-0 sm:flex-row justify-between'>
              <Button 
                variant='link'
                onClick={() => navigate('/MSRA/all-session-summary/PD')}
              >
                Review Previous Sessions
              </Button>
              <div className='flex flex-col sm:flex-row w-full gap-2 justify-end'>

                {existingPd.id && (
                  <Button
                    variant='outline'
                    onClick={() => navigate('/MSRA/PD/session')}
                    className="inline-flex w-full sm:w-auto h-9 items-center justify-center"
                  >
                    Resume Session
                  </Button>              
                )}
                <Button
                  onClick={() => navigate('/MSRA/PD/start-session')}
                  className="inline-flex w-full sm:w-auto h-9 items-center justify-center"
                >
                  Start New Session
                </Button>
              </div>
            </CardFooter>
          </Card>
        </div>
        <MSRAResetDialog 
          incrementSuccess={incrementSuccess}
          incrementError={incrementError}
          token={token}
          csrfToken={csrfToken}
          />
      </div>
    </>
  )
  
}
export default MSRAHomePage;
