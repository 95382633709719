import React from 'react'
import { Card, CardHeader, CardTitle, CardContent } from '../../../../components/shadcn/ui/card'
import { Separator } from '../../../../components/shadcn/ui/separator'
import { LibraryBig } from 'lucide-react'

const CoverageCard = ({ CPSdata, PDdata, selected, categoryCard }) => {

  const coverageData = (selected === 'Clinical Problem Solving' || selected === 'Clinical Problem Solving Free Trial') ? CPSdata.coverage : (selected === 'Professional Dilemmas' || selected === 'Professional Dilemmas Free Trial') ? PDdata.coverage : null

  return (
    <Card>
      <CardHeader className="flex justify-between">
        <div className="flex justify-between items-center">
          <CardTitle className="text-sm font-medium">Coverage</CardTitle>
          <LibraryBig size={16} />
        </div>
        <Separator orientation="horizontal" />
      </CardHeader>
      <CardContent>
        {categoryCard ? (
        <>
          <div className="text-2xl font-bold">
            {selected.coverage.coverage_percentage.toFixed(1)}% 
            <span className="text-sm text-muted-foreground"> of questions answered</span>
          </div>
          <p className="text-xs text-muted-foreground">
            ({selected.coverage.total_questions_answered} answered / {selected.coverage.total_questions} total)
          </p>
        </>  
        ): (
        <>
          <div className="text-2xl font-bold">
            {coverageData.coverage_percentage.toFixed(1)}% 
            <span className="text-sm text-muted-foreground"> of questions answered</span>
          </div>
          <p className="text-xs text-muted-foreground">
            ({coverageData.total_questions_answered} answered / {coverageData.total_questions} total)
          </p>
        </>
        )}
      </CardContent>
    </Card>
    )
}

export default CoverageCard