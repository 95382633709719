import { ResponsivePie } from '@nivo/pie';
import '../../styles/index.css';
import { useTheme } from '../common/ThemeContext';

const PieChart = ({ data, title, dashboard, scoreChart, mobile, sessionSummary }) => {
    const { theme } = useTheme();
    // For coverage chart
    const attempted = data.find((d) => d.label === 'Attempted') ? data.find((d) => d.label === 'Attempted').value : null;
    const viewed = data.find((d) => d.label === 'Skipped') ? data.find((d) => d.label === 'Skipped').value : null;
    const notViewed = data.find((d) => d.label === 'Not viewed') ? data.find((d) => d.label === 'Not viewed').value : null;
    // For score chart
    const correct = data.find((d) => d.label === 'Correct') ? data.find((d) => d.label === 'Correct').value : null
    const incorrect = data.find((d) => d.label === 'Incorrect') ? data.find((d) => d.label === 'Incorrect').value : null
    const toolTipBackground = theme === 'light' ? 'white' : 'hsl(223 47% 11%)'
    const total = data.reduce((acc, item) => acc + item.value, 0)

    // Map your colorMapping to the format Nivo expects
    const colors = {
        1: 'hsl(222.2, 67%, 28%)',
        2: 'hsl(222.2, 67%, 50%)',
        3: 'hsl(222.2, 67%, 70%)',
    };

    const scoreColors = {
        1: 'hsl(120, 53%, 47%)', // Green
        2: 'hsl(0, 100%, 50%)',  // Red
    }

    // Convert the data to match Nivo's required format and filter out zero values
    const nivoData = data
        .filter((item) => item.value > 0) // Filter out data with a value of 0
        .map((item) => ({
            id: item.label,
            label: item.label,
            value: item.value,
            color: scoreChart ? scoreColors[item.color] : colors[item.color], // Use the colors from your static mapping
        }));

    const arcLinkLabelsTextColor = theme === 'light' ? 'black' : 'rgb(248, 250, 252)'

    // Define a custom theme for the chart
    const customTheme = {
        labels: {
            text: {
                fontSize: sessionSummary && mobile ? 12 : 16, // Change the arc link label text size here
                fill: arcLinkLabelsTextColor,
                fontWeight: 'bold',
            },
        },
        legends: {
            text: {
                fontSize: 12,
            },
        },
    };

    const customArcLinkLabel = (d) => `${d.label}`;
    const customArcLabel = (d) => sessionSummary ? `${d.label} (${d.value})` : `${((d.value / total) * 100).toFixed(1)}%`

      // Custom tooltip component
    const CustomTooltip = ({ datum }) => {
        // Define tooltip style based on theme
        const tooltipStyle = {
            padding: '5px 10px',
            background: toolTipBackground,
            border: theme === 'light' ? '1px solid black' : '1px solid hsl(210 40% 98%)',
        };

        return (
        <div style={tooltipStyle}>
            <strong>{datum.label}</strong>: {datum.value} ({((datum.value / total)*100).toFixed(1)}%) {datum.value === 1 ? 'question' : 'questions'}
        </div>
        );
    };


    const dimensions = {
        width: mobile ? 
            (sessionSummary ? 350 : 400) : 
            (sessionSummary ? 475 : 550),
        height: mobile ? 
            (sessionSummary ? 250 : 300) : 
            (sessionSummary ? 300 : 350)
    }
    const margins = {
        top: mobile ? 
            (sessionSummary ? 40 : 30) : 
            (sessionSummary ? 45 : 35),
        bottom: mobile ? 
            (sessionSummary ? 40 : 30) : 
            (sessionSummary ? 45 : 35),
        left: mobile ? 
            (sessionSummary ? 45 : 65) : 
            (sessionSummary ? 65 : 55),
        right: mobile ? 
            (sessionSummary ? 45 : 65) : 
            (sessionSummary ? 65 : 55),
    }

    const titleSize = mobile ? 'text-2xl' : 'text-3xl'

  return (
    <div style={{ height: dimensions.height, width: dimensions.width, position: 'relative', marginBottom: '20px' }}>
      <h2 className={`${titleSize} font-semibold text-gray-900 dark:text-white`}>{title}</h2>
      <ResponsivePie
        data={nivoData}
        margin={{ top: margins.top, right: margins.right, bottom: margins.bottom, left: margins.left }}
        innerRadius={mobile ? 0.4 : 0.6} // Adjust this to make it a doughnut chart
        padAngle={1}
        cornerRadius={4}
        colors={({ data }) => data.color} // Apply custom colors
        borderWidth={0}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        enableArcLabels={mobile ? true : false} 
        arcLabel={customArcLabel}
        enableArcLinkLabels={mobile ? false : true}
        arcLinkLabel={customArcLinkLabel}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor={arcLinkLabelsTextColor}
        arcLinkLabelsThickness={3}
        arcLinkLabelsColor={{ from: 'color' }}
        tooltip={CustomTooltip}
        legends={
          !dashboard
            ? [
                {
                  anchor: 'bottom',
                  direction: 'row',
                  justify: false,
                  translateX: 0,
                  translateY: 56,
                  itemsSpacing: 0,
                  itemWidth: 100,
                  itemHeight: 18,
                  itemTextColor: '#999',
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: 'circle',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000',
                      },
                    },
                  ],
                },
              ]
            : []
        }
        theme={customTheme}
      />
      {/* Custom legend for non-dashboard */}
      {!dashboard && (
        <ul className="text-gray-900 dark:text-white mb-6">
          <li>
            <span
              style={{
                height: '10px',
                width: '10px',
                backgroundColor: colors[1],
                borderRadius: '50%',
                display: 'inline-block',
              }}
            />
            &nbsp;<span className="underline">Attempted questions:</span> {attempted}
          </li>
          {viewed > 0 && (
            <li>
              <span
                style={{
                  height: '10px',
                  width: '10px',
                  backgroundColor: colors[2],
                  borderRadius: '50%',
                  display: 'inline-block',
                }}
              />
              &nbsp;<span className="underline">Skipped:</span> {viewed}
            </li>
          )}
          {notViewed > 0 && (
            <li>
              <span
                style={{
                  height: '10px',
                  width: '10px',
                  backgroundColor: colors[3],
                  borderRadius: '50%',
                  display: 'inline-block',
                }}
              />
              &nbsp;<span className="underline">Not viewed:</span> {notViewed}
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default PieChart;
