import React, { useRef, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import SpinLoader from '../loader/SpinLoader';

const ProtectedRoute = ({ children }) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const redirectInitiated = useRef(false); // Track if the redirect has already been initiated

  useEffect(() => {
    // If not loading, not authenticated, and redirect has not been initiated, trigger redirect
    if (!isLoading && !isAuthenticated && !redirectInitiated.current) {
      redirectInitiated.current = true; // Set the flag to true
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  // Show a loader while the authentication state is being checked or redirect is in progress
  if (isLoading || !isAuthenticated) {
    return <SpinLoader />;
  }

  // Render the children if authenticated
  return children;
};

export default ProtectedRoute;
