import React from 'react'

const HeaderMedium = ({text, blackText, mx, underline, my, outerText}) => {
  return (
    <h3 className={`
      ${blackText && 'text-black dark:text-white'}
      ${mx && `mx-${mx}`}
      ${my && `my-${my}`}
      `}>
      <span className={`
          ${underline && 'underline'}
          scroll-m-20 text-2xl font-semibold tracking-tight`}>
          {text}
      </span>
      {outerText}
    </h3>
  )
}

export default HeaderMedium