// Profile.js
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '../../../../components/shadcn/ui/button';
import UserSubscriptions from '../../../../components/account/UserSubscriptions';
import useFetchUserSubscriptions from '../../../../components/hooks/subscriptions/useFetchUserSubscriptions';
import SpinLoader from '../../../../components/loader/SpinLoader';
import useFetchUserDetailsCheck from '../../../../components/hooks/MSRA/fetches/useFetchUserDetailsCheck';
import UserDetailsForm from '../../../../components/user_details/UserDetailsForm';
import { getCsrfToken } from '../../../../components/hooks/csrfTokenHelper';
import useGrantTrialSubscription from '../../../../components/hooks/subscriptions/useGrantTrialSubscription';
import useSubscriptionCount from '../../../../components/hooks/subscriptions/free-trial/useSubscriptionCount';
import useGrantFreeSubscription from '../../../../components/hooks/subscriptions/free-trial/useGrantFreeSubscription';

const Profile = () => {

  const [token, setToken] = useState(null)
  const [tokenLoading, setTokenLoading] = useState(true)
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const csrfToken = getCsrfToken();
  const {grantTrialSubscription, loading: trialGrantLoading, error: trialGrantError, success: trialGrantSuccess} = useGrantTrialSubscription();
  // const { subscriptionCount, loading: subcountLoading, error: subcountError, refresh } = useSubscriptionCount(csrfToken, token);
  const { grantFreeSubscription, loading: freeSubLoading, error: freeSubError, success: freeSubSuccess } = useGrantFreeSubscription(csrfToken, token);

  useEffect(() => {
    const fetchTokenAndWarmUpCache = async () => {
      if (!isAuthenticated) return; // Ensure the user is authenticated before fetching the token
  
      try {
        const token = await getAccessTokenSilently();
        setToken(token);
      } catch (error) {
        console.error('Error fetching token or warming up cache:', error);
      } finally {
        setTokenLoading(false);
      }
    };
  
    fetchTokenAndWarmUpCache();
  }, [isAuthenticated, getAccessTokenSilently]);
  

  const { loading: userIDLoading, error: userIDError, email, firstName, lastName } = useFetchUserDetailsCheck()
  const { user, isLoading: authLoading } = useAuth0();
  
  const [userName, setUserName] = useState('');
  const [pageLoading, setPageLoading] = useState(true);
  const { fetchUserSubscriptions, data, loading, error } = useFetchUserSubscriptions();
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [expiredSubscriptions, setExpiredSubscriptions] = useState([]);
  const [formEmail, setFormEmail] = useState('');
  const [formEmailConfirm, setFormEmailConfirm] = useState('');
  const [formFirstName, setFormFirstName] = useState('');
  const [formLastName, setFormLastName] = useState('');
  const [referralMethod, setReferralMethod] = useState('')
  const [formSuccess, setFormSuccess] = useState(false)
  const [formError, setFormError] = useState('')
  const [skipProfileContent, setSkipProfileContent] = useState(false)

  useEffect(() => {
    // Clean up URL if the user is authenticated
    if (!authLoading && isAuthenticated) {
      cleanUpUrl();
    }
  }, [authLoading, isAuthenticated]);

  function cleanUpUrl() {
    const url = new URL(window.location.href);
    if (url.searchParams.has('code') || url.searchParams.has('state')) {
      url.searchParams.delete('code');
      url.searchParams.delete('state');
      window.history.replaceState({}, document.title, url.pathname + url.search);
    }
  }

  useEffect(() => {
    if (!authLoading && user) {
      setUserName(user['given_name']);
      fetchUserSubscriptions();
      setPageLoading(false); // Ensure page loading is set after everything is fetched
    }
  }, [user, authLoading]);
  

  useEffect(() => {
    if (data && data.subscriptions) {
      const activeSubs = data.subscriptions.filter(sub => sub.is_active);
      const expiredSubs = data.subscriptions.filter(sub => !sub.is_active);
      setActiveSubscriptions(activeSubs);
      setExpiredSubscriptions(expiredSubs);
    }
  }, [data]);

  if ( pageLoading || authLoading || loading || userIDLoading || tokenLoading ) return <SpinLoader />;

  return (
    <div className="w-full max-w-3xl mx-auto p-6 md:p-8">
      {!userIDLoading && !skipProfileContent && email === '' ? (
        <>
          {!formSuccess && <div className="flex items-center justify-center mb-6"> 
            <h1 className="text-lg font-bold text-center">Thanks for using our product! We just need to confirm some contact details from you.</h1> {/* Added text-center */}
          </div>}
          <UserDetailsForm
            email={formEmail}
            setEmail={setFormEmail}
            firstName={formFirstName}
            setFirstName={setFormFirstName}
            lastName={formLastName}
            setLastName={setFormLastName}
            emailConfirm={formEmailConfirm}
            setEmailConfirm={setFormEmailConfirm}
            referralMethod={referralMethod}
            setReferralMethod={setReferralMethod}
            formSuccess={formSuccess}
            setFormSuccess={setFormSuccess}
            formError={formError}
            setFormError={setFormError}
            token={token}
            csrfToken={csrfToken}
            setSkipProfileContent={setSkipProfileContent}
          />
        </>
      ) : (
        <>
          <div className="flex items-center justify-between mb-6">
            <h1 className="text-2xl font-bold">Welcome, {userName}</h1>
          </div>
          <div className="space-y-6">
              <>
                <UserSubscriptions 
                  activeSubscriptions={activeSubscriptions} 
                  expiredSubscriptions={expiredSubscriptions} 
                  grantTrialSubscription={grantTrialSubscription}
                  success={trialGrantSuccess}
                  loading={trialGrantLoading}
                  // subscriptionCount={subscriptionCount}
                  grantFreeSubscription={grantFreeSubscription}
                  freeSubLoading={freeSubLoading}
                  freeSubError={freeSubError}
                  freeSubSuccess={freeSubSuccess}
                  />
              </>
          </div>
        </>
      )}
    </div>

  );
};

export default Profile;
