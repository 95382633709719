// authReducer.js
import { SET_AUTHENTICATED, SET_UNAUTHENTICATED, SET_IDLE, SET_ACTIVE } from './authActions';

const initialState = {
  isAuthenticated: false,
  isIdle: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: true,
        isIdle: false,
      };
    case SET_UNAUTHENTICATED:
      return {
        ...state,
        isAuthenticated: false,
        isIdle: false,
      };
    case SET_IDLE:
      return {
        ...state,
        isIdle: true,
      };
    case SET_ACTIVE:
      return {
        ...state,
        isIdle: false,
      };
    default:
      return state;
  }
};

export default authReducer;
