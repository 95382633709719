/**
 * v0 by Vercel.
 * @see https://v0.dev/t/0W13RkH
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
export default function PricingText() {
    return (
      <section className="w-full py-4 md:py-6 lg:py-8 xl:py-10">
        <div className="container px-4 md:px-6">
          <div className="grid gap-6 items-center">
            <div className="flex flex-col justify-center space-y-8 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl mb-2 sm:mb-6 font-bold tracking-tighter sm:text-4xl xl:text-5xl/none">
                  Multi-Specialty Recruitment Assessment (MSRA)
                  <br />
                  Question Bank
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
  