import React from 'react'
import { Link } from 'react-router-dom'

function NoMSRASubscription() {
  return (
    <div className="flex min-h-[80dvh] flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8">
      <div className="mx-auto lg:max-w-[50dvw] text-center">
        <FrownIcon className="mx-auto h-12 w-12 text-primary" />
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl">
          Oops, looks like you don't have an active subscription for our MSRA question bank!
        </h1>
        <p className="mt-4 text-muted-foreground">
          We're sorry, but it seems that either your subscription has expired, or you have tried to access a page prior to purchasing a subscription. To continue using our services, please purchase a new subscription for our MSRA Question Bank.
        </p>
        <div className="mt-6">
          <Link
            to="/pricing/msra"
            className="inline-flex items-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground shadow-sm transition-colors hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            prefetch={false}
          >
            Go to Pricing
          </Link>
        </div>
      </div>
    </div>
  )
}

function FrownIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="M16 16s-1.5-2-4-2-4 2-4 2" />
      <line x1="9" x2="9.01" y1="9" y2="9" />
      <line x1="15" x2="15.01" y1="9" y2="9" />
    </svg>
  )
}

export default NoMSRASubscription