import { useAuth0 } from '@auth0/auth0-react';
import { useFetchMSRASessionData } from '../../../../../components/hooks/MSRA/fetches/FetchActiveMSRASessionData';
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { 
  fetchTextbookPages,
  fetchUserQuestionStates,
  fetchSessionQuestions,
  enrichQuestionsWithDetails,
  fetchQuestionAttempts,
  fetchQuestionAttemptStats,
  fetchSingleTextbookPage
} from '../../../../../components/hooks/MSRA/helper_functions/cpsFetches'
import { EMQDisplay } from '../../../../../components/hooks/MSRA/question_displays/EMQDisplay' 
import { SBADisplay } from '../../../../../components/hooks/MSRA/question_displays/SBADisplay'
import { Card } from '../../../../../components/shadcn/ui/card';
import { Button } from '../../../../../components/shadcn/ui/button';
import { AlertCircle, CircleArrowLeft, CircleArrowRight, ExternalLinkIcon } from 'lucide-react';
import { Separator } from '../../../../../components/shadcn/ui/separator';
import { 
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
 } from '../../../../../components/shadcn/ui/dialog';
import FormattedQuestionList from '../../../../../components/session_navigation/MSRA/sessionNavigator';
import SpinLoader from '../../../../../components/loader/SpinLoader';
import { getCsrfToken } from '../../../../../components/hooks/csrfTokenHelper';
import { Input } from '../../../../../components/shadcn/ui/input';
import { handleSubmitQuestionError } from '../../../../../components/hooks/handleSubmitQuestionError';
import CheckMarkAnimated from '../../../../../components/other_ui/CheckMarkAnimated';
import { Label } from '../../../../../components/shadcn/ui/label';
import { Textarea } from '../../../../../components/shadcn/ui/textarea';
import HeaderMedium from '../../../../../components/typography/HeaderMedium';

function TrialCPSActiveSession() {
  const [questionOrder, setQuestionOrder] = useState([])
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [questionStates, setQuestionStates] = useState([]);
    const [originalQuestionOrder, setOriginalQuestionOrder] = useState([]);
    const [expandedQuestionOrder, setExpandedQuestionOrder] = useState([]);
    const [questionAttemptsStats, setQuestionAttemptsStats] = useState([]);
    const [textbookContent, setTextbookContent] = useState([])
    const [questions, setQuestions] = useState([]);
    const [questionsLoading, setQuestionsLoading] = useState(false);
    const [questionsError, setQuestionsError] = useState(null);
    const [questionAttempts, setQuestionAttempts] = useState({});
    const [overallScore, setOverallScore] = useState(0);
    const [totalPoints, setTotalPoints] = useState(0);
    const [scorePercentage, setScorePercentage] = useState(0.0);
    const [userAnswer, setUserAnswer] = useState({}); // Now an object to track answers by question ID
    const [showDialog, setShowDialog] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [questionError, setQuestionError] = useState('');
    const [questionErrorSuccess, setQuestionErrorSuccess] = useState(false);

    const [token, setToken] = useState(null)
    const [tokenLoading, setTokenLoading] = useState(true)
    const { getAccessTokenSilently, isLoading: authLoading } = useAuth0()
    const csrfToken = getCsrfToken();

    useEffect(() => {
      const fetchToken = async () => {
        try {
          const token = await getAccessTokenSilently();
          setToken(token);
        } catch (error) {
          console.error('Error fetching token:', error);
        } finally {
          setTokenLoading(false);
        }
      };
      
      fetchToken();
    }, [getAccessTokenSilently]);

    const navigate = useNavigate()
    
    const { data, loading, error } = useFetchMSRASessionData(6, token, "MSRA Free Trial")
    const allLoading = loading || questionsLoading || authLoading || tokenLoading

    const toggleCollapse = () => {
      setIsCollapsed(!isCollapsed);
    };
    
    const handleOpenDialog = () => {
        setShowDialog(true);
      };

    const handleUserAnswerChange = (answerData) => {
        const questionId = currentQuestionId; // Ensure this ID is correctly set depending on current question context
    
        if (typeof answerData === 'number') {
            // Assuming SBADisplay only passes the selected index directly
            setUserAnswer(prev => ({
                ...prev,
                [questionId]: answerData
            }));
        } else if (typeof answerData === 'object' && answerData.hasOwnProperty('scenarioId')) {
            // Handling for EMQDisplay
            setUserAnswer(prev => ({
                ...prev,
                [questionId]: {
                    ...prev[questionId],
                    [answerData.scenarioId]: answerData.selectedOptionIndex
                }
            }));
        }
    };
    
    useEffect(() => {
      if (!data || !data.id || !token) return;
  
      async function initializeData() {
          setQuestionsLoading(true);
          
          try {
              if (data.question_order) {
                  const parsedQuestionOrder = JSON.parse(data.question_order);
                  setOriginalQuestionOrder(parsedQuestionOrder);
                  setQuestionOrder(parsedQuestionOrder);
                  loadTextbookPage(JSON.parse(data.question_order)[0])
              }
              
              const questions = await fetchSessionQuestions(data.id, token);
              const questionsWithDetails = await enrichQuestionsWithDetails(questions, token); // Explicitly await and pass token here
  
              const [attemptsResponse, questionStatesData, questionAttemptsStats] = await Promise.all([
                  fetchQuestionAttempts(data.id, token),
                  fetchUserQuestionStates(data.id, token),
                  fetchQuestionAttemptStats(data.id, token),
              ]);
    
              setQuestions(questionsWithDetails);
              processAttemptsData(attemptsResponse);
              setQuestionStates(questionStatesData);
              setExpandedQuestionOrder(JSON.parse(data.expanded_question_order));
              setQuestionAttemptsStats(questionAttemptsStats)

            //   const initialQuestionId = questionOrder[currentQuestionIndex]
                
            } catch (error) {
              console.error('Failed to fetch session details:', error);
              setQuestionsError(error);
          } finally {
              setQuestionsLoading(false);
          }
      }
    
      initializeData();
  }, [data, token]); // Dependency array ensures this runs when `data` changes
  
      function processAttemptsData(attempts) {
        const attemptsByQuestionId = {};
        let totalScore = 0;
        let totalPossiblePoints = 0;
      
        attempts.forEach(attempt => {
          attemptsByQuestionId[attempt.question] = attempt;
          totalScore += attempt.points_scored;
          totalPossiblePoints += attempt.total_points;
        });
        setQuestionAttempts(attemptsByQuestionId);
        setOverallScore(totalScore);
        setTotalPoints(totalPossiblePoints);
        setScorePercentage(((totalScore / totalPossiblePoints) * 100).toFixed(1));
      }
      
    
    useEffect(() => {
        setQuestionErrorSuccess(false)
        const updateQuestionState = async () => {
            if (questionOrder.length > 0) {
                const currentQuestionId = questionOrder[currentQuestionIndex];
                const currentQuestionState = questionStates.find(state => state.question_details.id === currentQuestionId);
                // Check if the current question's state has not been viewed yet
                if (currentQuestionState && !currentQuestionState.viewed) {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/MSRA/user-session/question-states/${currentQuestionState.id}/`, {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                            "X-CSRFToken": csrfToken,
                          },
                        body: JSON.stringify({ viewed: true }),
                    });
    
                    if (response.ok) {
                        // Update the local state to reflect the change
                        setQuestionStates(prevStates => prevStates.map(state => state.id === currentQuestionState.id ? { ...state, viewed: true } : state));
                    } else {
                        console.error("Failed to update question state");
                    }
                }
            }
        };
    
        updateQuestionState();
    }, [currentQuestionIndex, questionOrder, questionStates]);

    const loadTextbookPage = async (questionId) => {
        if (!textbookContent[questionId]) {
            try {
                const textbookPage = await fetchSingleTextbookPage(questionId, token);
                setTextbookContent((prev) => ({
                    ...prev,
                    [questionId]: textbookPage,
                }));
            } catch (error) {
                console.error(`Failed to fetch textbook page for question ${questionId}:`, error);
            }
        }
    };

    useEffect(() => {
        const currentQuestionId = questionOrder[currentQuestionIndex];
        if (currentQuestionId) {
            loadTextbookPage(currentQuestionId); // Fetch textbook page if not already fetched
        }
    }, [currentQuestionIndex, questionOrder]);

    // Expand questionOrder based on questionStates, but do not overwrite the originalQuestionOrder

    const goToPreviousQuestion = useCallback(() => {
        if (currentQuestionIndex > 0) {
            let newIndex = currentQuestionIndex - 1;
            setCurrentQuestionIndex(newIndex);
        }
    }, [currentQuestionIndex]);

    const goToNextQuestion = useCallback(() => {
        if (currentQuestionIndex < questionOrder.length - 1) {
            let newIndex = currentQuestionIndex + 1;
            setCurrentQuestionIndex(newIndex);
        }
    }, [currentQuestionIndex, questionOrder.length]);

    const navigateToQuestion = (questionNumber) => {
        setCurrentQuestionIndex(questionNumber)
    };
    


    const getCurrentQuestionDisplay = () => {
        const currentQuestionId = questionOrder[currentQuestionIndex];
        const currentQuestionState = questionStates.find(state => state.question_details.id === currentQuestionId);
    
        
        if (currentQuestionState && currentQuestionState.question_details.question_type === 2) {
            // Find the index of the first occurrence of the current question ID in the expandedQuestionOrder
            const startRange = expandedQuestionOrder.indexOf(currentQuestionId) + 1
        
            // End range is startRange + scenario_count - 1
            const endRange = startRange + currentQuestionState.question_details.scenario_count - 1;
            return `Questions ${startRange}-${endRange} of ${expandedQuestionOrder.length}`;
        }
        return `Question ${expandedQuestionOrder.indexOf(currentQuestionId) + 1} of ${expandedQuestionOrder.length}`;
    };
        
    const submitAnswer = async () => {
        const currentQuestionId = questionOrder[currentQuestionIndex];
        const currentQuestionState = questionStates.find(state => state.question_details.id === currentQuestionId);
    
        if (currentQuestionState.answered) {
            return; // Stop if the question has already been answered
        }
    
        let userAnswerForSubmission = userAnswer[currentQuestionId];
        if (currentQuestion && currentQuestion.question_type === 2) {  // EMQ handling
            userAnswerForSubmission = currentQuestion.scenarios.map(scenario => {
                return userAnswer[currentQuestionId][scenario.id] // Default to -1 if not answered
            });
        }
    
        const submitData = {
            question: currentQuestionId,
            user_session: data.id,
            user_answer: userAnswerForSubmission,
        };
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/MSRA/user-session/user-question-attempt/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "X-CSRFToken": csrfToken,
                },
                body: JSON.stringify(submitData),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const result = await response.json();
    
            // Update the question state to reflect that it has been answered and link the new user question attempt
            await updateQuestionState(currentQuestionState.id, true, result.id);
        } catch (error) {
            console.error('Failed to submit user answer:', error);
        }
    };
    
    const updateQuestionState = async (questionStateId, answeredStatus, userQuestionAttemptId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/MSRA/user-session/question-states/${questionStateId}/`, {
                method: 'PATCH',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                  "X-CSRFToken": csrfToken
                },
                body: JSON.stringify({ answered: answeredStatus, user_question_attempt: userQuestionAttemptId }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            // Assuming you have a way to fetch or re-fetch the state data; adjust accordingly
            setQuestionStates(prevStates => prevStates.map(state => 
                state.id === questionStateId ? { ...state, answered: answeredStatus, user_question_attempt: userQuestionAttemptId } : state
            ));
        } catch ( error ) {
            console.error("Failed to update question answered status:", error);
        }
    };
    

    

    // for displaying question content
    const currentQuestionId = questionOrder[currentQuestionIndex];
    const currentQuestion = questions.find(q => q.id === currentQuestionId);
    const currentQuestionState = questionStates.find(qs => qs.question_details.id === currentQuestionId);
    const OptionsComponent = currentQuestion && currentQuestion.question_type === 2 ? EMQDisplay : SBADisplay;

    // Navigate questions with arrow keys
    // Define the keydown handler
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'ArrowRight') {
            goToNextQuestion();
        } else if (event.key === 'ArrowLeft') {
            goToPreviousQuestion();
        }
    }, [goToNextQuestion, goToPreviousQuestion]);

    // Add event listener on component mount and cleanup on unmount
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const endSession = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/MSRA/user-session/user-session/${data.id}/end/`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "X-CSRFToken": csrfToken,
                }
            });
    
            if (response.ok) {
                navigate(`/trial-MSRA/session-summary/CPS/${data.id}`)
                // Handle successful session ending, e.g., redirecting the user or updating UI
            } else {
                console.error('Failed to end session.');
                // Handle errors, e.g., by showing an error message
            }
    };

    if (allLoading || !currentQuestion) return <SpinLoader loadingText={'Loading questions...'} />;

  return (
    <div>
      <div className="flex flex-col justify-center lg:flex-row mx-auto p-1 md:p-4 lg:p-10 gap-8">
        <Card className="flex flex-col w-full lg:w-[50vw] md:p-8 p-2">
            <div className="flex items-center justify-between mb-4">
                <Button 
                    variant='outline'
                    className={`hidden md:block ${currentQuestionIndex === 0 ? 'invisible' : ''}`} onClick={goToPreviousQuestion}>
                    Previous
                </Button>
                <CircleArrowLeft
                    className={`block md:hidden ${currentQuestionIndex === 0 ? 'invisible' : ''}`}
                    onClick={goToPreviousQuestion}
                />
                <h5 className='text-xl font-bold'>
                    {getCurrentQuestionDisplay()}
                </h5>
                <CircleArrowRight
                    className={`block md:hidden ${(currentQuestionIndex === questionOrder.length - 1) ? 'invisible' : ''}`}
                    onClick={goToNextQuestion}
                />
                <Button
                    variant='outline'
                    className={`hidden md:block ${(currentQuestionIndex === questionOrder.length - 1) ? 'invisible' : ''}`} onClick={goToNextQuestion}>
                    Next
                </Button>
            </div>
            <Separator orientation='horizontal' />
            <div className="mb-4">
                <OptionsComponent
                    questions={currentQuestion}
                    isAnswered={currentQuestionState?.answered}
                    onUserAnswerChange={(answer) => handleUserAnswerChange(answer)} // Pass the function to update the answer
                    data={data}
                    allQuestionAttempts={questionAttempts}
                    localUserAnswer={userAnswer}
                    questionAttemptsStats={questionAttemptsStats}
                />
            </div>
            <div className="flex flex-col md:flex-row md:justify-between">
                <div className='hidden md:inline'>
                    <Dialog>
                        <DialogTrigger asChild>
                            <Button className='text-sm items-center' variant={'link'}>
                                <AlertCircle size={16} />&nbsp; Report a problem with this question
                            </Button>
                        </DialogTrigger>
                        <DialogContent>
                            {!questionErrorSuccess ? (
                            <>
                                <DialogHeader>
                                    <DialogTitle>
                                        Please fill out some brief information on the error:
                                    </DialogTitle>
                                    <DialogDescription className='text-xs italic'>
                                        Question ID: {questionOrder[currentQuestionIndex]}
                                    </DialogDescription>
                                </DialogHeader>
                                <form onSubmit={(e) => handleSubmitQuestionError(e, questionError, questionOrder, currentQuestionIndex, null, setQuestionError, token, csrfToken, questionErrorSuccess, setQuestionErrorSuccess)}>
                                    <div className='mb-4'>
                                        <Label htmlFor='questionError'>Please summarize the error:</Label>
                                        <Textarea
                                            id="questionError"
                                            value={questionError}
                                            onChange={(e) => setQuestionError(e.target.value)}
                                            required
                                            placeholder="Describe the error here..."
                                            />
                                    </div>
                                    <Button className='w-full' type='submit'>Submit</Button>
                                </form>
                            </>
                            ) : (
                                <>
                                    <CheckMarkAnimated page="Question Error" />
                                </>
                            )}
                        </DialogContent>
                    </Dialog>
                </div>
                <div className="flex flex-col md:flex-row md:justify-between">
                    <Button className={`${currentQuestionState?.answered ? 'hidden' : ''}`}
                        onClick={submitAnswer}
                        disabled={currentQuestionState?.answered} // Disable button if question is answered
                    >
                        Submit Answer
                    </Button>
                </div>
                <div className='inline md:hidden'>
                    <Dialog>
                        <DialogTrigger asChild>
                            <Button className='text-sm items-center' variant={'link'}>
                                <AlertCircle size={16} />&nbsp; Report a problem with this question
                            </Button>
                        </DialogTrigger>
                        <DialogContent>
                            {!questionErrorSuccess ? (
                            <>
                                <DialogHeader>
                                    <DialogTitle>
                                        Please fill out some brief information on the error:
                                    </DialogTitle>
                                    <DialogDescription className='text-xs italic'>
                                        Question ID: {questionOrder[currentQuestionIndex]}
                                    </DialogDescription>
                                </DialogHeader>
                                <form onSubmit={(e) => handleSubmitQuestionError(e, questionError, questionOrder, currentQuestionIndex, null, setQuestionError, token, csrfToken, questionErrorSuccess, setQuestionErrorSuccess)}>
                                    <div className='mb-4'>
                                        <Label htmlFor='questionError'>Please summarize the error:</Label>
                                        <Textarea
                                            id="questionError"
                                            value={questionError}
                                            onChange={(e) => setQuestionError(e.target.value)}
                                            required
                                            placeholder="Describe the error here..."
                                            />
                                    </div>
                                    <Button className='w-full' type='submit'>Submit</Button>
                                </form>
                            </>
                            ) : (
                                <>
                                    <CheckMarkAnimated page="Question Error" />
                                </>
                            )}
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        </Card>
        <div>
            <Card className="w-full lg:min-w-[17vw] p-4">
                <FormattedQuestionList
                    expandedQuestionOrder={expandedQuestionOrder}
                    questionStates={questionStates}
                    questionAttempts={questionAttempts}
                    navigateToQuestion={navigateToQuestion}
                    localUserAnswer={userAnswer}
                    currentQuestionIndex={currentQuestionIndex}
                    allQuestions={questions}
                    totalPoints={totalPoints}
                    overallScore={overallScore}
                />
                <div className="flex">
                    <Dialog>
                        <DialogTrigger asChild>
                            <Button onClick={handleOpenDialog} className="mx-auto">End Session</Button>
                        </DialogTrigger>
                        <DialogContent>
                            <DialogHeader>
                            <DialogTitle>End session?</DialogTitle>
                            <DialogDescription>
                                By ending the session, all progress will be saved and it will become locked. You will be able to review your answers but not change them.
                            </DialogDescription>
                            </DialogHeader>
                            <Button className="mx-auto" onClick={endSession}>End Session</Button>
                        </DialogContent>
                    </Dialog>
                </div>
            </Card>
        </div>
      </div>
      {currentQuestionState?.answered && textbookContent[currentQuestionId] && (
        <Card className='w-full lg:w-[75vw] mx-auto p-1 md:p-8 mb-5 px-2 border-none'>
            <h2 className='scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-2' dangerouslySetInnerHTML={{ __html: textbookContent[currentQuestionId].title}}></h2>
            <div id="ckeditor" className="ckeditor-content" dangerouslySetInnerHTML={{ __html: textbookContent[currentQuestionId].content }}></div>
            <Separator className='my-4' />
            <div className='my-2'>
                <HeaderMedium blackText={false} text="Recommended Reading:" underline={true} /> 
                <ul className='list-disc pl-5'>
                  {textbookContent[currentQuestionId].page_resources && textbookContent[currentQuestionId].page_resources.map((res) => (
                    <li className='py-1'>
                      <a
                        href={res.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline flex items-center space-x-1"
                      >
                        <span>{res.name}</span>
                        <ExternalLinkIcon size={14}/>
                      </a>
                    </li>
                  ))}
                </ul>
            </div>
        </Card>
      )}
    </div>
  )
}

export default TrialCPSActiveSession