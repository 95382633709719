import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { MultiBackend, createTransition } from 'dnd-multi-backend';
import { Button } from '../../../shadcn/ui/button';
import { Dialog, DialogContent, DialogTrigger } from '../../../shadcn/ui/dialog';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '../../../shadcn/ui/table';
import { HoverCard, HoverCardTrigger, HoverCardContent } from '../../../shadcn/ui/hover-card';
import { Progress } from '../../../shadcn/ui/progress';

// Transition for mobile-friendly DnD
const HTML5toTouch = {
    backends: [
        {
            backend: HTML5Backend,
        },
        {
            backend: TouchBackend,
            options: { enableMouseEvents: true },
            preview: true,
            transition: createTransition('touchstart', event => event.touches.length === 1),
        },
    ],
};

// Utility to convert index numbers to letter labels
const indexToLabel = (index) => String.fromCharCode(65 + index); // 65 is ASCII for 'A'

export const RLDisplay = ({ questions, isAnswered, userAnswer, fetchedUserAnswer, optionOrder, moveOption, manualScore, questionStats, reviewState }) => {
    const formatUserAnswer = (userAnswer) => {
        return userAnswer ? userAnswer.map(index => indexToLabel(index)).join(', ') : null;
    };

    return (
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
            <div className="flex flex-col">
                {reviewState && !isAnswered && (
                    <div className="font-semibold italic text-gray-600 text-center underline my-4 dark:text-gray-300">
                        You did not answer this question.
                    </div>
                )}
                <div className="p-4 mb-4 border-b border-gray-200" dangerouslySetInnerHTML={{ __html: questions.content }} />
                <div className="text-sm italic text-gray-600 mb-4">
                    Please order the following options in order of most appropriate responses to the above situation.
                    <br />
                    1: <strong>most</strong> appropriate
                    <br />
                    5: <strong>least</strong> appropriate
                </div>
                {optionOrder && optionOrder.map((option, index) => {
                    const isOptionCorrect = questions.rl_answer && questions.options[questions.rl_answer[index]].id === option.id;

                    return (
                        <Option
                            key={option.id}
                            index={index}
                            id={option.id}
                            text={option.content}
                            moveOption={moveOption}
                            label={option.label}
                            disabled={isAnswered || reviewState}
                            isCorrect={isOptionCorrect}
                            isAnswered={isAnswered}
                            stats={questionStats}
                        />
                    );
                })}
                {isAnswered && (
                    <>
                        <div className="mt-4">
                            <strong>Correct order:</strong> {formatUserAnswer(questions.rl_answer)}. <br />
                            <strong>Score for this question:</strong> {fetchedUserAnswer ? `${fetchedUserAnswer.points_scored} / ${fetchedUserAnswer.total_points}` : `${manualScore[questions.id]} / 20`}
                        </div>
                        {questions.explanation && (
                            <>
                                <div className="underline italic text-gray-600 dark:text-gray-300">
                                    Explanation:
                                </div>
                                <div className="mb-4" dangerouslySetInnerHTML={{ __html: questions.explanation }} />
                            </>
                        )}
                    </>
                )}
            </div>
        </DndProvider>
    );
};

const Option = ({ id, text, index, moveOption, label, disabled, isCorrect, isAnswered, stats }) => {
    const [{ isDragging }, drag] = useDrag({
        type: 'option',
        item: { id, index },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
        canDrag: !disabled,
    });

    const [, drop] = useDrop({
        accept: 'option',
        hover(item) {
            if (!disabled && item.index !== index) {
                moveOption(item.index, index);
                item.index = index; // Update index for consistent dragging experience
            }
        },
    });

    const optionStyle = isCorrect ? 'bg-green-300 dark:bg-green-900' : 'bg-red-300 dark:bg-red-900';

    return (
        <div ref={!disabled ? (node) => drag(drop(node)) : undefined} className={`flex items-center mb-4 p-4 border rounded shadow-sm ${isAnswered ? `${optionStyle}` : ''} ${!disabled && 'hover:bg-gray-100 dark:hover:bg-black/20'} ${disabled ? 'cursor-default' : 'cursor-pointer'}`}>
            <div className="text-lg font-bold pr-4">{index + 1}.</div>
            <div className="flex-grow flex items-center justify-between">
                <div>
                    <span className="mr-2 font-bold">{label}.</span>
                    <span dangerouslySetInnerHTML={{ __html: text }} />
                </div>
                {isAnswered && (
                    <Dialog>
                        <DialogTrigger>
                            <div className='p-0 text-sm text-muted-foreground hover:underline'>
                                <span className='hidden md:inline'>Click</span><span className='md:hidden inline'>Tap</span> for Answer Data 
                            </div>
                        </DialogTrigger>
                        <DialogContent>
                            <h2 className="scroll-m-20 border-b pb-2 text-xl font-semibold tracking-tight first:mt-0">
                                Option: {label}. <span className='font-normal'>{text}</span>
                            </h2>
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableCell>Rank Position</TableCell>
                                        <TableCell>Responses</TableCell>
                                        <TableCell>Percentage</TableCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {Object.entries(stats.option[id]).map(([rank, data]) => (
                                        <TableRow key={`${id}-${rank}`}>
                                            <TableCell className='font-semibold'>{parseInt(rank) + 1}</TableCell>
                                            <TableCell className=''>{data.percentage.toFixed(1)}%</TableCell>
                                            <TableCell><Progress value={data.percentage} /></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </DialogContent>
                    </Dialog>
                )}
            </div>
        </div>
    );
};
